export const textWithVideo = (photoEndpoint, thumbEndpoint) => ({
  name: 'textWithVideo',
  label: 'T_GENERAL_BLOCK_TYPE_TEXT_WITH_VIDEO',
  properties: {
    translations: {
      type: 'translation',
      properties: {
        title: {},
        text: {
          type: 'tinymce',
        },
        buttonTitle: {},
        buttonLink: {},
      },
    },
    youtube: {
      type: 'youtube',
      description: 'T_GENERAL_YOUTUBE_CODE',
    },
    photo: {
      type: 'image',
      description: 'T_GENERAL_VIDEO_MASK',
      endpoint: photoEndpoint,
      validate: ['maxSize'],
      thumbs: thumbEndpoint && {
        endpoint: thumbEndpoint
      }
    },
  },
})
