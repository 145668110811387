import { types } from './_path_common_types'

const schema = {
  endpoint: '/api/diaspora_ashkenazis',
  resource: {
    definition: 'DiasporaAshkenazi-diasporaAshkenazi.read',
    sectionA: {
      properties: {
        translations: {
          type: 'translation',
          properties: {
            sectionATitle: {},
            sectionALeftText: {
              type: 'textarea'
            },
            sectionARightText: {
              type: 'textarea'
            },
            sectionAPhotoText: {
              type: 'textarea'
            },
            sectionAPhotoTitle: {},
            sectionASubtextATitle: {},
            sectionASubtextAText: {
              type: 'textarea'
            },
          }
        },
        sectionAExhibitA: types.exhibit,
        sectionAExhibitASize: types.exhibit_size,
        sectionAExhibitAPosition: types.exhibit_position,
        sectionAExhibitAMask: types.exhibit_mask,

        sectionAStat: {}
      }
    },
    sectionB: {
      properties: {
        translations: {
          type: 'translation',
          properties: {
            sectionBTitle: {},
            sectionBText: {
              type: 'textarea'
            },
            sectionBVideoTitle: {},
            sectionBVideoText: {
              type: 'textarea'
            },
          }
        },
        sectionBExhibitA: types.exhibit,
        sectionBExhibitASize: types.exhibit_size,
        sectionBExhibitAPosition: types.exhibit_position,
        sectionBExhibitAMask: types.exhibit_mask,

        sectionBExhibitB: types.exhibit,
        sectionBExhibitBSize: types.exhibit_size,
        sectionBExhibitBPosition: types.exhibit_position,
        sectionBExhibitBMask: types.exhibit_mask,

        sectionBYoutube: {
          type: 'youtube'
        },
        sectionBStat: {}
      }
    },
    sectionC: {
      properties: {
        translations: {
          type: 'translation',
          properties: {
            sectionCTitle: {},
            sectionCText: {
              type: 'textarea'
            },
          }
        },
        sectionCExhibitA: types.exhibit,
        sectionCExhibitASize: types.exhibit_size,
        sectionCExhibitAPosition: types.exhibit_position,
        sectionCExhibitAMask: types.exhibit_mask,

        sectionCExhibitB: types.exhibit,
        sectionCExhibitBSize: types.exhibit_size,
        sectionCExhibitBPosition: types.exhibit_position,
        sectionCExhibitBMask: types.exhibit_mask,

        sectionCExhibitC: types.exhibit,
        sectionCExhibitCSize: types.exhibit_size,
        sectionCExhibitCPosition: types.exhibit_position,
        sectionCExhibitCMask: types.exhibit_mask,

        sectionCStat: {}
      }
    },
    sectionD: {
      properties: {
        translations: {
          type: 'translation',
          properties: {
            sectionDTitle: {},
            sectionDText: {
              type: 'textarea'
            },
          }
        },
        sectionDExhibitA: types.exhibit,
        sectionDExhibitASize: types.exhibit_size,
        sectionDExhibitAPosition: types.exhibit_position,
        sectionDExhibitAMask: types.exhibit_mask,

        sectionDExhibitB: types.exhibit,
        sectionDExhibitBSize: types.exhibit_size,
        sectionDExhibitBPosition: types.exhibit_position,
        sectionDExhibitBMask: types.exhibit_mask,

        sectionDExhibitC: types.exhibit,
        sectionDExhibitCSize: types.exhibit_size,
        sectionDExhibitCPosition: types.exhibit_position,
        sectionDExhibitCMask: types.exhibit_mask,

        sectionDExhibitD: types.exhibit,
        sectionDExhibitDSize: types.exhibit_size,
        sectionDExhibitDPosition: types.exhibit_position,
        sectionDExhibitDMask: types.exhibit_mask,

        sectionDExhibitE: types.exhibit,
        sectionDExhibitESize: types.exhibit_size,
        sectionDExhibitEPosition: types.exhibit_position,
        sectionDExhibitEMask: types.exhibit_mask,

        sectionDStat: {}
      }
    },
    sectionE: {
      properties: {
        translations: {
          type: 'translation',
          properties: {
            sectionETitle: {},
            sectionEText: {
              type: 'textarea'
            },
            sectionEVideoTitle: {},
            sectionEVideoText: {
              type: 'textarea'
            },
            sectionESliderTitle: {
              type: 'textarea'
            },
          }
        },
        sectionEExhibitA: types.exhibit,
        sectionEExhibitASize: types.exhibit_size,
        sectionEExhibitAPosition: types.exhibit_position,
        sectionEExhibitAMask: types.exhibit_mask,

        sectionEExhibitB: types.exhibit,
        sectionEExhibitBSize: types.exhibit_size,
        sectionEExhibitBPosition: types.exhibit_position,
        sectionEExhibitBMask: types.exhibit_mask,

        sectionEExhibitC: types.exhibit,
        sectionEExhibitCSize: types.exhibit_size,
        sectionEExhibitCPosition: types.exhibit_position,
        sectionEExhibitCMask: types.exhibit_mask,

        sectionESliderAExhibitA: types.exhibit,
        sectionESliderAExhibitAMask: types.exhibit_mask,
        sectionESliderAExhibitB: types.exhibit,
        sectionESliderAExhibitBMask: types.exhibit_mask,
        sectionESliderAExhibitC: types.exhibit,
        sectionESliderAExhibitCMask: types.exhibit_mask,
        sectionESliderAExhibitD: types.exhibit,
        sectionESliderAExhibitDMask: types.exhibit_mask,

        sectionEYoutube: {
          type: 'youtube'
        },
        sectionEStat: {}
      }
    },
    sectionF: {
      properties: {
        translations: {
          type: 'translation',
          properties: {
            sectionFTitle: {},
            sectionFText: {
              type: 'textarea'
            }
          }
        },
        sectionFExhibitA: types.exhibit,
        sectionFExhibitASize: types.exhibit_size,
        sectionFExhibitAPosition: types.exhibit_position,
        sectionFExhibitAMask: types.exhibit_mask,

        sectionFExhibitB: types.exhibit,
        sectionFExhibitBSize: types.exhibit_size,
        sectionFExhibitBPosition: types.exhibit_position,
        sectionFExhibitBMask: types.exhibit_mask,

        sectionFExhibitC: types.exhibit,
        sectionFExhibitCSize: types.exhibit_size,
        sectionFExhibitCPosition: types.exhibit_position,
        sectionFExhibitCMask: types.exhibit_mask,

        sectionFStat: {}
      }
    }
  },
}

export default schema
