import packageGallerySchema from 'core/_schema/packageGallery'

export const packageGallery = () => ({
  name: 'packageGallery',
  label: 'T_GENERAL_BLOCK_TYPE_PACKAGE_GALLERY',
  properties: {
    translations: {
      type: 'translation',
      properties: {
        title: {},
        text: {
          type: 'tinymce',
        },
      },
    },
    packageGallery: {
      type: 'resource',
      endpoint: `${packageGallerySchema.endpoint}?pagination=false`,
      titleAccessor: 'title',
    },
    galleryType: {
      type: 'choice',
      choices: {
        slider: 'T_GENERAL_BLOCK_GALLERY_TYPE_SLIDER',
        logos: 'T_GENERAL_BLOCK_GALLERY_TYPE_LOGOS',
      },
      validate: ['required'],
    },
  },
})
