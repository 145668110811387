import React from 'react'
import { Route } from 'react-router-dom'
import { CalendarTodayOutlined } from '@material-ui/icons'
import { Collection } from './Collection'
import { SchemableComponent } from 'core/components/SchemableComponent'
import { New } from './New'
import { Edit } from './Edit'
import { addRoutesConfig } from 'core/_helpers/addRoutesConfig'
import exhibitSchema from '_schema/exhibit'

const basePath = '/exhibit_holiday_types'

const routes = () =>
  addRoutesConfig(
    {
      index: {
        subtitle: 'T_GENERAL_RECORD_LIST',
        type: Route,
        render: props => <Collection {...props} />,
        path: basePath,
        exact: true,
        icon: CalendarTodayOutlined,
      },
      new: {
        subtitle: 'T_GENERAL_RECORD_NEW',
        type: Route,
        render: props => (
          <SchemableComponent path="definitions" component={New} {...props} />
        ),
        exact: true,
        path: `${basePath}/new`,
      },
      edit: {
        subtitle: 'T_GENERAL_RECORD_EDIT',
        type: Route,
        render: props => (
          <SchemableComponent path="definitions" component={Edit} {...props} />
        ),
        path: `${basePath}/:id`,
        exact: true,
      },
    },
    {
      title: 'T_MODULE_EXHIBIT_HOLIDAY_TYPES_TITLE',
      resourceAccess: exhibitSchema.endpoint,
      indexPath: basePath,
    }
  )

export default routes
