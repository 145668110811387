import { YearFromType } from 'pages/Exhibits/form/fields/YearFromType'
import { YearToType } from 'pages/Exhibits/form/fields/YearToType'
import photoSchema from 'core/_schema/photo'
import exhibitCategorySchema from '_schema/exhibitCategory'
import exhibitSectionSchema from '_schema/exhibitSection'
import exhibitHolidayTypeSchema from '_schema/exhibitHolidayType'
import exhibitStorageLocationSchema from '_schema/exhibitStorageLocation'

const schema = {
  endpoint: '/api/exhibits',
  resource: {
    definition: 'Exhibit-exhibit.read',
    properties: {
      translations: {
        type: 'translation',
        properties: {
          title: {},
          description: {
            type: 'textarea'
          },
          photoAlt: {
            type: 'textarea'
          },
          expositionLink: {},
          slug: {
            type: 'slug',
            routes: {
              pl: '/biblioteka-obiektow#:slug',
              en: '/en/object-library#:slug',
              he: '/he/object-library#:slug'
            },
          },
        },
      },
      yearFrom: {
        type: YearFromType,
        description: 'T_MODULE_EXHIBIT_ENTER_YEAR',
        hint: 'Creation year or creation range start',
        validators: ['minValue(0)', 'maxValue(2022)']
      },
      yearTo: {
        type: YearToType,
        description: 'T_MODULE_EXHIBIT_ENTER_YEAR',
        hint: 'Creation range end',
        validators: ['minValue(0)', 'maxValue(2022)']
      },
      category: {
        type: 'resource',
        titleAccessor: `translations.${process.env.REACT_APP_LOCALE}.title`,
        endpoint: `${exhibitCategorySchema.endpoint}?order[ord]=desc&pagination=false`,
      },
      sections: {
        type: 'multipleResource',
        titleAccessor: `translations.${process.env.REACT_APP_LOCALE}.title`,
        endpoint: `${exhibitSectionSchema.endpoint}?order[ord]=desc&pagination=false`,
      },
      holidayType: {
        type: 'resource',
        titleAccessor: `translations.${process.env.REACT_APP_LOCALE}.title`,
        endpoint: `${exhibitHolidayTypeSchema.endpoint}?order[ord]=desc&pagination=false`,
      },
      storageLocation: {
        type: 'resource',
        titleAccessor: `translations.${process.env.REACT_APP_LOCALE}.title`,
        endpoint: `${exhibitStorageLocationSchema.endpoint}?order[ord]=desc&pagination=false`,
      },
      photo: {
        type: 'image',
        endpoint: photoSchema.endpoint.single,
        validate: ['maxSize'],
        thumbs: {
          endpoint: '/api/exhibit_thumbs'
        }
      },
      linkedExhibits: {
        type: 'multipleResource',
        endpoint: '/api/exhibits?order[ord]=desc&pagination=false',
        titleAccessor: `translations.${process.env.REACT_APP_LOCALE}.title`,
      },
      stat: {
        description: 'T_GENERAL_PUBLISH',
      },
    },
  },
}

export default schema
