const schema = {
  endpoint: '/api/exhibit_holiday_types',
  resource: {
    definition: 'ExhibitHolidayType-exhibitHolidayType.read',
    properties: {
      translations: {
        type: 'translation',
        properties: {
          title: {},
        },
      },
      stat: {
        description: 'T_GENERAL_PUBLISH',
      },
    },
  },
}

export default schema
