import fileSchema from './file'

const schema = {
  endpoint: '/api/package_files',
  resource: {
    definition: 'PackageFile-packageFile.read',
    properties: {
      title: {
        validate: ['required'],
      },
    },
  },
  subresources: {
    item: {
      endpoint: '/api/package_file_items',
      definition: 'PackageFileItem-packageFileItem.read',
      properties: {
        translations: {
          type: 'translation',
          properties: {
            title: {},
          },
        },
        file: {
          type: 'file',
          description: 'T_GENERAL_FILE',
          endpoint: fileSchema.endpoint.single,
          validate: ['maxSize'],
        },
      },
    },
  },
}

export default schema
