import React from 'react'
import { Route } from 'react-router-dom'
import { Collection } from './Collection'
import { basePath as polinParentBasePath } from 'pages/PolinRecipes/routes'
import { basePath as userParentBasePath } from 'pages/UserRecipes/routes'
import parentSchema from '_schema/recipe'

const routes = () =>({
  index_polin: {
    title: 'Polin recipe ratings',
    subtitle: 'T_GENERAL_RECORD_LIST',
    type: Route,
    render: props => <Collection oppositeType="user" {...props} />,
    path: '/polin_recipes/ratings/:id',
    indexPath: '/polin_recipes/ratings/:id',
    parent: polinParentBasePath,
    resourceAccess: parentSchema.endpoint,
    exact: true,
  },
  index_user: {
    title: 'User recipe ratings',
    subtitle: 'T_GENERAL_RECORD_LIST',
    type: Route,
    render: props => <Collection oppositeType="polin" {...props} />,
    path: '/user_recipes/ratings/:id',
    indexPath: '/user_recipes/ratings/:id',
    parent: userParentBasePath,
    resourceAccess: parentSchema.endpoint,
    exact: true,
  },
})

export default routes
