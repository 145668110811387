import adminSchema from 'core/_schema/admin'
import adminGroupSchema from 'core/_schema/adminGroup'
import loginEntry from 'core/_schema/loginEntry'
import registryEntry from 'core/_schema/registryEntry'
import page from 'core/_schema/page'
import translatorEntry from 'core/_schema/translatorEntry'
import packageFile from 'core/_schema/packageFile'
import packageGallery from 'core/_schema/packageGallery'
import homeConfigSchema from './homeConfig'
import recipe from './recipe'
import exhibit from './exhibit'
import workshop from './workshop'
import menuLink from './menuLink'
import footerLink from './footerLink'
import configSchema from './config'

const resources = () => ({
  [homeConfigSchema.endpoint]: 'Home config',
  '/api/paths': 'T_GENERAL_PATHS',
  [page.endpoint]: 'Pages',
  [recipe.endpoint]: 'Recipes',
  [exhibit.endpoint]: 'Exhibits',
  [packageFile.endpoint]: 'File packages',
  [packageGallery.endpoint]: 'Gallery packages',
  [workshop.endpoint]: 'Workshops',
  [menuLink.endpoint]: 'Menu links',
  [footerLink.endpoint]: 'Footer links',
  [adminSchema.endpoint]: 'Administrators',
  [adminGroupSchema.endpoint]: 'Administrator Groups',
  [loginEntry.endpoint]: 'Login entries',
  [registryEntry.endpoint]: 'Registry entries',
  [translatorEntry.endpoint]: 'Translator entries',
  [configSchema.endpoint]: 'Config',
})

export default resources
