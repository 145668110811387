import { types } from './_path_common_types'

const schema = {
  endpoint: '/api/tradition_blessings',
  resource: {
    definition: 'TraditionBlessings-traditionBlessings.read',
    sectionA: {
      properties: {
        translations: {
          type: 'translation',
          properties: {
            sectionATitle: {},
            sectionAText: {
              type: 'textarea'
            },
            sectionASubtextATitle: {},
            sectionASubtextAText: {
              type: 'textarea'
            },
            sectionASliderTitle: {
              type: 'textarea'
            },
          },
        },
        sectionARecipe: types.recipe,

        sectionAExhibitA: types.exhibit,
        sectionAExhibitASize: types.exhibit_size,
        sectionAExhibitAPosition: types.exhibit_position,
        sectionAExhibitAMask: types.exhibit_mask,

        sectionAExhibitB: types.exhibit,
        sectionAExhibitBSize: types.exhibit_size,
        sectionAExhibitBPosition: types.exhibit_position,
        sectionAExhibitBMask:types.exhibit_mask,

        sectionAExhibitC: types.exhibit,
        sectionAExhibitCSize: types.exhibit_size,
        sectionAExhibitCPosition: types.exhibit_position,
        sectionAExhibitCMask: types.exhibit_mask,

        sectionASliderExhibitA: types.exhibit,
        sectionASliderExhibitAMask: types.exhibit_mask,

        sectionASliderExhibitB: types.exhibit,
        sectionASliderExhibitBMask: types.exhibit_mask,

        sectionASliderExhibitC: types.exhibit,
        sectionASliderExhibitCMask: types.exhibit_mask,

        sectionASliderExhibitD: types.exhibit,
        sectionASliderExhibitDMask: types.exhibit_mask,

        sectionAStat: {}
      }
    },
    sectionB: {
      properties: {
        translations: {
          type: 'translation',
          properties: {
            sectionBTitle: {},
            sectionBText: {
              type: 'textarea'
            },
          },
        },
        sectionBExhibit: types.exhibit,
        sectionBExhibitSize: types.exhibit_size,
        sectionBExhibitPosition: types.exhibit_position,
        sectionBExhibitMask: types.exhibit_mask,

        sectionBStat: {}
      }
    },
    sectionC: {
      properties: {
        translations: {
          type: 'translation',
          properties: {
            sectionCTitle: {},
            sectionCText: {
              type: 'textarea'
            },
          },
        },
        sectionCExhibit: types.exhibit,
        sectionCExhibitSize: types.exhibit_size,
        sectionCExhibitPosition: types.exhibit_position,
        sectionCExhibitMask: types.exhibit_mask,

        sectionCStat: {}
      }
    },
    sectionD: {
      properties: {
        translations: {
          type: 'translation',
          properties: {
            sectionDTitle: {},
            sectionDText: {
              type: 'textarea'
            },
          },
        },
        sectionDExhibit: types.exhibit,
        sectionDExhibitSize: types.exhibit_size,
        sectionDExhibitPosition: types.exhibit_position,
        sectionDExhibitMask: types.exhibit_mask,

        sectionDStat: {}
      }
    },
  },
}

export default schema
