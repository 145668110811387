import React, { useState } from 'react'
import clsx from 'clsx'
import { Link } from 'react-router-dom'
import { Menu, MenuItem, IconButton } from '@material-ui/core'
import { MoreVertOutlined } from '@material-ui/icons'
import { makeStyles, withStyles  } from '@material-ui/core/styles'
import { EditLink } from 'core/components/resource/EditLink'
import { DeleteCell } from 'core/components/table/cells/DeleteCell'
import { StatusCell } from 'core/components/table/cells/StatusCell'
import { Timestamp } from 'core/components/resource/Timestamp'
import { translate } from 'core/_helpers/translate'
import recipeRatingRoutes from 'pages/RecipeRatings/routes'

const useStyles = makeStyles({
  root: {
    display: 'flex',
    alignItems: 'center',
  },
  menu_item: {
    fontSize: 14,
    '& svg': {
      fontSize: 20,
    },
  },
  list_item: {
    minWidth: 32,
  },
  menu_trigger: {
    borderRadius: 4,
    padding: 7,
  },
  memenu_trigger_open: {
    border: '1px solid #d3d4d5',
    borderBottomColor: '#fff',
    backgroundColor: '#fff',
    borderBottomRightRadius: 0,
    borderBottomLeftRadius: 0,
  },
  link: {
    width: '100%',
    textDecoration: 'none',
    color: 'inherit',
    '&:hover': {
      color: 'inherit',
    },
  }
})

const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',

    borderTopRightRadius: 0
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
));

export const operations = () => ({
  header: translate('T_GENERAL_OPERATIONS'),
  accessor: `translations.${process.env.REACT_APP_LOCALE}.title`,
  sortable: false,
  filterable: false,
  Cell: ({
    resource,
    accessor,
    tableState,
    tableStateDispatch,
    editPath = null,
  }) => {
    const classes = useStyles()
    const [menuAnchor, setMenuAnchor] = useState(null)

    const handleMenu = e => {
      setMenuAnchor(e.currentTarget)
    }

    const handleClose = () => {
      setMenuAnchor(null)
    }

    return (
      <div className={classes.root}>
        <IconButton
          aria-label="record menu"
          aria-controls="record-menu"
          aria-haspopup="true"
          onClick={handleMenu}
          className={clsx( classes.menu_trigger,
            menuAnchor && classes.memenu_trigger_open
          )}
        >
          <MoreVertOutlined color="inherit" />
        </IconButton>
        <StyledMenu
          id="record-menu"
          anchorEl={menuAnchor}
          keepMounted
          open={!!menuAnchor}
          onClose={handleClose}
        >
          <MenuItem className={classes.menu_item}>
            <EditLink
              resource={resource}
              accessor={accessor}
              editPath={editPath}
            />
          </MenuItem>
          <MenuItem className={classes.menu_item}>
            <Link to={recipeRatingRoutes().index_polin.path.replace(
              ':id',
              resource[process.env.REACT_APP_RESOURCE_ID]
            )} className={classes.link}>
              {translate('T_MODULE_RECIPE_RATINGS')}
            </Link>
          </MenuItem>
          <MenuItem className={classes.menu_item}>
            <DeleteCell
              resource={resource}
              accessor={accessor}
              tableState={tableState}
              tableStateDispatch={tableStateDispatch}
            />
          </MenuItem>
        </StyledMenu>
        <StatusCell
          resource={resource}
          tableStateDispatch={tableStateDispatch}
        />
        <Timestamp resource={resource} />
      </div>
    )
  },
})
