// import recipeHolidayTypeSchema from './recipeHolidayType'
import { types } from './_path_common_types'

const recipeHolidayTypeSchema = {
  type: 'resource',
  titleAccessor: `translations.${process.env.REACT_APP_LOCALE}.title`,
  // endpoint: `${recipeHolidayTypeSchema.endpoint}?order[ord]=desc&pagination=false`,
}

const schema = {
  endpoint: '/api/tradition_holidays',
  resource: {
    definition: 'TraditionHolidays-traditionHolidays.read',
    sectionA: {
      properties: {
        translations: {
          type: 'translation',
          properties: {
            sectionATitle: {},
            sectionAText: {
              type: 'textarea'
            },
          }
        },
        sectionAExhibitA: types.exhibit,
        sectionAExhibitASize: types.exhibit_size,
        sectionAExhibitAPosition: types.exhibit_position,
        sectionAExhibitAMask: types.exhibit_mask,

        sectionAExhibitB: types.exhibit,
        sectionAExhibitBSize: types.exhibit_size,
        sectionAExhibitBPosition: types.exhibit_position,
        sectionAExhibitBMask: types.exhibit_mask,

        sectionAExhibitC: types.exhibit,
        sectionAExhibitCSize: types.exhibit_size,
        sectionAExhibitCPosition: types.exhibit_position,
        sectionAExhibitCMask: types.exhibit_mask,

        sectionAStat: {}
      }
    },
    holidayA: {
      properties: {
        translations: {
          type: 'translation',
          properties: {
            holidayATitle: {},
            holidayAText: {
              type: 'textarea'
            },
            holidayARecipeTitle: {},
            holidayARecipeText: {
              type: 'textarea'
            }
          },
        },
        holidayARecipeHolidayType: recipeHolidayTypeSchema,
        holidayARecipe: types.recipe,

        holidayASliderAExhibitA: types.exhibit,
        holidayASliderAExhibitAMask: types.exhibit_mask,

        holidayASliderAExhibitB: types.exhibit,
        holidayASliderAExhibitBMask: types.exhibit_mask,

        holidayASliderAExhibitC: types.exhibit,
        holidayASliderAExhibitCMask: types.exhibit_mask,

        holidayASliderAExhibitD: types.exhibit,
        holidayASliderAExhibitDMask: types.exhibit_mask,

        holidayASliderAExhibitE: types.exhibit,
        holidayASliderAExhibitEMask: types.exhibit_mask,

        holidayASliderAExhibitF: types.exhibit,
        holidayASliderAExhibitFMask: types.exhibit_mask,

        holidayASliderAExhibitG: types.exhibit,
        holidayASliderAExhibitGMask: types.exhibit_mask,

        holidayASliderAExhibitH: types.exhibit,
        holidayASliderAExhibitHMask: types.exhibit_mask,

        holidayAStat: {}
      }
    },
    holidayB: {
      properties: {
        translations: {
          type: 'translation',
          properties: {
            holidayBTitle: {},
            holidayBText: {
              type: 'textarea'
            },
            holidayBSliderTitle: {
              type: 'textarea'
            },
            holidayBRecipeTitle: {},
            holidayBRecipeText: {
              type: 'textarea'
            },
            holidayBCharosetText: {
              type: 'textarea'
            },
            holidayBMarorText: {
              type: 'textarea'
            },
            holidayBBejcaText: {
              type: 'textarea'
            },
            holidayBMacaText: {
              type: 'textarea'
            },
            holidayBKarpasText: {
              type: 'textarea'
            },
            holidayBZeroaText: {
              type: 'textarea'
            },
          },
        },
        holidayBRecipeHolidayType: recipeHolidayTypeSchema,
        holidayBRecipe: types.recipe,

        holidayBSliderAExhibitA: types.exhibit,
        holidayBSliderAExhibitAMask: types.exhibit_mask,

        holidayBSliderAExhibitB: types.exhibit,
        holidayBSliderAExhibitBMask: types.exhibit_mask,

        holidayBSliderAExhibitC: types.exhibit,
        holidayBSliderAExhibitCMask: types.exhibit_mask,

        holidayBSliderAExhibitD: types.exhibit,
        holidayBSliderAExhibitDMask: types.exhibit_mask,

        holidayBExhibitA: types.exhibit,
        holidayBExhibitASize: types.exhibit_size,
        holidayBExhibitAPosition: types.exhibit_position,
        holidayBExhibitAMask: types.exhibit_mask,

        holidayBExhibitB: types.exhibit,
        holidayBExhibitBSize: types.exhibit_size,
        holidayBExhibitBPosition: types.exhibit_position,
        holidayBExhibitBMask: types.exhibit_mask,

        holidayBExhibitC: types.exhibit,
        holidayBExhibitCSize: types.exhibit_size,
        holidayBExhibitCPosition: types.exhibit_position,
        holidayBExhibitCMask: types.exhibit_mask,

        holidayBStat: {}
      }
    },
    holidayC: {
      properties: {
        translations: {
          type: 'translation',
          properties: {
            holidayCTitle: {},
            holidayCText: {
              type: 'textarea'
            },
          },
        },
        holidayCExhibit: types.exhibit,
        holidayCExhibitSize: types.exhibit_size,
        holidayCExhibitPosition: types.exhibit_position,
        holidayCExhibitMask: types.exhibit_mask,

        holidayCExhibitB: types.exhibit,
        holidayCExhibitBSize: types.exhibit_size,
        holidayCExhibitBPosition: types.exhibit_position,
        holidayCExhibitBMask: types.exhibit_mask,

        holidayCStat: {}
      }
    },
    holidayD: {
      properties: {
        translations: {
          type: 'translation',
          properties: {
            holidayDTitle: {},
            holidayDText: {
              type: 'textarea'
            },
          },
        },
        holidayDRecipeHolidayType: recipeHolidayTypeSchema,
        holidayDRecipe: types.recipe,

        holidayDStat: {}
      }
    },
    holidayE: {
      properties: {
        translations: {
          type: 'translation',
          properties: {
            holidayETitle: {},
            holidayEText: {
              type: 'textarea'
            },
          },
        },
        holidayERecipeHolidayType: recipeHolidayTypeSchema,
        holidayERecipe: types.recipe,

        holidayEExhibit: types.exhibit,
        holidayEExhibitSize: types.exhibit_size,
        holidayEExhibitPosition: types.exhibit_position,
        holidayEExhibitMask: types.exhibit_mask,

        holidayEStat: {}
      }
    },
    holidayF: {
      properties: {
        translations: {
          type: 'translation',
          properties: {
            holidayFTitle: {},
            holidayFText: {
              type: 'textarea'
            },
          },
        },
        holidayFRecipeHolidayType: recipeHolidayTypeSchema,
        holidayFRecipe: types.recipe,

        holidayFExhibit: types.exhibit,
        holidayFExhibitSize: types.exhibit_size,
        holidayFExhibitPosition: types.exhibit_position,
        holidayFExhibitMask: types.exhibit_mask,

        holidayFStat: {}
      }
    },
    holidayG: {
      properties: {
        translations: {
          type: 'translation',
          properties: {
            holidayGTitle: {},
            holidayGText: {
              type: 'textarea'
            },
          },
        },
        holidayGExhibit: types.exhibit,
        holidayGExhibitSize: types.exhibit_size,
        holidayGExhibitPosition: types.exhibit_position,
        holidayGExhibitMask: types.exhibit_mask,

        holidayGStat: {}
      }
    },
    holidayH: {
      properties: {
        translations: {
          type: 'translation',
          properties: {
            holidayHTitle: {},
            holidayHText: {
              type: 'textarea'
            },
          },
        },
        holidayHRecipeHolidayType: recipeHolidayTypeSchema,
        holidayHRecipe: types.recipe,

        holidayHStat: {}
      }
    },
    holidayI: {
      properties: {
        translations: {
          type: 'translation',
          properties: {
            holidayITitle: {},
            holidayIText: {
              type: 'textarea'
            },
          },
        },
        holidayIRecipeHolidayType: recipeHolidayTypeSchema,
        holidayIRecipe: types.recipe,

        holidayIStat: {}
      }
    },
    holidayJ: {
      properties: {
        translations: {
          type: 'translation',
          properties: {
            holidayJTitle: {},
            holidayJText: {
              type: 'textarea'
            },
          },
        },
        holidayJStat: {}
      }
    },
  },
}

export default schema
