import photoSchema from 'core/_schema/photo'
import recipeSchema from './recipe'

const schema = {
  endpoint: '/api/configs',
  resource: {
    definition: 'Config-config.read',
    properties: {
      translations: {
        type: 'translation',
        properties: {
          title: {},
          workshopsLink: {},
          contactText: {
            type: 'textarea',
          },
          instagramTag: {},
          instagramHashTags: {},
          metaTitle: {},
          metaDescription: {
            type: 'textarea',
          },
          metaKeywords: {
            type: 'textarea',
          },
        },
      },
      recipeOfTheWeek: {
        type: 'resource',
        titleAccessor: `translations.${process.env.REACT_APP_LOCALE}.title`,
        endpoint: `${recipeSchema.endpoint}?order[ord]=desc&pagination=false`,
      },
      linkFacebook: {},
      linkInstagram: {},
      additionalHeadCode: {
        type: 'textarea',
      },
      additionalBodyCode: {
        type: 'textarea',
      },
      photo: {
        type: 'image',
        description: 'T_MODULE_CONFIG_DEFAULT_FACEBOOK_PHOTO',
        endpoint: photoSchema.endpoint.single,
        validate: ['maxSize'],
      },
    },
  },
}

export default schema
