import React, { useState, useMemo } from 'react'
import { BlankForm } from 'core/pages'
import { Paper } from 'core/components/Paper'
import { Loader } from 'core/components/Loader'
import { Title } from 'core/components/Title'
import { usePropertyResourcesFetch } from 'core/_helpers/usePropertyResourcesFetch'
import { usePathFetch } from '_helpers/usePathFetch'
import { handlePathSectionSuccess } from '_helpers/handlePathSectionSuccess'
import { usePathSectionStyles } from 'pages/usePathSectionStyles'
import { NotFound } from 'core/pages'
import { translate } from 'core/_helpers/translate'
import schema from '_schema/diasporaAshkenaziTastes'
import recipeSchema from '_schema/recipe'
import exhibitSchema from '_schema/exhibit'

export const Edit = ({ schema: definitions }) => {
  const [state, setState] = useState({
    isFetching: true,
    fetchError: false,
    resource: null,
  })

  usePathFetch(setState, schema.endpoint)

  const additionalResourcesMapping = useMemo(
    () => ({
      recipe: `${recipeSchema.endpoint}?order[ord]=desc`,
      exhibit: `${exhibitSchema.endpoint}?order[ord]=desc`,
    }),
    []
  )

  const [additionalResources] = usePropertyResourcesFetch(
    additionalResourcesMapping
  )

  const resourceSchema = useMemo(() => ({
    ...schema.resource,
    sectionA: {
      ...schema.sectionA,
      properties: Object.assign(
        {},
        ...Object.keys(schema.resource.sectionA.properties).map(
          prop => ({ [prop] : {
            ...schema.resource.sectionA.properties[prop],
            ...(prop === 'sectionARecipeA'
              ? { resources: additionalResources.recipe }
              : {})
          }})
        )
      )
    },
    sectionB: {
      ...schema.sectionB,
      properties: Object.assign(
        {},
        ...Object.keys(schema.resource.sectionB.properties).map(
          prop => ({ [prop] : {
            ...schema.resource.sectionB.properties[prop],
            ...(prop === 'sectionBExhibitA'
              ? { resources: additionalResources.exhibit }
              : {})
          }})
        )
      )
    },
    sectionC: {
      ...schema.sectionC,
      properties: Object.assign(
        {},
        ...Object.keys(schema.resource.sectionC.properties).map(
          prop => ({ [prop] : {
            ...schema.resource.sectionC.properties[prop],
            ...([
              'sectionCSliderAExhibitA',
              'sectionCSliderAExhibitB',
              'sectionCSliderAExhibitC',
              'sectionCSliderAExhibitD',
            ].includes(prop)
              ? { resources: additionalResources.exhibit }
              : prop === 'sectionCRecipeA'
              ? { resources: additionalResources.recipe }
              : {})
          }})
        )
      )
    },
    sectionD: {
      ...schema.sectionD,
      properties: Object.assign(
        {},
        ...Object.keys(schema.resource.sectionD.properties).map(
          prop => ({ [prop] : {
            ...schema.resource.sectionD.properties[prop],
            ...(prop === 'sectionDRecipeA'
              ? { resources: additionalResources.recipe }
              : {})
          }})
        )
      )
    },
    sectionE: {
      ...schema.sectionE,
      properties: Object.assign(
        {},
        ...Object.keys(schema.resource.sectionE.properties).map(
          prop => ({ [prop] : {
            ...schema.resource.sectionE.properties[prop],
            ...(prop === 'sectionERecipeA'
              ? { resources: additionalResources.recipe }
              : {})
          }})
        )
      )
    },
    sectionG: {
      ...schema.sectionG,
      properties: Object.assign(
        {},
        ...Object.keys(schema.resource.sectionG.properties).map(
          prop => ({ [prop] : {
            ...schema.resource.sectionG.properties[prop],
            ...(prop === 'sectionGRecipeA'
              ? { resources: additionalResources.recipe }
              : {})
          }})
        )
      )
    },
    sectionH: {
      ...schema.sectionH,
      properties: Object.assign(
        {},
        ...Object.keys(schema.resource.sectionH.properties).map(
          prop => ({ [prop] : {
            ...schema.resource.sectionH.properties[prop],
            ...(prop === 'sectionHRecipeA'
              ? { resources: additionalResources.recipe }
              : {})
          }})
        )
      )
    },
    sectionI: {
      ...schema.sectionI,
      properties: Object.assign(
        {},
        ...Object.keys(schema.resource.sectionI.properties).map(
          prop => ({ [prop] : {
            ...schema.resource.sectionI.properties[prop],
            ...(prop === 'sectionIRecipeA'
              ? { resources: additionalResources.recipe }
              : {})
          }})
        )
      )
    },
    sectionJ: {
      ...schema.sectionJ,
      properties: Object.assign(
        {},
        ...Object.keys(schema.resource.sectionJ.properties).map(
          prop => ({ [prop] : {
            ...schema.resource.sectionJ.properties[prop],
            ...(prop === 'sectionJRecipeA'
              ? { resources: additionalResources.recipe }
              : {})
          }})
        )
      )
    },
    sectionK: {
      ...schema.sectionK,
      properties: Object.assign(
        {},
        ...Object.keys(schema.resource.sectionK.properties).map(
          prop => ({ [prop] : {
            ...schema.resource.sectionK.properties[prop],
            ...(prop === 'sectionKRecipeA'
              ? { resources: additionalResources.recipe }
              : {})
          }})
        )
      )
    },
  }), [additionalResources])

  const { isFetching, fetchError, resource } = state

  const classes = usePathSectionStyles()

  return isFetching ? (
    <Paper>
      <Loader />
    </Paper>
  ) : fetchError ? (
    <NotFound />
  ) : (
    <Paper>
      <div className={classes.section}>
        <Title className={classes.section_title}>
          {translate('T_PATHS_DIASPORA_ASHKENAZI_TASTES_SECTION_A')}
        </Title>
        <BlankForm
          resource={resource}
          definitionSchema={definitions[schema.resource.definition]}
          customResourceSchema={resourceSchema.sectionA}
          method={resource['@id'] ? 'PUT' : 'POST'}
          url={resource['@id'] || schema.endpoint}
          handleSuccess={handlePathSectionSuccess(setState, schema.resource.sectionA.properties)}
          showSubmitAndStayButton={false}
          showCancelButton={false}
          onlyPassedProperties={true}
          fieldsFullWidth={false}
          buttonsFixed={false}
          width={700}
          key={`diaspora-ashkenazi-tastes-section-a-${resource['@id'] || 'empty'}`}
        />
      </div>
      <div className={classes.section}>
        <Title className={classes.section_title}>
          {translate('T_PATHS_DIASPORA_ASHKENAZI_TASTES_SECTION_B')}
        </Title>
        <BlankForm
          resource={resource}
          definitionSchema={definitions[schema.resource.definition]}
          customResourceSchema={resourceSchema.sectionB}
          method={resource['@id'] ? 'PUT' : 'POST'}
          url={resource['@id'] || schema.endpoint}
          handleSuccess={handlePathSectionSuccess(setState, schema.resource.sectionB.properties)}
          showSubmitAndStayButton={false}
          showCancelButton={false}
          onlyPassedProperties={true}
          fieldsFullWidth={false}
          buttonsFixed={false}
          width={700}
          key={`diaspora-ashkenazi-tastes-section-b-${resource['@id'] || 'empty'}`}
        />
      </div>
      <div className={classes.section}>
        <Title className={classes.section_title}>
          {translate('T_PATHS_DIASPORA_ASHKENAZI_TASTES_SECTION_C')}
        </Title>
        <BlankForm
          resource={resource}
          definitionSchema={definitions[schema.resource.definition]}
          customResourceSchema={resourceSchema.sectionC}
          method={resource['@id'] ? 'PUT' : 'POST'}
          url={resource['@id'] || schema.endpoint}
          handleSuccess={handlePathSectionSuccess(setState, schema.resource.sectionC.properties)}
          showSubmitAndStayButton={false}
          showCancelButton={false}
          onlyPassedProperties={true}
          fieldsFullWidth={false}
          buttonsFixed={false}
          width={700}
          key={`diaspora-ashkenazi-tastes-section-c-${resource['@id'] || 'empty'}`}
        />
      </div>
      <div className={classes.section}>
        <Title className={classes.section_title}>
          {translate('T_PATHS_DIASPORA_ASHKENAZI_TASTES_SECTION_D')}
        </Title>
        <BlankForm
          resource={resource}
          definitionSchema={definitions[schema.resource.definition]}
          customResourceSchema={resourceSchema.sectionD}
          method={resource['@id'] ? 'PUT' : 'POST'}
          url={resource['@id'] || schema.endpoint}
          handleSuccess={handlePathSectionSuccess(setState, schema.resource.sectionD.properties)}
          showSubmitAndStayButton={false}
          showCancelButton={false}
          onlyPassedProperties={true}
          fieldsFullWidth={false}
          buttonsFixed={false}
          width={700}
          key={`diaspora-ashkenazi-tastes-section-d-${resource['@id'] || 'empty'}`}
        />
      </div>
      <div className={classes.section}>
        <Title className={classes.section_title}>
          {translate('T_PATHS_DIASPORA_ASHKENAZI_TASTES_SECTION_E')}
        </Title>
        <BlankForm
          resource={resource}
          definitionSchema={definitions[schema.resource.definition]}
          customResourceSchema={resourceSchema.sectionE}
          method={resource['@id'] ? 'PUT' : 'POST'}
          url={resource['@id'] || schema.endpoint}
          handleSuccess={handlePathSectionSuccess(setState, schema.resource.sectionE.properties)}
          showSubmitAndStayButton={false}
          showCancelButton={false}
          onlyPassedProperties={true}
          fieldsFullWidth={false}
          buttonsFixed={false}
          width={700}
          key={`diaspora-ashkenazi-tastes-section-e-${resource['@id'] || 'empty'}`}
        />
      </div>
      <div className={classes.section}>
        <Title className={classes.section_title}>
          {translate('T_PATHS_DIASPORA_ASHKENAZI_TASTES_SECTION_F')}
        </Title>
        <BlankForm
          resource={resource}
          definitionSchema={definitions[schema.resource.definition]}
          customResourceSchema={resourceSchema.sectionF}
          method={resource['@id'] ? 'PUT' : 'POST'}
          url={resource['@id'] || schema.endpoint}
          handleSuccess={handlePathSectionSuccess(setState, schema.resource.sectionF.properties)}
          showSubmitAndStayButton={false}
          showCancelButton={false}
          onlyPassedProperties={true}
          fieldsFullWidth={false}
          buttonsFixed={false}
          width={700}
          key={`diaspora-ashkenazi-tastes-section-f-${resource['@id'] || 'empty'}`}
        />
      </div>
      <div className={classes.section}>
        <Title className={classes.section_title}>
          {translate('T_PATHS_DIASPORA_ASHKENAZI_TASTES_SECTION_G')}
        </Title>
        <BlankForm
          resource={resource}
          definitionSchema={definitions[schema.resource.definition]}
          customResourceSchema={resourceSchema.sectionG}
          method={resource['@id'] ? 'PUT' : 'POST'}
          url={resource['@id'] || schema.endpoint}
          handleSuccess={handlePathSectionSuccess(setState, schema.resource.sectionG.properties)}
          showSubmitAndStayButton={false}
          showCancelButton={false}
          onlyPassedProperties={true}
          fieldsFullWidth={false}
          buttonsFixed={false}
          width={700}
          key={`diaspora-ashkenazi-tastes-section-g-${resource['@id'] || 'empty'}`}
        />
      </div>
      <div className={classes.section}>
        <Title className={classes.section_title}>
          {translate('T_PATHS_DIASPORA_ASHKENAZI_TASTES_SECTION_H')}
        </Title>
        <BlankForm
          resource={resource}
          definitionSchema={definitions[schema.resource.definition]}
          customResourceSchema={resourceSchema.sectionH}
          method={resource['@id'] ? 'PUT' : 'POST'}
          url={resource['@id'] || schema.endpoint}
          handleSuccess={handlePathSectionSuccess(setState, schema.resource.sectionH.properties)}
          showSubmitAndStayButton={false}
          showCancelButton={false}
          onlyPassedProperties={true}
          fieldsFullWidth={false}
          buttonsFixed={false}
          width={700}
          key={`diaspora-ashkenazi-tastes-section-h-${resource['@id'] || 'empty'}`}
        />
      </div>
      <div className={classes.section}>
        <Title className={classes.section_title}>
          {translate('T_PATHS_DIASPORA_ASHKENAZI_TASTES_SECTION_I')}
        </Title>
        <BlankForm
          resource={resource}
          definitionSchema={definitions[schema.resource.definition]}
          customResourceSchema={resourceSchema.sectionI}
          method={resource['@id'] ? 'PUT' : 'POST'}
          url={resource['@id'] || schema.endpoint}
          handleSuccess={handlePathSectionSuccess(setState, schema.resource.sectionI.properties)}
          showSubmitAndStayButton={false}
          showCancelButton={false}
          onlyPassedProperties={true}
          fieldsFullWidth={false}
          buttonsFixed={false}
          width={700}
          key={`diaspora-ashkenazi-tastes-section-i-${resource['@id'] || 'empty'}`}
        />
      </div>
      <div className={classes.section}>
        <Title className={classes.section_title}>
          {translate('T_PATHS_DIASPORA_ASHKENAZI_TASTES_SECTION_J')}
        </Title>
        <BlankForm
          resource={resource}
          definitionSchema={definitions[schema.resource.definition]}
          customResourceSchema={resourceSchema.sectionJ}
          method={resource['@id'] ? 'PUT' : 'POST'}
          url={resource['@id'] || schema.endpoint}
          handleSuccess={handlePathSectionSuccess(setState, schema.resource.sectionJ.properties)}
          showSubmitAndStayButton={false}
          showCancelButton={false}
          onlyPassedProperties={true}
          fieldsFullWidth={false}
          buttonsFixed={false}
          width={700}
          key={`diaspora-ashkenazi-tastes-section-j-${resource['@id'] || 'empty'}`}
        />
      </div>
      <div className={classes.section}>
        <Title className={classes.section_title}>
          {translate('T_PATHS_DIASPORA_ASHKENAZI_TASTES_SECTION_K')}
        </Title>
        <BlankForm
          resource={resource}
          definitionSchema={definitions[schema.resource.definition]}
          customResourceSchema={resourceSchema.sectionK}
          method={resource['@id'] ? 'PUT' : 'POST'}
          url={resource['@id'] || schema.endpoint}
          handleSuccess={handlePathSectionSuccess(setState, schema.resource.sectionK.properties)}
          showSubmitAndStayButton={false}
          showCancelButton={false}
          onlyPassedProperties={true}
          fieldsFullWidth={false}
          buttonsFixed={false}
          width={700}
          key={`diaspora-ashkenazi-tastes-section-k-${resource['@id'] || 'empty'}`}
        />
      </div>
    </Paper>
  )
}
