import { types } from './_path_common_types'

const schema = {
  endpoint: '/api/diaspora_sephardic_tastes',
  resource: {
    definition: 'DiasporaSephardicTastes-diasporaSephardicTastes.read',
    sectionA: {
      properties: {
        translations: {
          type: 'translation',
          properties: {
            sectionATitle: {},
            sectionAMealATitle: {},
            sectionAMealAText: {
              type: 'textarea'
            },
            sectionAMealASubtitle: {},
            sectionAMealBTitle: {},
            sectionAMealBText: {
              type: 'textarea'
            },
            sectionAMealBSubtitle: {},
            sectionAMealCTitle: {},
            sectionAMealCText: {
              type: 'textarea'
            },
            sectionAMealCSubtitle: {},
          }
        },
        sectionARecipeA: types.recipe,

        sectionAExhibitA: types.exhibit,
        sectionAExhibitASize: types.exhibit_size,
        sectionAExhibitAPosition: types.exhibit_position,
        sectionAExhibitAMask: types.exhibit_mask,

        sectionAStat: {}
      }
    },
    sectionB: {
      properties: {
        translations: {
          type: 'translation',
          properties: {
            sectionBTitle: {},
            sectionBMealATitle: {},
            sectionBMealAText: {
              type: 'textarea'
            },
            sectionBMealASubtitle: {},
            sectionBMealBTitle: {},
            sectionBMealBText: {
              type: 'textarea'
            },
            sectionBMealBSubtitle: {},
            sectionBSubtextATitle: {},
            sectionBSubtextAText: {
              type: 'textarea'
            },
          }
        },
        sectionBRecipeA: types.recipe,
        sectionBRecipeB: types.recipe,

        sectionBStat: {}
      }
    },
    sectionC: {
      properties: {
        translations: {
          type: 'translation',
          properties: {
            sectionCMealATitle: {},
            sectionCMealAText: {
              type: 'textarea'
            },
            sectionCMealASubtitle: {},
            sectionCMealBTitle: {},
            sectionCMealBText: {
              type: 'textarea'
            },
            sectionCMealBSubtitle: {},
          }
        },
        sectionCRecipeA: types.recipe,
        sectionCRecipeB: types.recipe,

        sectionCStat: {}
      }
    },
    sectionD: {
      properties: {
        translations: {
          type: 'translation',
          properties: {
            sectionDMealATitle: {},
            sectionDMealAText: {
              type: 'textarea'
            },
            sectionDMealASubtitle: {},
            sectionDMealBTitle: {},
            sectionDMealBText: {
              type: 'textarea'
            },
            sectionDMealBSubtitle: {},
          }
        },
        sectionDRecipeA: types.recipe,
        sectionDRecipeB: types.recipe,

        sectionDStat: {}
      }
    },
  },
}

export default schema
