export const translations = {
  pl: {
    /* Inside application code */
    T_GENERAL_OPERATIONS: 'Operacje',
    T_GENERAL_EDIT: 'Edytuj',
    T_GENERAL_NEW: 'Dodaj',
    T_GENERAL_CLONE: 'Klonuj',
    T_GENERAL_CLONING: 'Klonuję...',
    T_GENERAL_VERSION_CREATE: 'Archiwizuj',
    T_GENERAL_VERSION_CREATING: 'Archiwizuję...',
    T_GENERAL_VERSION_RESTORE: 'Przywróć wersję',
    T_GENERAL_VERSION_RESTORING: 'Przywracam wersję...',
    T_GENERAL_VERSION_CREATED_AT: 'Wersja z dnia',
    T_GENERAL_VIEW: 'Pokaż',
    T_GENERAL_DELETE: 'Usuń',
    T_GENERAL_DELETE_CONFIRM: 'Czy napewno usunąć',
    T_GENERAL_DELETED: 'Rekord usunięty',
    T_GENERAL_CREATED_AT: 'Utworzono',
    T_GENERAL_UPDATED_AT: 'Ostatnia aktualizacja',
    T_GENERAL_CANCEL: 'Anuluj',
    T_GENERAL_CONFIRM: 'Potwierdź',
    T_GENERAL_STATUS_CHANGED: 'Zmieniono status',
    T_GENERAL_SAVE: 'Zapisz',
    T_GENERAL_SAVE_TO_KEEP_CHANGES: 'Zapisz aby zachować zmiany',
    T_GENERAL_SEARCH: 'Szukaj',
    T_GENERAL_ALL: 'Wszystkie',
    T_GENERAL_REFRESH: 'Odswież',
    T_GENERAL_TOGGLE_MENU: 'Przełącznik menu',
    T_GENERAL_RESULT_SUCCESS: 'Udane',
    T_GENERAL_RESULT_FAILURE: 'Nieudane',
    T_GENERAL_NO: 'Nr',
    T_GENERAL_OPERATION_DATE: 'Data operacji',
    T_GENERAL_MODULE: 'Moduł',
    T_GENERAL_RECORD: 'Rekord',
    T_GENERAL_OPERATION: 'Operacja',
    T_GENERAL_SHOW_ALL: 'Pokaż wszystkie',
    T_GENERAL_REQUIRED: 'Wymagany',
    T_GENERAL_DATE: 'Data',
    T_GENERAL_EMPTY: 'Brak',
    T_GENERAL_TITLE: 'Tytuł',
    T_GENERAL_INVALID_DATE: 'Niepoprawny format',
    T_GENERAL_INVALID_MIN_DATE: 'Data nie może być mniejsza niż 01.01.1990',
    T_GENERAL_INVALID_MAX_DATE: 'Data nie może być większa niż 01.01.2100',
    T_GENERAL_DESCRIPTION: 'Krótki opis',
    T_GENERAL_DESCRIPTION_HINT: 'Wyświetlany na detalu',
    T_GENERAL_LEAD: 'Lead',
    T_GENERAL_LEAD_HINT: 'Wyświetlany na liście',
    T_GENERAL_PUBLICATION: 'Publikacja',
    T_GENERAL_CODE: 'Kod',
    T_GENERAL_TIME: 'Czas',
    T_GENERAL_ADMIN: 'Administrator',
    T_GENERAL_RECORD_LIST: 'Lista',
    T_GENERAL_RECORD_NEW: 'Dodaj',
    T_GENERAL_RECORD_NEW_BUTTON: 'Dodaj nowy rekord',
    T_GENERAL_RECORD_EDIT: 'Edycja',
    T_GENERAL_RECORD_SHOW: 'Pokaż',
    T_GENERAL_RECORD_PREVIEW: 'Podgląd',
    T_GENERAL_RECORD_VERSION_COLLECTION: 'Poprzednie wersje',
    T_GENERAL_RECORD_VERSION_VIEW: 'Podgląd',
    T_GENERAL_ITEMS_EMPTY: 'Brak elementów',
    T_GENERAL_BLOCK: 'Bloki',
    T_GENERAL_BLOCK_TYPE_TEXT: 'Blok tekstowy',
    T_GENERAL_BLOCK_TYPE_TEXT_WITH_VIDEO: 'Blok tekstowy z filmem',
    T_GENERAL_BLOCK_TYPE_TEXT_WITH_AUDIO: 'Blok tekstowy z audio',
    T_GENERAL_BLOCK_TYPE_PACKAGE_FILE: 'Blok z plikami',
    T_GENERAL_BLOCK_TYPE_PACKAGE_GALLERY: 'Blok z galerią',
    T_GENERAL_BLOCK_GALLERY_TYPE_SLIDER: 'Slider',
    T_GENERAL_BLOCK_GALLERY_TYPE_LOGOS: 'Loga',
    T_GENERAL_CHOOSE: 'Wybierz',
    T_GENERAL_MISSING_TITLE: '<Brak tytułu>',
    T_GENERAL_SUPER_ADMIN: 'Super Admin',
    T_GENERAL_TRANSLATION: 'Tłumaczenie',
    T_GENERAL_MANUAL_ORDER: 'Manualna kolejność',
    T_GENERAL_MANUAL_ORDER_PAGE_DROPZONE_PREVIOUS:
      'Upuść tu żeby przenieść na poprzednią stronę',
    T_GENERAL_MANUAL_ORDER_PAGE_DROPZONE_NEXT:
      'Upuść tu żeby przenieść na następną stronę',
    T_GENERAL_DRAG_HANDLE: 'Przytrzymaj i przeciągnij, aby zmienić kolejność',
    T_GENERAL_UPLOAD: 'Upload pliku',
    T_GENERAL_UPLOAD_MULTIPLE: 'Upload plików',
    T_GENERAL_PHOTO: 'Zdjęcie',
    T_GENERAL_IMAGE_ADD: 'Dodaj zdjęcie',
    T_GENERAL_IMAGE_CHANGE: 'Wymień zdjęcie',
    T_GENERAL_IMAGE_REMOVE: 'Usuń zdjęcie',
    T_GENERAL_IMAGE_REMOVE_CONFIRM: 'Czy napewno usunąć zdjęcie?',
    T_GENERAL_PHOTO_THUMBS: 'Kadry',
    T_GENERAL_PHOTO_THUMBS_EMPTY: 'Brak kadrów',
    T_GENERAL_PHOTO_THUMBS_SAVE: 'Zapisz kadr',
    T_GENERAL_PHOTO_THUMBS_RESET: 'Resetuj kadr',
    T_GENERAL_PHOTO_THUMBS_CHANGE_FILE: 'Zmień plik dla kadru',
    T_GENERAL_EXPAND_ON: 'zwiń wszystkie',
    T_GENERAL_EXPAND_OFF: 'rozwiń wszystkie',
    T_GENERAL_EXPAND_BLOCKS: 'bloki',
    T_GENERAL_BLOCK_COLLECTION_TITLE: 'Bloki śródtekstowe',
    T_GENERAL_ALT: 'Znacznik alt zdjęcia',
    T_GENERAL_FILE: 'Plik',
    T_GENERAL_FILE_ADD: 'Dodaj plik',
    T_GENERAL_FILE_CHANGE: 'Wymień plik',
    T_GENERAL_FILE_REMOVE: 'Usuń plik',
    T_GENERAL_FILE_REMOVE_CONFIRM: 'Czy napewno usunąć plik?',
    T_GENERAL_NAME: 'Nazwa',
    T_GENERAL_SEND: 'Wyślij',
    T_GENERAL_CHOSEN_FILE: 'Wybrany plik',
    T_GENERAL_CHOSEN_FILES: 'Wybrane pliki',
    T_GENERAL_NONE: 'brak',
    T_GENERAL_YOUTUBE_CODE: 'Kod filmu youtube',
    T_GENERAL_VIDEO_MASK: 'Maska filmu',
    T_GENERAL_MEDIA_PHOTO: 'Zdjęcie',
    T_GENERAL_MEDIA_YOUTUBE: 'Film youtube',
    T_GENERAL_MASS_SELECT_ACTION: 'Co zrobić z zaznaczonymi rekordami',
    T_GENERAL_MASS_SELECT_CHOOSE: 'Wybierz',
    T_GENERAL_MASS_SELECT_PUBLISH: 'Publikuj',
    T_GENERAL_MASS_SELECT_UNPUBLISH: 'Odpublikuj',
    T_GENERAL_ENABLE: 'Włącz',
    T_GENERAL_DISABLE: 'Wyłącz',
    T_GENERAL_OPENFORM_ADMIN_GROUP_IS_OPENFORM: '[OF] Prawa openform',
    T_GENERAL_OPENFORM_PAGE_IDNAME: '[OF] Id name',
    T_GENERAL_OPENFORM_PAGE_IS_STATABLE: '[OF] Publikowalna',
    T_GENERAL_OPENFORM_PAGE_IS_EDITABLE: '[OF] Edytowalna',
    T_GENERAL_OPENFORM_PAGE_IS_DELETABLE: '[OF] Usuwalna',
    T_GENERAL_OPENFORM_PAGE_IS_SUBPAGEABLE: '[OF] Podstrony',
    T_GENERAL_SAVE_FORM_TO_EDIT: 'Zapisz formularz, aby edytować',
    T_GENERAL_PASSWORD_WAS_RESETTED:
      'Twoje hasło zostało zresetowane przez administratora. Ustaw nowe hasło, a następnie przeloguj się, aby odzyskać dostęp.',
    T_GENERAL_EDIT_DESCRIPTION: 'edytuj opis',
    T_GENERAL_BACK_TO_LIST: 'Wróć na listę',
    T_GENERAL_NEXT: 'Następny',
    T_GENERAL_PREVIOUS: 'Poprzedni',
    T_GENERAL_FULL_SCREEN: 'Full screen',
    T_GENERAL_LOGIN: 'Zaloguj',
    T_GENERAL_LOGOUT: 'Wyloguj',
    T_GENERAL_REMEMBER_ME: 'Zapamiętaj mnie',
    T_GENERAL_FORGOT_PASSWORD: 'Zapomniałem hasła',
    T_GENERAL_FORGOT_PASSWORD_TITLE: 'Zapomniałem hasła',
    T_GENERAL_FORGOT_PASSWORD_TYPE_EMAIL: 'Twój adres e-mail',
    T_GENERAL_FORGOT_PASSWORD_SEND_LINK: 'Wyślij link',
    T_GENERAL_FORGOT_PASSWORD_VALIDATION_EMAIL: 'Niepoprawny adres e-mail',
    T_GENERAL_FORGOT_PASSWORD_LINK_SENT:
      'Link wysłany. Sprawdź swój adres e-mail',
    T_GENERAL_NEW_PASSWORD_TITLE: 'Nadaj nowe hasło',
    T_GENERAL_NEW_PASSWORD_TYPE_NEW: 'Wpisz nowe hasło',
    T_GENERAL_NEW_PASSWORD_RETYPE_NEW: 'Powtórz nowe hasło',
    T_GENERAL_NEW_PASSWORD_CHANGE: 'Zmień hasło',
    T_GENERAL_NEW_PASSWORD_RESETTED: 'Hasło zostało zmienione',
    T_GENERAL_BACK_TO_LOGIN: 'Powrót do logowania',
    T_GENERAL_MAP: 'Mapa',
    T_GENERAL_MAP_HINT:
      'Wpisz pełną lokalizację lub częściową i wybierz z listy. Marker na mapie zostanie automatycznie ustawiony',
    T_GENERAL_MAP_INPUT: 'Wpisz lokalizację',
    T_GENERAL_NORMAL_VIEW: 'Powrót do poprzedniego widoku',
    T_GENERAL_RESOURCE_ADD_MULTIPLE_CHOSEN: 'Dodajesz % plików',
    T_GENERAL_RESOURCE_ADD_MULTIPLE_INFO: 'Nadaj wszystkim dodawanym:',
    T_GENERAL_RESOURCE_ADD_MULTIPLE_UPLOADING: 'Dodaję',
    T_GENERAL_RESOURCE_ADD_MULTIPLE_UPLOADING_FROM: 'z',
    T_GENERAL_CONTEXTUAL_HINT: 'Pomoc kontekstowa',
    T_GENERAL_YOU_ARE_LOGGED_AS: 'Jesteś zalogowany jako',
    T_GENERAL_MASS_UPLOAD_DRAG_AND_DROP:
      'Przeciągnij i upuść lub kliknij, żeby wybrać',
    T_GENERAL_PLANNED_PUBLISH_DATE_TIME: 'Planowana publikacja',
    T_GENERAL_PUBLISH: 'Publikuj',
    T_GENERAL_UNPUBLISH: 'Odpublikuj',
    T_GENERAL_VERSION: 'Wersja',
    T_GENERAL_DOWNLOAD_YOUTUBE_THUMBNAIL: 'Pobierz zaślepkę z youtube',
    T_GENERAL_LINK: 'Link',
    T_GENERAL_FRONT_ROUTE: 'Adres',
    T_GENERAL_AUDIO: 'Plik audio',
    T_GENERAL_EMAIL_SENT: 'E-mail wysłany',
    T_GENERAL_PATHS: 'Ścieżki',
    T_GENERAL_PHOTO_MASK_RECTANGULAR: 'Prostokąt',
    T_GENERAL_PHOTO_MASK_ROUND: 'Okrąg wertykalny',
    T_GENERAL_PHOTO_MASK_ROUND_HORIZONTAL: 'Okrąg horyzontalny',
    T_GENERAL_PHOTO_MASK_DIAMOND: 'Romb',
    T_GENERAL_PHOTO_MASK_DIAMOND_WITH_INDENTATION: 'Romb z wcięciami',
    T_GENERAL_PHOTO_MASK_WINDOW: 'Okno',
    T_GENERAL_PATH_EXHIBIT_POSITION_RIGHT: 'Po prawej',
    T_GENERAL_PATH_EXHIBIT_POSITION_INSIDE: 'Wewnątrz',
    T_GENERAL_PATH_EXHIBIT_POSITION_UNDER: 'Pod spodem',
    T_GENERAL_PATH_EXHIBIT_SIZE_SMALL: 'Mały',
    T_GENERAL_PATH_EXHIBIT_SIZE_LARGE: 'Duży',
    T_GENERAL_PATH_EXHIBIT_SIZE_FULL: 'Na pełną wysokość',
    T_GENERAL_PATH_EXHIBIT_SIZE_FULL_DECORATIVE: 'Na pełną wysokość dekoracyjny',
    T_PATHS_TRADITION: 'Ścieżka Tradycja',
    T_PATHS_TRADITION_RULES_SECTION_A: 'Sekcja A: Zasady koszerności',
    T_PATHS_TRADITION_RULES_SECTION_B: 'Sekcja B: Fundamenty zasad żywieniowych',
    T_PATHS_TRADITION_RULES_SECTION_C: 'Sekcja C: Praktyki koszerności',
    T_PATHS_TRADITION_TABLE_SECTION_A: 'Sekcja A: Stół Tradycji',
    T_PATHS_TRADITION_THEN_AND_NOW_SECTION_A: 'Sekcja A: Koszerność dawniej i dziś',
    T_PATHS_TRADITION_THEN_AND_NOW_SECTION_B: 'Sekcja B: Certyfikaty koszerności',
    T_PATHS_TRADITION_THEN_AND_NOW_SECTION_C: 'Sekcja C: W podążaniu za zmianą',
    T_PATHS_TRADITION_BLESSINGS_SECTION_A: 'Sekcja A: Błogosławieństwa',
    T_PATHS_TRADITION_BLESSINGS_SECTION_B: 'Sekcja B: Obmywanie rąk',
    T_PATHS_TRADITION_BLESSINGS_SECTION_C: 'Sekcja C: Kidusz',
    T_PATHS_TRADITION_BLESSINGS_SECTION_D: 'Sekcja D: Koszerność wina',
    T_PATHS_TRADITION_HOLIDAYS_THEN_AND_NOW_SECTION_A: 'Sekcja A: Święta dawniej i dziś',
    T_PATHS_TRADITION_HOLIDAYS_THEN_AND_NOW_SECTION_B: 'Sekcja B: Przygotowania na Pesach',
    T_PATHS_TRADITION_HOLIDAYS_THEN_AND_NOW_SECTION_C: 'Sekcja C: Pozostałe święta',
    T_PATHS_TRADITION_HOLIDAYS_SECTION_A: 'Sekcja A: Święta',
    T_PATHS_TRADITION_HOLIDAYS_HOLIDAY_A: 'Święto A: Szabat',
    T_PATHS_TRADITION_HOLIDAYS_HOLIDAY_B: 'Święto B: Pesach',
    T_PATHS_TRADITION_HOLIDAYS_HOLIDAY_C: 'Święto C: Rosz ha-Szana',
    T_PATHS_TRADITION_HOLIDAYS_HOLIDAY_D: 'Święto D: Jom Kipur',
    T_PATHS_TRADITION_HOLIDAYS_HOLIDAY_E: 'Święto E: Sukot',
    T_PATHS_TRADITION_HOLIDAYS_HOLIDAY_F: 'Święto F: Chanuka',
    T_PATHS_TRADITION_HOLIDAYS_HOLIDAY_G: 'Święto G: Tu bi-szwat',
    T_PATHS_TRADITION_HOLIDAYS_HOLIDAY_H: 'Święto H: Purim',
    T_PATHS_TRADITION_HOLIDAYS_HOLIDAY_I: 'Święto I: Szawout',
    T_PATHS_TRADITION_HOLIDAYS_HOLIDAY_J: 'Święto J: Tisza be-Av',
    T_PATHS_DIASPORA: 'Ścieżka Diaspora',
    T_PATHS_DIASPORA_MANNERS_SECTION_A: 'Sekcja A: Lokalność i obyczaj',
    T_PATHS_DIASPORA_ASHKENAZI_SECTION_A: 'Sekcja A: Kuchnia aszkenazyjska',
    T_PATHS_DIASPORA_ASHKENAZI_SECTION_B: 'Sekcja B: Niemcy - kolebka diaspory aszkenazyjskiej',
    T_PATHS_DIASPORA_ASHKENAZI_SECTION_C: 'Sekcja C: Włochy - skrzyżowanie kultur',
    T_PATHS_DIASPORA_ASHKENAZI_SECTION_D: 'Sekcja D: Polska i Litwa - słodko i ostro',
    T_PATHS_DIASPORA_ASHKENAZI_SECTION_E: 'Sekcja E: Krajobraz z karczmą',
    T_PATHS_DIASPORA_ASHKENAZI_SECTION_F: 'Sekcja F: W Cesarstwie Habsburskim',
    T_PATHS_DIASPORA_ASHKENAZI_TASTES_SECTION_A: 'Sekcja A: Aszkenazyjskie smaki',
    T_PATHS_DIASPORA_ASHKENAZI_TASTES_SECTION_B: 'Sekcja B: Smalec',
    T_PATHS_DIASPORA_ASHKENAZI_TASTES_SECTION_C: 'Sekcja C: Śledź',
    T_PATHS_DIASPORA_ASHKENAZI_TASTES_SECTION_D: 'Sekcja D: Czulent',
    T_PATHS_DIASPORA_ASHKENAZI_TASTES_SECTION_E: 'Sekcja E: Kugel',
    T_PATHS_DIASPORA_ASHKENAZI_TASTES_SECTION_F: 'Sekcja F: Gęsie pipki (gęsia szyja)',
    T_PATHS_DIASPORA_ASHKENAZI_TASTES_SECTION_G: 'Sekcja G: Rosół',
    T_PATHS_DIASPORA_ASHKENAZI_TASTES_SECTION_H: 'Sekcja H: Cymes',
    T_PATHS_DIASPORA_ASHKENAZI_TASTES_SECTION_I: 'Sekcja I: Holiszki (gołąbki)',
    T_PATHS_DIASPORA_ASHKENAZI_TASTES_SECTION_J: 'Sekcja J: Bajgiel (niem. wygięcie)',
    T_PATHS_DIASPORA_ASHKENAZI_TASTES_SECTION_K: 'Sekcja K: Siekana wątróbka',
    T_PATHS_DIASPORA_SEPHARDIC_SECTION_A: 'Sekcja A: Kuchnia sefardyjska i orientalna',
    T_PATHS_DIASPORA_SEPHARDIC_SECTION_B: 'Sekcja B: Starożytne korzenie Żydów orientalnych - Afryka Północna i Bliski Wschód',
    T_PATHS_DIASPORA_SEPHARDIC_SECTION_C: 'Sekcja C: Czasy kalifatu - smaki z różnych stron świata',
    T_PATHS_DIASPORA_SEPHARDIC_SECTION_D: 'Sekcja D: W muzułmańskiej i chrześcijańskiej Hiszpani',
    T_PATHS_DIASPORA_SEPHARDIC_SECTION_E: 'Sekcja E: Migracje żydów sefardyjskich i ich tradycji kulinarnych',
    T_PATHS_DIASPORA_SEPHARDIC_SECTION_F: 'Sekcja F: W Imperium Osmańskim',
    T_PATHS_DIASPORA_SEPHARDIC_TASTES_SECTION_A: 'Sekcja A: Sefardyjskie i orientalne smaki',
    T_PATHS_DIASPORA_SEPHARDIC_TASTES_SECTION_B: 'Sekcja B: Szabatowy koćiołek - adafina i hamin',
    T_PATHS_DIASPORA_SEPHARDIC_TASTES_SECTION_C: 'Sekcja C: Kubaneh',
    T_PATHS_DIASPORA_SEPHARDIC_TASTES_SECTION_D: 'Sekcja D: Mina de Espinaca',
    T_PATHS_MODERNITY: 'Nowoczesność',
    T_PATHS_MODERNITY_BOOKS_SECTION_A: 'Sekcja A: Książki kucharskie',
    T_PATHS_MODERNITY_BOOKS_SECTION_B: 'Sekcja B: Książki imigrantów w USA',
    T_PATHS_MODERNITY_BOOKS_SECTION_C: 'Sekcja C: Nowe trendy',
    T_PATHS_MODERNITY_BOOKS_SECTION_D: 'Sekcja D: Nowoczesne panie domu',
    T_PATHS_MODERNITY_SITUATIONS_SECTION_A: 'Sekcja A: Nowe sytuacje',
    T_PATHS_MODERNITY_SITUATIONS_SECTION_B: 'Sekcja B: Kawiarnie',
    T_PATHS_MODERNITY_SITUATIONS_SECTION_C: 'Sekcja C: Nowe ideologie',
    T_PATHS_MODERNITY_SITUATIONS_SECTION_D: 'Sekcja D: Masowa gastronomia',
    T_PATHS_MODERNITY_WARS_SECTION_A: 'Sekcja A: Wojny',
    T_PATHS_MODERNITY_WARS_SECTION_B: 'Sekcja B: II Wojna światowa',
    T_PATHS_MODERNITY_MIGRATIONS_SECTION_A: 'Sekcja A: Migracje smaków',
    T_PATHS_MODERNITY_MIGRATIONS_SECTION_B: 'Sekcja B: American story',
    T_PATHS_MODERNITY_MIGRATIONS_SECTION_C: 'Sekcja C: Bajgiel z łososiem (tzw. lox and bagel)',
    T_PATHS_MODERNITY_MIGRATIONS_SECTION_D: 'Sekcja D: Przemysł koszerny',
    T_PATHS_MODERNITY_MIGRATIONS_SECTION_E: 'Sekcja E: Nowa tradycja',
    T_PATHS_MODERNITY_KITCHEN_SECTION_A: 'Sekcja A: Kuchnia izraelska',
    T_PATHS_MODERNITY_KITCHEN_SECTION_B: 'Sekcja B: Nowa kultura',
    T_PATHS_MODERNITY_KITCHEN_SECTION_C: 'Sekcja C: Kultura barów i restauracji',
    T_PATHS_MODERNITY_KITCHEN_SECTION_D: 'Sekcja D: Hummus',
    T_PATHS_MODERNITY_POLAND_SECTION_A: 'Sekcja A: Polska dziś',
    T_PATHS_MODERNITY_POLAND_SECTION_B: 'Sekcja B: Restauracje "żydowskie"',
    T_PATHS_MODERNITY_POLAND_SECTION_C: 'Sekcja C: Współczesność',
    T_PATHS_MODERNITY_TABLE_SECTION_A: 'Sekcja A: Stół Nowoczesności',
    T_PATHS_MEMORY: 'Ścieżka Wspomnienia',
    T_PATHS_DIASPORA_TABLE_SECTION_A: 'Sekcja A: Stół Diaspory',
    T_PATHS_MEMORY_STORIES_SECTION_A: 'Sekcja A: Historie osobiste',
    T_PATHS_MEMORY_STORIES_SECTION_B: 'Sekcja B: Karp po żydowsku',
    T_PATHS_MEMORY_SOUVENIRS_SECTION_A: 'Sekcja A: Pamiątki',
    T_PATHS_MEMORY_TABLE_SECTION_A: 'Sekcja A: Stół Wspomnień',
    T_LOGIN_DATE: 'Data logowania',
    T_TABLE_FIRST_PAGE: 'Pierwsza strona',
    T_TABLE_LAST_PAGE: 'Ostatnia strona',
    T_TABLE_RESET: 'Wyczyść ustawienia tabeli',
    T_TABLE_NO_RESULTS: 'Brak wyników',
    T_MODULE_DASHBOARD: 'Dashboard',
    T_MODULE_SETTINGS: 'Ustawienia',
    T_MODULE_PROFILE: 'Profil',
    T_MODULE_PACKAGE_GALLERIES: 'Lista galerii',
    T_MODULE_PACKAGE_GALLERIES_NEW_BUTTON: 'Dodaj nową galerię',
    T_MODULE_PACKAGE_GALLERY_ITEMS: 'Lista mediów',
    T_MODULE_PACKAGE_GALLERY_ITEMS_NEW_BUTTON: 'Dodaj nowe zdjęcie',
    T_MODULE_PACKAGE_GALLERY_ITEMS_NEW_MULTIPLE_BUTTON: 'Dodaj wiele zdjęć',
    T_MODULE_PACKAGE_FILES: 'Lista pakietów plików',
    T_MODULE_PACKAGE_FILES_NEW_BUTTON: 'Dodaj nowy pakiet',
    T_MODULE_TRANSLATOR_ENTRIES: 'Lista tłumaczeń',
    T_MODULE_TRANSLATOR_ENTRIES_NEW_BUTTON: 'Dodaj nowe tłumaczenie',
    T_MODULE_ADMINS: 'Lista administratorów',
    T_MODULE_ADMINS_NEW_BUTTON: 'Dodaj nowego administratora',
    T_MODULE_ADMIN_GROUPS: 'Lista grup administratorskich',
    T_MODULE_ADMIN_GROUPS_NEW_BUTTON: 'Dodaj nową grupę administratorską',
    T_MODULE_PAGES: 'Lista stron',
    T_MODULE_PAGES_NEW_BUTTON: 'Dodaj nową stronę',
    T_MODULE_PAGES_EDIT_STRUCTURE: 'Edycja struktury',
    T_MODULE_PAGES_SHOW_ALL_CHILDREN: 'Rozwiń wszystko',
    T_MODULE_PAGES_HIDE_ALL_CHILDREN: 'Zwiń wszystko',
    T_MODULE_PAGES_ADD_SUBPAGE: 'Dodaj podstronę',
    T_MODULE_PAGES_SUBPAGE_UNDER_PREVIOUS_PAGE:
      'Przenieś jako podstronę strony wyżej',
    T_MODULE_PACKAGE_FILE_ITEMS_HEADER_TITLE: 'Pliki:',
    T_MODULE_PACKAGE_FILE_ITEMS_EXPAND_TITLE: 'pliki',
    T_MODULE_MESSENGER: 'Wiadomości',
    T_MODULE_MESSENGER_RECIPIENT: 'Odbiorca',
    T_MODULE_MESSENGER_SEND_TO: 'Napisz do',
    T_MODULE_MESSENGER_GO_FURTHER: 'Przejdź dalej',
    T_MODULE_MESSENGER_THREAD: 'Wątek',
    T_MODULE_MESSENGER_MESSAGE: 'Wiadomość',
    T_MODULE_MESSENGER_WITH: 'z',
    T_MODULE_MESSENGER_SENT: 'Wiadomość wysłana',
    T_MODULE_MESSENGER_MORE: 'Więcej',
    T_MODULE_MESSENGER_UPDATED_AT: 'Aktualizacja',
    T_MODULE_MESSENGER_YOU: 'Ty',
    T_MODULE_MENU_LINK_COL_TOP: 'górna',
    T_MODULE_MENU_LINK_COL_LEFT: 'lewa',
    T_MODULE_MENU_LINK_COL_RIGHT: 'prawa',
    T_MODULE_FOOTER_LINK_COL_LEFT: 'lewa',
    T_MODULE_FOOTER_LINK_COL_RIGHT: 'prawa',
    T_MODULE_FOOTER_LINK_COL_BOTTOM: 'dolna',
    T_MODULE_CONFIG_DEFAULT_FACEBOOK_PHOTO: 'Domyślne zdjęcie na Facebooka',
    T_MODULE_HOME_CONFIG_SECTION_A: 'Sekcja A: Od kuchni',
    T_MODULE_HOME_CONFIG_SECTION_B: 'Sekcja B: Tradycja',
    T_MODULE_HOME_CONFIG_SECTION_C: 'Sekcja C: Koszerne trefne',
    T_MODULE_HOME_CONFIG_SECTION_D: 'Sekcja D: Diaspora',
    T_MODULE_HOME_CONFIG_SECTION_E: 'Sekcja E: Nowoczesność',
    T_MODULE_HOME_CONFIG_SECTION_F: 'Sekcja F: Migracje smaków',
    T_MODULE_HOME_CONFIG_SECTION_G: 'Sekcja G: Wspomnienia',
    T_MODULE_EXHIBIT_CATEGORIES_TITLE: 'Kategorie eksponatów',
    T_MODULE_EXHIBIT_SECTIONS_TITLE: 'Działy wystawy',
    T_MODULE_EXHIBIT_HOLIDAY_TYPES_TITLE: 'Święta',
    T_MODULE_EXHIBIT_STORAGE_LOCATIONS_TITLE: 'Miejsca przechowywania',
    T_MODULE_EXHIBIT_ENTER_YEAR: 'Wpisz rok',
    T_MODULE_EXHIBIT_CREATION_YEAR_TO_MISSING_YEAR_FROM: 'Brakujący początek przedziału',
    T_MODULE_EXHIBIT_CREATION_YEAR_TO_LESSER_THAN_YEAR_FROM: 'Podany początek przedziału jest mniejszy niż koniec',
    T_MODULE_RECIPE_MEAL_TYPES_TITLE: 'Rodzaje posiłku',
    T_MODULE_RECIPE_DIET_TYPES_TITLE: 'Rodzaje diety',
    T_MODULE_RECIPE_HOLIDAY_TYPES_TITLE: 'Święta',
    T_MODULE_RECIPE_PREPARATION_TIMES_TITLE: 'Czasy przygotowania',
    T_MODULE_RECIPE_SENT_PHOTOS: 'Nadesłane zdjęcia',
    T_MODULE_RECIPE_CHOOSE_PHOTO: 'Wybierz zdjęcie',
    T_MODULE_RECIPE_PHOTO_CHOSEN: 'Zdjęcie ustawione',
    T_MODULE_RECIPE_USER_SEND_ACCEPT_EMAIL_BUTTON: 'Zatwierdź',
    T_MODULE_RECIPE_USER_SEND_ACCEPT_EMAIL_TOOLTIP: 'Wyślij mail z akceptacją',
    T_MODULE_RECIPE_USER_SEND_ACCEPT_EMAIL_TITLE: 'Wysyłanie maila z akceptacją',
    T_MODULE_RECIPE_USER_SEND_ACCEPT_EMAIL_TEXT: 'Czy napewno wysłać e-mail z akceptacją przepisu',
    T_MODULE_RECIPE_USER_SEND_REJECT_EMAIL_BUTTON: 'Odrzuć',
    T_MODULE_RECIPE_USER_SEND_REJECT_EMAIL_TOOLTIP: 'Wyślij mail z odrzuceniem',
    T_MODULE_RECIPE_USER_SEND_REJECT_EMAIL_TITLE: 'Wysyłanie maila z odrzuceniem',
    T_MODULE_RECIPE_USER_SEND_REJECT_EMAIL_TEXT: 'Czy napewno wysłać e-mail z odrzuceniem przepisu',
    T_MODULE_RECIPE_USER_ACCEPTED_AT: 'Zatwierdzono',
    T_MODULE_RECIPE_USER_REJECTED_AT: 'Odrzucono',
    T_MODULE_RECIPE_RATINGS: 'Oceny',
    T_MODULE_RECIPE_RATINGS_AUTHOR: 'Autor',
    T_MODULE_RECIPE_INGREDIENT_LIST_A: 'A',
    T_MODULE_RECIPE_INGREDIENT_LIST_B: 'B',
    T_MODULE_RECIPE_INGREDIENT_LIST_C: 'C',
    T_MENU_PACKAGES: 'Pakiety',
    T_MENU_REGISTRIES: 'Rejestry',
    T_MENU_LINKS: 'Linki',
    T_MENU_RECIPES: 'Przepisy',
    T_MENU_EXHIBITS: 'Biblioteka obiektów',
    T_REQUEST_METHOD_GET: 'Wyświetlenie',
    T_REQUEST_METHOD_PATCH: 'Edycja',
    T_REQUEST_METHOD_PUT: 'Edycja',
    T_REQUEST_METHOD_POST: 'Utworzenie',
    T_REQUEST_METHOD_DELETE: 'Usunięcie',
    T_FORM_PROFILE_TITLE: 'Dane',
    T_FORM_CHANGE_PASSWORD_TITLE: 'Zmiana hasła',
    T_FORM_FIELD_EMAIL: 'E-mail',
    T_FORM_FIELD_PASSWORD: 'Hasło',
    T_FORM_FIELD_NEW_PASSWORD: 'Nowe hasło',
    T_FORM_FIELD_NEW_PASSWORD_REPEAT: 'Powtórz nowe hasło',
    T_FORM_FIELD_HINT_ENTER_NEW_PASSWORD: 'Podaj nowe hasło',
    T_FORM_FIELD_HINT_REPEAT_NEW_PASSWORD: 'Powtórz nowe hasło',
    T_FORM_FIELD_RESOURCE_ACCESS: 'Dostęp do modułów',
    T_FORM_FIELD_BOOLEAN_ON: 'Tak',
    T_FORM_FIELD_BOOLEAN_OFF: 'Nie',
    T_FORM_INCORRECT: 'Niepoprawny formularz',
    T_FORM_SUCCESS: 'Zapisano',
    T_FORM_RECORD_UPDATED: 'Rekord zaktualizowany',
    T_FORM_RECORD_CREATED: 'Rekord dodany',
    T_FORM_RECORD_CLONED: 'Rekord sklonowany',
    T_FORM_RECORD_MULTIPLE_CREATED: 'Rekordy dodane',
    T_FORM_RECORD_MULTIPLE_UPDATED: 'Rekordy zaktaulizowane',
    T_FORM_RECORD_UPLOADED: 'Rekord wgrany',
    T_FORM_RECORD_VERSION_CREATED: 'Wersja rekordu utworzona',
    T_FORM_RECORD_VERSION_RESTORED: 'Wersja rekordu przywrócona',
    T_FORM_CONTEXTUAL_HINTS_ON: 'Pomoc kontekstowa włączona',
    T_FORM_CONTEXTUAL_HINTS_OFF: 'Pomoc kontekstowa wyłączona',
    T_FORM_SAVE: 'Zapisz',
    T_FORM_SAVE_AND_STAY: 'Zapisz i pozostań',
    T_FORM_ACCEPT: 'Akceptuj',
    T_FORM_CANCEL: 'Anuluj',
    T_FORM_RECORD_CHANGED:
      'Rekord został zmieniony przez innego administratora. Sprawdź zmiany i zaakceptuj.',
    T_VALIDATION_REQUIRED: 'Pole wymagane',
    T_VALIDATION_EMAIL: 'Niepoprawny e-mail',
    T_VALIDATION_PHONE: 'Niepoprawny numer telefonu',
    T_VALIDATION_PASSWORD:
      'Hasło musi składać się z min. 8 znaków (duża litera, mała litera, cyfra, znak specjalny)',
    T_VALIDATION_PASSWORDS_DO_NOT_MATCH: 'Hasła nie zgadzają się',
    T_VALIDATION_LENGTH: 'To pole musi się składać z min. % znaków',
    T_VALIDATION_MAX_UPLOAD_SIZE: 'Maksymalny rozmiar pliku to %',
    T_VALIDATION_MAX_UPLOAD_MULTIPLE_SIZE:
      'Maksymalny rozmiar wszystkich plików to %',
    T_VALIDATION_MIN_VALUE: 'Minimalna wartość to %',
    T_VALIDATION_MAX_VALUE: 'Maksymalna wartość to %',
    T_404_TITLE: 'Strona nie istnieje',
    T_404_TEXT:
      'Wygląda na to, że kliknąłeś niedziałający link lub wprowadziłeś adres URL, który nie istnieje na tej witrynie.',
    T_404_BACK: 'Powrót na stronę główną',
    T_SETTINGS_MODE_LIGHT: 'Przejdź na tryb jasny',
    T_SETTINGS_MODE_DARK: 'Przejdź na tryb ciemny',
    T_FRONT_PREVIEW_ERROR_TITLE: 'Błąd',
    T_FRONT_PREVIEW_ERROR_TEXT: 'Błąd przy pobieraniu strony frontowej',
    /* Thumbs */
    'T_THUMB_API/PAGE_THUMBS_MAIN': 'Kadr główny',
    'T_THUMB_API/PAGE_BLOCK_THUMBS_MASK': 'Maska',
    'T_THUMB_API/WORKSHOP_THUMBS_MAIN': 'Kadr główny',
    'T_THUMB_API/EXHIBIT_THUMBS_LIST': 'Kadr na listę',
    'T_THUMB_API/EXHIBIT_THUMBS_RECTANGULAR': 'Kadr prostokątny',
    'T_THUMB_API/EXHIBIT_THUMBS_ROUND': 'Kadr okręgu wertykalnego',
    'T_THUMB_API/EXHIBIT_THUMBS_ROUND_HORIZONTAL': 'Kadr okręgu horyzontalnego',
    'T_THUMB_API/EXHIBIT_THUMBS_DIAMOND': 'Kadr rombu',
    'T_THUMB_API/EXHIBIT_THUMBS_DIAMOND_WITH_INDENTATION': 'Kadr rombu z wcięciami',
    'T_THUMB_API/EXHIBIT_THUMBS_WINDOW': 'Kadr okna',
    'T_THUMB_API/RECIPE_THUMBS_LIST': 'Kadr na listę',
    'T_THUMB_API/RECIPE_THUMBS_HORIZONTAL': 'Kadr główny na detal',
    'T_THUMB_API/RECIPE_THUMBS_LINKED': 'Kadr dla powiązanego przepisu',
    'T_THUMB_API/RECIPE_THUMBS_OF_THE_WEEK': 'Kadr dla przepisu tygodnia',
    'T_THUMB_API/PACKAGE_GALLERY_ITEM_THUMBS_RECTANGULAR': 'Kadr prostokątny',
    'T_THUMB_API/PACKAGE_GALLERY_ITEM_THUMBS_ROUND': 'Kadr okręgu wertykalnego',
    'T_THUMB_API/PACKAGE_GALLERY_ITEM_THUMBS_ROUND_HORIZONTAL': 'Kadr okręgu horyzontalnego',
    'T_THUMB_API/PACKAGE_GALLERY_ITEM_THUMBS_DIAMOND': 'Kadr rombu',
    'T_THUMB_API/PACKAGE_GALLERY_ITEM_THUMBS_DIAMOND_WITH_INDENTATION': 'Kadr rombu z wcięciami',
    'T_THUMB_API/PACKAGE_GALLERY_ITEM_THUMBS_WINDOW': 'Kadr okna',
    /* Returned by API: modules */
    Administrators: 'Administratorzy',
    'Administrator Groups': 'Grupy administratorskie',
    'Registry entries': 'Rejestr zmian',
    'Login entries': 'Historia logowania',
    Pages: 'Struktura stron',
    'Page blocks': 'Bloki strony',
    'Page block thumbs': 'Kadry bloku strony',
    'File packages': 'Pliki',
    'File package items': 'Elementy pakietu plików',
    'Gallery packages': 'Galerie',
    'Gallery package items': 'Elementy galerii',
    'Gallery package item thumbs': 'Kadry elementu galerii',
    'Translator entries': 'Tłumaczenia',
    'Menu links': 'Linki w menu',
    'Footer links': 'Linki w stopce',
    Config: 'Konfiguracja',
    Recipes: 'Przepisy',
    'Polin recipes': 'Przepisy POLIN',
    'User recipes': 'Przepisy użytkowników',
    'Recipe ratings': 'Oceny przepisów',
    'Polin recipe ratings': 'Oceny przepisów POLIN',
    'User recipe ratings': 'Oceny przepisów użytkowników',
    'Recipe thumbs': 'Kadry przepisu',
    'Recipe ingredients': 'Składniki przepisu',
    'Recipe steps': 'Kroki przepisu',
    'Recipe step thumbs': 'Kadry zdjęcia kroku przepisu',
    'Recipe meal types': 'Rodzaje posiłków przepisów',
    'Recipe diet types': 'Rodzaje diet przepisów',
    'Recipe holiday types': 'Typy świąt dla przepisów',
    'Recipe preparation times': 'Czasy przygotowania przepisów',
    Exhibits: 'Obiekty',
    'Exhibit categories': 'Kategorie obiektów',
    'Exhibit sections': 'Działy wystawy dla obiektów',
    'Exhibit holiday types': 'Typy świąt dla obiektów',
    'Exhibit storage locations': 'Miejsce przechowywania obiektów',
    'Exhibit thumbs': 'Kadry obiektu',
    'Home config': 'Strona główna',
    Workshops: 'Warsztaty',
    'Workshop thumbs': 'Kadry warsztatu',
    'Tradition rules': 'Zasady koszerności',
    'Tradition then and now': 'Koszerność dawniej i dziś',
    'Tradition blessings': 'Błogosławieństwa',
    'Tradition holidays': 'Święta',
    'Tradition holidays then and now': 'Święta dawniej i dziś',
    'Tradition table': 'Stół tradycji',
    'Diaspora manners': 'Lokalność i obyczaj',
    'Diaspora ashkenazi': 'Kuchnia aszkenazyjska',
    'Diaspora ashkenazi tastes': 'Aszekanzyjskie smaki',
    'Diaspora sephardic': 'Kuchnia sefardyjska',
    'Diaspora sephardic tastes': 'Sefardyjskie smaki',
    'Diaspora table': 'Stół diaspory',
    'Modernity books': 'Książki kucharskie',
    'Modernity situations': 'Nowe sytuacje',
    'Modernity wars': 'Wojny',
    'Modernity migrations': 'Migracje smaków',
    'Modernity kitchen': 'Kuchnia izraelska',
    'Modernity poland': 'Polska dziś',
    'Modernity table': 'Stół nowoczesności',
    'Memory stories': 'Historie osobiste',
    'Memory souvenirs': 'Pamiątki',
    'Memory table': 'Stół wspomnień',
    /* Returned by API: fields */
    'Name and surname': 'Imię i nazwisko',
    Group: 'Grupa',
    'Is enabled': 'Aktywny',
    Title: 'Tytuł',
    Username: 'Nazwa użytkownika',
    'Origin ip': 'IP',
    Result: 'Status',
    'Is super admin': 'Prawa super admina',
    'Title of article': 'Tytuł artykułu',
    'Text of article': 'Zawartość artykułu',
    'Show in main menu': 'Pokaż w menu głównym',
    Text: 'Tekst główny',
    'Meta title': '[SEO] Meta tytuł',
    'Meta description': '[SEO] Meta opis',
    'Meta keywords': '[SEO] Meta słowa kluczowe',
    Slug: 'Slug (generowany automatycznie z tytułu)',
    'Code of translator entry': 'Kod tłumaczenia',
    'Title of translator entry': 'Tłumaczenie',
    Question: 'Pytanie',
    Answer: 'Odpowiedź',
    Name: 'Nazwa',
    'Type of element': 'Typ elementu',
    'Is required': 'Wymagany do uzupełnienia',
    Description: 'Opis',
    Subtitle: 'Podtytuł',
    Quotation: 'Cytat',
    'Button title': 'Tytuł linka',
    'Button link': 'Link',
    'File package': 'Pakiet plików',
    'Gallery package': 'Pakiet galerii',
    Sections: 'Sekcje',
    'Text before media': 'Tekst przed zdjęciem',
    'Text after media': 'Tekst po zdjęciu',
    'Item type': 'Typ medium',
    'Media position': 'Pozycja zdjęcia',
    'Gallery type': 'Typ galerii',
    'Photo count': 'Zdjęć',
    'Video count': 'Filmów',
    'Media type': 'Typ medium',
    Photographer: 'Fotograf',
    Column: 'Kolumna',
    'Contact text': 'Tekst kontaktu w stopce',
    'Link to facebook': 'Link do profilu Facebooka',
    'Link to instagram': 'Link do profilu Instagrama',
    'Additional head code': 'Dodatkowy kod sekcji HTML HEAD',
    'Additional body code': 'Dodatkowy kod sekcji HTML BODY',
    Photo: 'Zdjęcie',
    '3D exposition link': 'Link do wystawy 3D',
    'Linked exhibits': 'Powiązane obiekty',
    'Linked recipes': 'Powiązane przepisy',
    'Section A: superscription': 'Nadtytuł',
    'Section A: title': 'Tytuł',
    'Section A: text': 'Tekst',
    'Section B: superscription': 'Nadtytuł',
    'Section B: title': 'Tytuł',
    'Section B: text': 'Tekst',
    'Section B: exhibit A': 'Obiekt A',
    'Section B: exhibit A mask': 'Maska obiektu A',
    'Section B: exhibit B': 'Obiekt B',
    'Section B: exhibit B mask': 'Maska obiektu B',
    'Section C: quote': 'Cytat',
    'Section C: quote author': 'Autor cytatu',
    'Section C: rules title': 'Tytuł zasad',
    'Section C: rules text': 'Tekst zasad',
    'Section D: superscription': 'Nadtytuł',
    'Section D: title': 'Tytuł',
    'Section D: text': 'Tekst',
    'Section D: kitchen A title': 'Kuchnia A - tytuł',
    'Section D: kitchen A text': 'Kuchnia A - tekst',
    'Section D: kitchen B title': 'Kuchnia B - tytuł',
    'Section D: kitchen B text': 'Kuchnia B - tekst',
    'Section D: kitchen A - recipe A': 'Kuchnia A - przepis A',
    'Section D: kitchen A - recipe B': 'Kuchnia A - przepis B',
    'Section D: kitchen A - recipe C': 'Kuchnia A - przepis C',
    'Section D: kitchen B - recipe A': 'Kuchnia B - przepis A',
    'Section D: kitchen B - recipe B': 'Kuchnia B - przepis B',
    'Section D: kitchen B - recipe C': 'Kuchnia B - przepis C',
    'Section E: superscription': 'Nadtytuł',
    'Section E: title': 'Tytuł',
    'Section E: text': 'Tekst',
    'Section E: exhibit A': 'Obiekt A',
    'Section E: exhibit A mask': 'Maska obiektu A',
    'Section E: exhibit B': 'Obiekt B',
    'Section E: exhibit B mask': 'Maska obiektu B',
    'Section E: exhibit C': 'Obiekt C',
    'Section E: exhibit C mask': 'Maska obiektu C',
    'Section F: title': 'Tytuł',
    'Section F: text': 'Tekst',
    'Section F: country A title': 'Kraj A - tytuł',
    'Section F: country A text': 'Kraj A - tekst',
    'Section F: country B title': 'Kraj B - tytuł',
    'Section F: country B text': 'Kraj B - tekst',
    'Section F: country C title': 'Kraj C - tytuł',
    'Section F: country C text': 'Kraj C - tekst',
    'Section F: country A - exhibit A': 'Kraj A - obiekt A',
    'Section F: country A - exhibit B': 'Kraj A - obiekt B',
    'Section F: country A - exhibit C': 'Kraj A - obiekt C',
    'Section F: country B - exhibit A': 'Kraj B - obiekt A',
    'Section F: country B - exhibit B': 'Kraj B - obiekt B',
    'Section F: country B - exhibit C': 'Kraj B - obiekt C',
    'Section F: country C - exhibit A': 'Kraj C - obiekt A',
    'Section F: country C - exhibit B': 'Kraj C - obiekt B',
    'Section F: country C - exhibit C': 'Kraj C - obiekt C',
    'Section G: superscription': 'Nadtytuł',
    'Section G: title': 'Tytuł',
    'Section G: text': 'Tekst',
    'Section G: video title': 'Wideo - tytuł',
    'Section G: video text': 'Wideo - tekst',
    'Section G: quote': 'Cytat',
    'Section G: quote author': 'Autor cytatu',
    'Section G: exhibit A': 'Obiekt A',
    'Section G: exhibit A mask': 'Maska obiektu A',
    'Section G: exhibit B': 'Obiekt B',
    'Section G: exhibit B mask': 'Maska obiektu B',
    'Photo: title': 'Zdjęcie: tytuł',
    'Photo: author': 'Zdjęcie: autor',
    'Photo: alt': 'Zdjęcie: znacznik alt',
    Ingredients: 'Składniki',
    Ingredient: 'Składnik',
    Steps: 'Kroki',
    Step: 'Krok',
    'Has quality mark': 'Posiada znak jakości Kuchni Polin',
    'Current rating': 'Aktualna ocena',
    'Introduction': 'Wprowadzenie',
    'Number of servings': 'Liczba porcji',
    'Holiday type': 'Święto',
    'Preparation time': 'Czas przygotowania',
    'Category': 'Kategoria',
    'Storage location': 'Miejsce przechowywania',
    'Creation time': 'Czas powstania',
    Signature: 'Podpis',
    'Meal types': 'Rodzaje posiłku',
    'Diet types': 'Rodzaje diety',
    'Workshops link': 'Link do strony zbiorczej warsztatów',
    'Recipe of the week': 'Przepis tygodnia',
    'Instagram tag': 'Tag do instagrama',
    'Instagram hash tags': 'Hashtagi do instagrama',
    'Author name': 'Autor',
    'Author email': 'Adres e-mail autora',
    'Rating': 'Ocena',
    'Creation year or creation range start': 'Rok powstania lub początek przedziału powstania',
    'Creation range end': 'Koniec przedziału powstania',
    'Section A: subtext A title': 'Międzytekst A - tytuł',
    'Section A: subtext A text': 'Międzytekst A - tekst',
    'Section A: quote A': 'Cytat 1',
    'Section A: quote A author': 'Autor cytatu 1',
    'Section A: quote B': 'Cytat 2',
    'Section A: quote B author': 'Autor cytatu 2',
    'Section A: quote C': 'Cytat 3',
    'Section A: quote C author': 'Autor cytatu 3',
    'Section A: quote D': 'Cytat 4',
    'Section A: quote D author': 'Autor cytatu 4',
    'Section A: exhibit A': 'Obiekt A',
    'Section A: exhibit A mask': 'Maska obiektu A',
    'Section A: exhibit A position': 'Pozycja tekstu obiektu A',
    'Section A: exhibit A size': 'Rozmiar obiektu A',
    'Section A: exhibit B': 'Obiekt B',
    'Section A: exhibit B mask': 'Maska obiektu B',
    'Section A: exhibit B position': 'Pozycja tekstu obiektu B',
    'Section A: exhibit B size': 'Rozmiar obiektu B',
    'Section B: subtitle': 'Podtytuł',
    'Section B: option A title': 'Pionowa karuzela, opcja 1 - tytuł',
    'Section B: option A text': 'Pionowa karuzela, opcja 1 - tekst',
    'Section B: option B title': 'Pionowa karuzela, opcja 2 - tytuł',
    'Section B: option B text': 'Pionowa karuzela, opcja 2 - tekst',
    'Section B: option C title': 'Pionowa karuzela, opcja 3 - tytuł',
    'Section B: option C text': 'Pionowa karuzela, opcja 3 - tekst',
    'Section B: option D title': 'Pionowa karuzela, opcja 4 - tytuł',
    'Section B: option D text': 'Pionowa karuzela, opcja 4 - tekst',
    'Section B: option E title': 'Pionowa karuzela, opcja 5 - tytuł',
    'Section B: option E text': 'Pionowa karuzela, opcja 5 - tekst',
    'Section B: option F title': 'Pionowa karuzela, opcja 6 - tytuł',
    'Section B: option F text': 'Pionowa karuzela, opcja 6 - tekst',
    'Section B: subtext A title': 'Międzytekst A - tytuł',
    'Section B: subtext A text': 'Międzytekst A - tekst',
    'Section B: subtext B title': 'Międzytekst B - tytuł',
    'Section B: subtext B text': 'Międzytekst B - tekst',
    'Section B: photo - text': 'Opis zdjęcia A',
    'Section B: photo - author': 'Autor zdjęcia A',
    'Section B: video title': 'Tytuł filmu',
    'Section B: video text': 'Opis filmu',
    'Section B: youtube': 'Kod youtube filmu',
    'Section C: title': 'Tytuł',
    'Section C: subtitle': 'Podtytuł',
    'Section C: text': 'Tekst',
    'Section C: subtext A title': 'Międzytekst A - tytuł',
    'Section C: subtext A text': 'Międzytekst A - tekst',
    'Section C: subtext B title': 'Międzytekst B - tytuł',
    'Section C: subtext B text': 'Międzytekst B - tekst',
    'Section C: subtext C title': 'Międzytekst C - tytuł',
    'Section C: subtext C text': 'Międzytekst C - tekst',
    'Section C: video title': 'Tytuł filmu',
    'Section C: video text': 'Opis filmu',
    'Section C: exhibit A': 'Obiekt A',
    'Section C: exhibit A mask': 'Maska obiektu A',
    'Section C: exhibit A position': 'Pozycja tekstu obiektu A',
    'Section C: exhibit A size': 'Rozmiar obiektu A',
    'Section C: exhibit B': 'Obiekt B',
    'Section C: exhibit B mask': 'Maska obiektu B',
    'Section C: exhibit B position': 'Pozycja tekstu obiektu B',
    'Section C: exhibit B size': 'Rozmiar obiektu B',
    'Section C: exhibit C': 'Obiekt C',
    'Section C: exhibit C mask': 'Maska obiektu C',
    'Section C: exhibit C position': 'Pozycja tekstu obiektu C',
    'Section C: exhibit C size': 'Rozmiar obiektu C',
    'Section C: exhibit D': 'Obiekt D',
    'Section C: exhibit D mask': 'Maska obiektu D',
    'Section C: exhibit D position': 'Pozycja tekstu obiektu D',
    'Section C: exhibit D size': 'Rozmiar obiektu D',
    'Section C: exhibit E': 'Obiekt E',
    'Section C: exhibit E mask': 'Maska obiektu E',
    'Section C: exhibit E position': 'Pozycja tekstu obiektu E',
    'Section C: exhibit E size': 'Rozmiar obiektu E',
    'Section C: youtube': 'Kod filmu youtube',
    'Section A: photo text': 'Tytuł zdjęcia',
    'Section A: photo subtext': 'Opis zdjęcia',
    'Section A: photo author': 'Autor zdjęcia',
    'Section A: photo alt': 'Alt zdjęcia',
    'Section A: installation text': 'Tekst instalacji',
    'Section A: installation link': 'Link instalacji',
    'Section A: exhibit C': 'Obiekt C',
    'Section A: exhibit C size': 'Rozmiar obiektu C',
    'Section A: exhibit C position': 'Pozycja tekstu obiektu C',
    'Section A: exhibit C mask': 'Maska obiektu C',
    'Section A: exhibit D': 'Obiekt D',
    'Section A: exhibit D size': 'Rozmiar obiektu D',
    'Section A: exhibit D position': 'Pozycja tekstu obiektu D',
    'Section A: exhibit D mask': 'Maska obiektu D',
    'Section B: slider A - text': 'Tytuł slidera obiektów A',
    'Section B: slider A - subtitle': 'Podpis slidera obiektów A',
    'Section B: slider B - text': 'Tytuł slidera obiektów B',
    'Section B: slider B - subtitle': 'Podpis slidera obiektów B',
    'Section B: recipe': 'Przepis A',
    'Section B: exhibit A size': 'Rozmiar obiektu A',
    'Section B: exhibit A position': 'Pozycja tekstu obiektu A',
    'Section B: slider A - exhibit A': 'Slider A - obiekt 1',
    'Section B: slider A - exhibit A mask': 'Slider A - maska obiektu 1',
    'Section B: slider A - exhibit B': 'Slider A - obiekt 2',
    'Section B: slider A - exhibit B mask': 'Slider A - maska obiektu 2',
    'Section B: slider A - exhibit C': 'Slider A - obiekt 3',
    'Section B: slider A - exhibit C mask': 'Slider A - maska obiektu 3',
    'Section B: slider A - exhibit D': 'Slider A - obiekt 4',
    'Section B: slider A - exhibit D mask': 'Slider A - maska obiektu 4',
    'Section B: slider A - exhibit E': 'Slider A - obiekt 5',
    'Section B: slider A - exhibit E mask': 'Slider A - maska obiektu 5',
    'Section B: slider A - exhibit F': 'Slider A - obiekt 6',
    'Section B: slider A - exhibit F mask': 'Slider A - maska obiektu 6',
    'Section B: slider A - exhibit G': 'Slider A - obiekt 7',
    'Section B: slider A - exhibit G mask': 'Slider A - maska obiektu 7',
    'Section B: slider B - exhibit A': 'Slider B - obiekt 1',
    'Section B: slider B - exhibit A mask': 'Slider B - maska obiektu 1',
    'Section B: slider B - exhibit B': 'Slider B - obiekt 2',
    'Section B: slider B - exhibit B mask': 'Slider B - maska obiektu 2',
    'Section B: slider B - exhibit C': 'Slider B - obiekt 3',
    'Section B: slider B - exhibit C mask': 'Slider B - maska obiektu 3',
    'Section B: slider B - exhibit D': 'Slider B - obiekt 4',
    'Section B: slider B - exhibit D mask': 'Slider B - maska obiektu 4',
    'Section B: slider B - exhibit E': 'Slider B - obiekt 5',
    'Section B: slider B - exhibit E mask': 'Slider B - maska obiektu 5',
    'Section B: slider B - exhibit F': 'Slider B - obiekt 6',
    'Section B: slider B - exhibit F mask': 'Slider B - maska obiektu 6',
    'Section B: slider B - exhibit G': 'Slider B - obiekt 7',
    'Section B: slider B - exhibit G mask': 'Slider B - maska obiektu 7',
    'Section A: slider title': 'Tytuł slidera obiektów A',
    'Section A: recipe': 'Przepis A',
    'Section A: slider - exhibit A': 'Slider A - obiekt 1',
    'Section A: slider - exhibit A mask': 'Slider A - maska obiektu 1',
    'Section A: slider - exhibit B': 'Slider A - obiekt 2',
    'Section A: slider - exhibit B mask': 'Slider A - maska obiektu 2',
    'Section A: slider - exhibit C': 'Slider A - obiekt 3',
    'Section A: slider - exhibit C mask': 'Slider A - maska obiektu 3',
    'Section A: slider - exhibit D': 'Slider A - obiekt 4',
    'Section A: slider - exhibit D mask': 'Slider A - maska obiektu 4',
    'Section B: exhibit': 'Obiekt A',
    'Section B: exhibit size': 'Rozmiar obiektu A',
    'Section B: exhibit position': 'Pozycja tekstu obiektu A',
    'Section B: exhibit mask': 'Maska obiektu A',
    'Section C: exhibit': 'Obiekt A',
    'Section C: exhibit size': 'Rozmiar obiektu A',
    'Section C: exhibit position': 'Pozycja tekstu obiektu A',
    'Section C: exhibit mask': 'Maska obiektu A',
    'Section D: exhibit': 'Obiekt A',
    'Section D: exhibit size': 'Rozmiar obiektu A',
    'Section D: exhibit position': 'Pozycja tekstu obiektu A',
    'Section D: exhibit mask': 'Maska obiektu A',
    'Section A: exhibit E': 'Obiekt E',
    'Section A: exhibit E size': 'Rozmiar obiektu E',
    'Section A: exhibit E position': 'Pozycja tekstu obiektu E',
    'Section A: exhibit E mask': 'Maska obiektu E',
    'Section B: exhibit B size': 'Rozmiar obiektu B',
    'Section B: exhibit B position': 'Pozycja tekstu obiektu B',
    'Section B: exhibit C': 'Obiekt C',
    'Section B: exhibit C size': 'Rozmiar obiektu C',
    'Section B: exhibit C position': 'Pozycja tekstu obiektu C',
    'Section B: exhibit C mask': 'Maska obiektu C',
    'Section B: exhibit D': 'Obiekt D',
    'Section B: exhibit D size': 'Rozmiar obiektu D',
    'Section B: exhibit D position': 'Pozycja tekstu obiektu D',
    'Section B: exhibit D mask': 'Maska obiektu D',
    'Section B: exhibit E': 'Obiekt E',
    'Section B: exhibit E size': 'Rozmiar obiektu E',
    'Section B: exhibit E position': 'Pozycja tekstu obiektu E',
    'Section B: exhibit E mask': 'Maska obiektu E',
    'Section B: exhibit F': 'Obiekt F',
    'Section B: exhibit F size': 'Rozmiar obiektu F',
    'Section B: exhibit F position': 'Pozycja tekstu obiektu F',
    'Section B: exhibit F mask': 'Maska obiektu F',
    'Section B: exhibit G': 'Obiekt G',
    'Section B: exhibit G size': 'Rozmiar obiektu G',
    'Section B: exhibit G position': 'Pozycja tekstu obiektu G',
    'Section B: exhibit G mask': 'Maska obiektu G',
    'Section B: exhibit H': 'Obiekt H',
    'Section B: exhibit H size': 'Rozmiar obiektu H',
    'Section B: exhibit H position': 'Pozycja tekstu obiektu H',
    'Section B: exhibit H mask': 'Maska obiektu H',
    'Section C: option A title': 'Tytuł święta A',
    'Section C: option B title': 'Tytuł święta B',
    'Section C: option C title': 'Tytuł święta C',
    'Section C: option D title': 'Tytuł święta D',
    'Section C: option E title': 'Tytuł święta E',
    'Section C: option F title': 'Tytuł święta F',
    'Section C: option A - exhibit A': 'Święto A - obiekt A',
    'Section C: option A - exhibit A size': 'Święto A - rozmiar obiektu A',
    'Section C: option A - exhibit A position': 'Święto A - pozycja tekstu obiektu A',
    'Section C: option A - exhibit A mask': 'Święto A - maska obiektu A',
    'Section C: option A - exhibit B': 'Święto A - obiekt B',
    'Section C: option A - exhibit B size': 'Święto A - rozmiar obiektu B',
    'Section C: option A - exhibit B position': 'Święto A - pozycja tekstu obiektu B',
    'Section C: option A - exhibit B mask': 'Święto A - maska obiektu B',
    'Section C: option B - exhibit A': 'Święto B - obiekt A',
    'Section C: option B - exhibit A size': 'Święto B - rozmiar obiektu A',
    'Section C: option B - exhibit A position': 'Święto B - pozycja tekstu obiektu A',
    'Section C: option B - exhibit A mask': 'Święto B - maska obiektu A',
    'Section C: option B - exhibit B': 'Święto B - obiekt B',
    'Section C: option B - exhibit B size': 'Święto B - rozmiar obiektu B',
    'Section C: option B - exhibit B position': 'Święto B - pozycja tekstu obiektu B',
    'Section C: option B - exhibit B mask': 'Święto B - maska obiektu B',
    'Section C: option C - exhibit A': 'Święto C - obiekt A',
    'Section C: option C - exhibit A size': 'Święto C - rozmiar obiektu A',
    'Section C: option C - exhibit A position': 'Święto C - pozycja tekstu obiektu A',
    'Section C: option C - exhibit A mask': 'Święto C - maska obiektu A',
    'Section C: option C - exhibit B': 'Święto C - obiekt B',
    'Section C: option C - exhibit B size': 'Święto C - rozmiar obiektu B',
    'Section C: option C - exhibit B position': 'Święto C - pozycja tekstu obiektu B',
    'Section C: option C - exhibit B mask': 'Święto C - maska obiektu B',
    'Section C: option D - exhibit A': 'Święto D - obiekt A',
    'Section C: option D - exhibit A size': 'Święto D - rozmiar obiektu A',
    'Section C: option D - exhibit A position': 'Święto D - pozycja tekstu obiektu A',
    'Section C: option D - exhibit A mask': 'Święto D - maska obiektu A',
    'Section C: option D - exhibit B': 'Święto D - obiekt B',
    'Section C: option D - exhibit B size': 'Święto D - rozmiar obiektu B',
    'Section C: option D - exhibit B position': 'Święto D - pozycja tekstu obiektu B',
    'Section C: option D - exhibit B mask': 'Święto D - maska obiektu B',
    'Section C: option E - exhibit A': 'Święto E - obiekt A',
    'Section C: option E - exhibit A size': 'Święto E - rozmiar obiektu A',
    'Section C: option E - exhibit A position': 'Święto E - pozycja tekstu obiektu A',
    'Section C: option E - exhibit A mask': 'Święto E - maska obiektu A',
    'Section C: option E - exhibit B': 'Święto E - obiekt B',
    'Section C: option E - exhibit B size': 'Święto E - rozmiar obiektu B',
    'Section C: option E - exhibit B position': 'Święto E - pozycja tekstu obiektu B',
    'Section C: option E - exhibit B mask': 'Święto E - maska obiektu B',
    'Section C: option F - exhibit A': 'Święto F - obiekt A',
    'Section C: option F - exhibit A size': 'Święto F - rozmiar obiektu A',
    'Section C: option F - exhibit A position': 'Święto F - pozycja tekstu obiektu A',
    'Section C: option F - exhibit A mask': 'Święto F - maska obiektu A',
    'Section C: option F - exhibit B': 'Święto F - obiekt B',
    'Section C: option F - exhibit B size': 'Święto F - rozmiar obiektu B',
    'Section C: option F - exhibit B position': 'Święto F - pozycja tekstu obiektu B',
    'Section C: option F - exhibit B mask': 'Święto F - maska obiektu B',
    'Holiday A: title': 'Tytuł',
    'Holiday A: text': 'Opis',
    'Holiday A: recipe title': 'Tytuł przepisu',
    'Holiday A: recipe text': 'Opis przepisu',
    'Holiday A: recipe holiday type': 'Filtr święta przepisu',
    'Holiday A: recipe': 'Przepis A',
    'Holiday A: slider A - exhibit A': 'Slider A - obiekt 1',
    'Holiday A: slider A - exhibit A mask': 'Slider A - maska obiektu 1',
    'Holiday A: slider A - exhibit B': 'Slider A - obiekt 2',
    'Holiday A: slider A - exhibit B mask': 'Slider A - maska obiektu 2',
    'Holiday A: slider A - exhibit C': 'Slider A - obiekt 3',
    'Holiday A: slider A - exhibit C mask': 'Slider A - maska obiektu 3',
    'Holiday A: slider A - exhibit D': 'Slider A - obiekt 4',
    'Holiday A: slider A - exhibit D mask': 'Slider A - maska obiektu 4',
    'Holiday A: slider A - exhibit E': 'Slider A - obiekt 5',
    'Holiday A: slider A - exhibit E mask': 'Slider A - maska obiektu 5',
    'Holiday A: slider A - exhibit F': 'Slider A - obiekt 6',
    'Holiday A: slider A - exhibit F mask': 'Slider A - maska obiektu 6',
    'Holiday A: slider A - exhibit G': 'Slider A - obiekt 7',
    'Holiday A: slider A - exhibit G mask': 'Slider A - maska obiektu 7',
    'Holiday A: slider A - exhibit H': 'Slider A - obiekt 8',
    'Holiday A: slider A - exhibit H mask': 'Slider A - maska obiektu 8',
    'Holiday B: title': 'Tytuł',
    'Holiday B: text': 'Opis',
    'Holiday B: recipe title': 'Tytuł przepisu',
    'Holiday B: slider title': 'Tytuł slidera obiektów A',
    'Holiday B: recipe text': 'Opis przepisu',
    'Holiday B: recipe holiday type': 'Filtr święta przepisu',
    'Holiday B: recipe': 'Przepis A',
    'Holiday B: slider A - exhibit A': 'Slider A - obiekt 1',
    'Holiday B: slider A - exhibit A mask': 'Slider A - maska obiektu 1',
    'Holiday B: slider A - exhibit B': 'Slider A - obiekt 2',
    'Holiday B: slider A - exhibit B mask': 'Slider A - maska obiektu 2',
    'Holiday B: slider A - exhibit C': 'Slider A - obiekt 3',
    'Holiday B: slider A - exhibit C mask': 'Slider A - maska obiektu 3',
    'Holiday B: slider A - exhibit D': 'Slider A - obiekt 4',
    'Holiday B: slider A - exhibit D mask': 'Slider A - maska obiektu 4',
    'Holiday B: exhibit A': 'Obiekt A',
    'Holiday B: exhibit A size': 'Rozmiar obiektu A',
    'Holiday B: exhibit A position': 'Pozycja tekstu obiektu A',
    'Holiday B: exhibit A mask': 'Maska obiektu A',
    'Holiday B: exhibit B': 'Obiekt B',
    'Holiday B: exhibit B size': 'Rozmiar obiektu B',
    'Holiday B: exhibit B position': 'Pozycja tekstu obiektu B',
    'Holiday B: exhibit B mask': 'Maska obiektu B',
    'Holiday B: exhibit C': 'Obiekt C',
    'Holiday B: exhibit C size': 'Rozmiar obiektu C',
    'Holiday B: exhibit C position': 'Pozycja tekstu obiektu C',
    'Holiday B: exhibit C mask': 'Maska obiektu C',
    'Holiday B: charoset text': 'Talerz sederowy - charoset',
    'Holiday B: maror text': 'Talerz sederowy - maror i chazaret',
    'Holiday B: maca text': 'Talerz sederowy - maca',
    'Holiday B: karpas text': 'Talerz sederowy - karpas',
    'Holiday B: zeroa text': 'Talerz sederowy - zeroa',
    'Holiday B: bejca text': 'Talerz sederowy - bejca',
    'Holiday C: title': 'Tytuł',
    'Holiday C: text': 'Opis',
    'Holiday C: recipe': 'Przepis A',
    'Holiday C: exhibit': 'Obiekt A',
    'Holiday C: exhibit size': 'Rozmiar obiektu A',
    'Holiday C: exhibit position': 'Pozycja tekstu obiektu A',
    'Holiday C: exhibit mask': 'Maska obiektu A',
    'Holiday C: exhibit B': 'Obiekt B',
    'Holiday C: exhibit B size': 'Rozmiar obiektu B',
    'Holiday C: exhibit B position': 'Pozycja tekstu obiektu B',
    'Holiday C: exhibit B mask': 'Maska obiektu B',
    'Holiday D: title': 'Tytuł',
    'Holiday D: text': 'Opis',
    'Holiday D: recipe': 'Przepis A',
    'Holiday D: recipe holiday type': 'Filtr święta przepisu',
    'Holiday E: title': 'Tytuł',
    'Holiday E: text': 'Opis',
    'Holiday E: recipe': 'Przepis A',
    'Holiday E: recipe holiday type': 'Filtr święta przepisu',
    'Holiday E: exhibit': 'Obiekt A',
    'Holiday E: exhibit size': 'Rozmiar obiektu A',
    'Holiday E: exhibit position': 'Pozycja tekstu obiektu A',
    'Holiday E: exhibit mask': 'Maska obiektu A',
    'Holiday F: title': 'Tytuł',
    'Holiday F: text': 'Opis',
    'Holiday F: recipe': 'Przepis A',
    'Holiday F: recipe holiday type': 'Filtr święta przepisu',
    'Holiday F: exhibit': 'Obiekt A',
    'Holiday F: exhibit size': 'Rozmiar obiektu A',
    'Holiday F: exhibit position': 'Pozycja tekstu obiektu A',
    'Holiday F: exhibit mask': 'Maska obiektu A',
    'Holiday G: title': 'Tytuł',
    'Holiday G: text': 'Opis',
    'Holiday G: exhibit': 'Obiekt A',
    'Holiday G: exhibit size': 'Rozmiar obiektu A',
    'Holiday G: exhibit position': 'Pozycja tekstu obiektu A',
    'Holiday G: exhibit mask': 'Maska obiektu A',
    'Holiday H: title': 'Tytuł',
    'Holiday H: text': 'Opis',
    'Holiday H: recipe': 'Przepis A',
    'Holiday H: recipe holiday type': 'Filtr święta przepisu',
    'Holiday I: title': 'Tytuł',
    'Holiday I: text': 'Opis',
    'Holiday I: recipe': 'Przepis A',
    'Holiday I: recipe holiday type': 'Filtr święta przepisu',
    'Holiday J: title': 'Tytuł',
    'Holiday J: text': 'Opis',
    'Section A: video title': 'Tytuł filmu',
    'Section A: video text': 'Opis filmu',
    'Section A: subtext A - title': 'Międzytekst A - tytuł',
    'Section A: subtext A - text': 'Międzytekst A - tekst',
    'Section A: quote A - author': 'Autor cytatu 1',
    'Section A: quote B - author': 'Autor cytatu 2',
    'Section A: photo title': 'Autor zdjęcia',
    'Section A: youtube': 'Kod youtube filmu',
    'Section B: sonia photo title': 'Podpis zdjęcia książki Soni',
    'Section B: sonia photo text': 'Opis zdjęcia książki Soni',
    'Section B: photo title': 'Autor zdjęcia',
    'Section B: photo subtext': 'Tekst środkowy zdjęcia',
    'Section B: photo text': 'Opis zdjęcia',
    'Section B: video A title': 'Tytuł filmu A',
    'Section B: video A text': 'Opis filmu A',
    'Section B: video B title': 'Tytuł filmu B',
    'Section B: video B text': 'Opis filmu B',
    'Section B: video C title': 'Tytuł filmu C',
    'Section B: video C text': 'Opis filmu C',
    'Section B: video D title': 'Tytuł filmu D',
    'Section B: video D text': 'Opis filmu D',
    'Section B: subtext C title': 'Międzytekst C - tytuł',
    'Section B: subtext C text': 'Międzytekst C - tekst',
    'Section B: subtext D title': 'Międzytekst D - tytuł',
    'Section B: subtext D text': 'Międzytekst D - tekst',
    'Section B: slider title': 'Tytuł slidera obiektów A',
    'Section B: recipe A': 'Przepis A',
    'Section B: recipe B': 'Przepis B',
    'Section B: slider - exhibit A': 'Slider A - obiekt 1',
    'Section B: slider - exhibit A mask': 'Slider A - maska obiektu 1',
    'Section B: slider - exhibit B': 'Slider A - obiekt 2',
    'Section B: slider - exhibit B mask': 'Slider A - maska obiektu 2',
    'Section B: slider - exhibit C': 'Slider A - obiekt 3',
    'Section B: slider - exhibit C mask': 'Slider A - maska obiektu 3',
    'Section B: slider - exhibit D': 'Slider A - obiekt 4',
    'Section B: slider - exhibit D mask': 'Slider A - maska obiektu 4',
    'Section B: youtube A': 'Kod filmu youtube A',
    'Section B: youtube B': 'Kod filmu youtube B',
    'Section B: youtube C': 'Kod filmu youtube C',
    'Section B: youtube D': 'Kod filmu youtube D',
    'Section A: subtext B - title': 'Międzytekst B - tytuł',
    'Section A: subtext B - text': 'Międzytekst B - tekst',
    'Section A: subtext C - title': 'Międzytekst C - tytuł',
    'Section A: subtext C - text': 'Międzytekst C - tekst',
    'Section A: left text': 'Lewa kolumna tekstu',
    'Section A: right text': 'Prawa kolumna tekstu',
    'Section D: exhibit A': 'Obiekt A',
    'Section D: exhibit A mask': 'Maska obiektu A',
    'Section D: exhibit A position': 'Pozycja tekstu obiektu A',
    'Section D: exhibit A size': 'Rozmiar obiektu A',
    'Section D: exhibit B': 'Obiekt B',
    'Section D: exhibit B mask': 'Maska obiektu B',
    'Section D: exhibit B position': 'Pozycja tekstu obiektu B',
    'Section D: exhibit B size': 'Rozmiar obiektu B',
    'Section D: exhibit C': 'Obiekt C',
    'Section D: exhibit C mask': 'Maska obiektu C',
    'Section D: exhibit C position': 'Pozycja tekstu obiektu C',
    'Section D: exhibit C size': 'Rozmiar obiektu C',
    'Section D: exhibit D': 'Obiekt D',
    'Section D: exhibit D mask': 'Maska obiektu D',
    'Section D: exhibit D position': 'Pozycja tekstu obiektu D',
    'Section D: exhibit D size': 'Rozmiar obiektu D',
    'Section D: exhibit E': 'Obiekt E',
    'Section D: exhibit E mask': 'Maska obiektu E',
    'Section D: exhibit E position': 'Pozycja tekstu obiektu E',
    'Section D: exhibit E size': 'Rozmiar obiektu E',
    'Section E: subtext A - title': 'Międzytekst A - tytuł',
    'Section E: subtext A - text': 'Międzytekst A - tekst',
    'Section E: slider title': 'Tytuł slidera obiektów A',
    'Section E: exhibit A position': 'Pozycja tekstu obiektu A',
    'Section E: exhibit A size': 'Rozmiar obiektu A',
    'Section E: exhibit B position': 'Pozycja tekstu obiektu B',
    'Section E: exhibit B size': 'Rozmiar obiektu B',
    'Section E: exhibit C position': 'Pozycja tekstu obiektu C',
    'Section E: exhibit C size': 'Rozmiar obiektu C',
    'Section E: slider A - exhibit A': 'Slider A - obiekt 1',
    'Section E: slider A - exhibit A mask': 'Slider A - maska obiektu 1',
    'Section E: slider A - exhibit B': 'Slider A - obiekt 2',
    'Section E: slider A - exhibit B mask': 'Slider A - maska obiektu 2',
    'Section E: slider A - exhibit C': 'Slider A - obiekt 3',
    'Section E: slider A - exhibit C mask': 'Slider A - maska obiektu 3',
    'Section E: slider A - exhibit D': 'Slider A - obiekt 4',
    'Section E: slider A - exhibit D mask': 'Slider A - maska obiektu 4',
    'Section E: youtube': 'Kod filmu youtube',
    'Section F: exhibit A': 'Obiekt A',
    'Section F: exhibit A mask': 'Maska obiektu A',
    'Section F: exhibit A position': 'Pozycja tekstu obiektu A',
    'Section F: exhibit A size': 'Rozmiar obiektu A',
    'Section F: exhibit B': 'Obiekt B',
    'Section F: exhibit B mask': 'Maska obiektu B',
    'Section F: exhibit B position': 'Pozycja tekstu obiektu B',
    'Section F: exhibit B size': 'Rozmiar obiektu B',
    'Section F: exhibit C': 'Obiekt C',
    'Section F: exhibit C mask': 'Maska obiektu C',
    'Section F: exhibit C position': 'Pozycja tekstu obiektu C',
    'Section F: exhibit C size': 'Rozmiar obiektu C',
    'Section A: meal A - title': 'Potrawa A - tytuł',
    'Section A: meal A - text': 'Potrawa A - tekst',
    'Section A: meal A - subtitle': 'Potrawa A - podpis',
    'Section A: recipe A': 'Przepis A',
    'Section C: meal A - title': 'Potrawa A - tytuł',
    'Section C: meal A - text': 'Potrawa A - tekst',
    'Section C: meal A - subtitle': 'Potrawa A - podpis',
    'Section C: recipe A': 'Przepis A',
    'Section C: slider A - exhibit A': 'Slider A - obiekt 1',
    'Section C: slider A - exhibit A mask': 'Slider A - maska obiektu 1',
    'Section C: slider A - exhibit B': 'Slider A - obiekt 2',
    'Section C: slider A - exhibit B mask': 'Slider A - maska obiektu 2',
    'Section C: slider A - exhibit C': 'Slider A - obiekt 3',
    'Section C: slider A - exhibit C mask': 'Slider A - maska obiektu 3',
    'Section C: slider A - exhibit D': 'Slider A - obiekt 4',
    'Section C: slider A - exhibit D mask': 'Slider A - maska obiektu 4',
    'Section D: meal A - title': 'Potrawa A - tytuł',
    'Section D: meal A - text': 'Potrawa A - tekst',
    'Section D: meal A - subtitle': 'Potrawa A - podpis',
    'Section D: recipe A': 'Przepis A',
    'Section D: youtube': 'Kod filmu youtube',
    'Section E: meal A - title': 'Potrawa A - tytuł',
    'Section E: meal A - text': 'Potrawa A - tekst',
    'Section E: meal A - subtitle': 'Potrawa A - podpis',
    'Section E: recipe A': 'Przepis A',
    'Section F: meal A - title': 'Potrawa A - tytuł',
    'Section F: meal A - text': 'Potrawa A - tekst',
    'Section F: meal A - subtitle': 'Potrawa A - podpis',
    'Section G: meal A - title': 'Potrawa A - tytuł',
    'Section G: meal A - text': 'Potrawa A - tekst',
    'Section G: meal A - subtitle': 'Potrawa A - podpis',
    'Section G: recipe A': 'Przepis A',
    'Section H: meal A - title': 'Potrawa A - tytuł',
    'Section H: meal A - text': 'Potrawa A - tekst',
    'Section H: meal A - subtitle': 'Potrawa A - podpis',
    'Section H: recipe A': 'Przepis A',
    'Section I: meal A - title': 'Potrawa A - tytuł',
    'Section I: meal A - text': 'Potrawa A - tekst',
    'Section I: meal A - subtitle': 'Potrawa A - podpis',
    'Section I: recipe A': 'Przepis A',
    'Section J: meal A - title': 'Potrawa A - tytuł',
    'Section J: meal A - text': 'Potrawa A - tekst',
    'Section J: meal A - subtitle': 'Potrawa A - podpis',
    'Section J: recipe A': 'Przepis A',
    'Section K: title': 'Tytuł',
    'Section K: text': 'Tekst',
    'Section K: subtext A - title': 'Międzytekst A - tytuł',
    'Section K: subtext A - text': 'Międzytekst A - tekst',
    'Section K: recipe A': 'Przepis A',
    'Section A: meal B - title': 'Potrawa B - tytuł',
    'Section A: meal B - text': 'Potrawa B - tekst',
    'Section A: meal B - subtitle': 'Potrawa B - podpis',
    'Section A: meal C - title': 'Potrawa C - tytuł',
    'Section A: meal C - text': 'Potrawa C - tekst',
    'Section A: meal C - subtitle': 'Potrawa C - podpis',
    'Section B: meal A - title': 'Potrawa A - tytuł',
    'Section B: meal A - text': 'Potrawa A - tekst',
    'Section B: meal A - subtitle': 'Potrawa A - podpis',
    'Section B: meal B - title': 'Potrawa B - tytuł',
    'Section B: meal B - text': 'Potrawa B - tekst',
    'Section B: meal B - subtitle': 'Potrawa B - podpis',
    'Section B: subtext A - title': 'Międzytekst A - tytuł',
    'Section B: subtext A - text': 'Międzytekst A - tekst',
    'Section C: meal B - title': 'Potrawa B - tytuł',
    'Section C: meal B - text': 'Potrawa B - tekst',
    'Section C: meal B - subtitle': 'Potrawa B - podpis',
    'Section C: recipe B': 'Przepis B',
    'Section D: meal B - title': 'Potrawa B - tytuł',
    'Section D: meal B - text': 'Potrawa B - tekst',
    'Section D: meal B - subtitle': 'Potrawa B - podpis',
    'Section D: recipe B': 'Przepis B',
    'Section E: video title': 'Tytuł filmu',
    'Section E: video text': 'Opis filmu',
    'Section C: slider title': 'Tytuł slidera obiektów A',
    'Section C: recipe': 'Przepis A',
    'Section D: exhibit F': 'Obiekt F',
    'Section D: exhibit F size': 'Rozmiar obiektu F',
    'Section D: exhibit F position': 'Pozycja tekstu obiektu F',
    'Section D: exhibit F mask': 'Maska obiektu F',
    'Section C: slider A - exhibit E': 'Slider A - obiekt 5',
    'Section C: slider A - exhibit E mask': 'Slider A - maska obiektu 5',
    'Section C: slider A - exhibit F': 'Slider A - obiekt 6',
    'Section C: slider A - exhibit F mask': 'Slider A - maska obiektu 6',
    'Section C: slider A - exhibit G': 'Slider A - obiekt 7',
    'Section C: slider A - exhibit G mask': 'Slider A - maska obiektu 7',
    'Section C: slider B - exhibit A': 'Slider B - obiekt 1',
    'Section C: slider B - exhibit A mask': 'Slider B - maska obiektu 1',
    'Section C: slider B - exhibit B': 'Slider B - obiekt 2',
    'Section C: slider B - exhibit B mask': 'Slider B - maska obiektu 2',
    'Section C: slider B - exhibit C': 'Slider B - obiekt 3',
    'Section C: slider B - exhibit C mask': 'Slider B - maska obiektu 3',
    'Section C: slider B - exhibit D': 'Slider B - obiekt 4',
    'Section C: slider B - exhibit D mask': 'Slider B - maska obiektu 4',
    'Section C: slider C - exhibit A': 'Slider C - obiekt 1',
    'Section C: slider C - exhibit A mask': 'Slider C - maska obiektu 1',
    'Section C: slider C - exhibit B': 'Slider C - obiekt 2',
    'Section C: slider C - exhibit B mask': 'Slider C - maska obiektu 2',
    'Section C: slider C - exhibit C': 'Slider C - obiekt 3',
    'Section C: slider C - exhibit C mask': 'Slider C - maska obiektu 3',
    'Section C: slider C - exhibit D': 'Slider C - obiekt 4',
    'Section C: slider C - exhibit D mask': 'Slider C - maska obiektu 4',
    'Section C: slider C - exhibit E': 'Slider C - obiekt 5',
    'Section C: slider C - exhibit E mask': 'Slider C - maska obiektu 5',
    'Section C: slider C - exhibit F': 'Slider C - obiekt 6',
    'Section C: slider C - exhibit F mask': 'Slider C - maska obiektu 6',
    'Section C: slider C - exhibit G': 'Slider C - obiekt 7',
    'Section C: slider C - exhibit G mask': 'Slider C - maska obiektu 7',
    'Section D: slider A - exhibit A': 'Slider A - obiekt 1',
    'Section D: slider A - exhibit A mask': 'Slider A - maska obiektu 1',
    'Section D: slider A - exhibit B': 'Slider A - obiekt 2',
    'Section D: slider A - exhibit B mask': 'Slider A - maska obiektu 2',
    'Section D: slider A - exhibit C': 'Slider A - obiekt 3',
    'Section D: slider A - exhibit C mask': 'Slider A - maska obiektu 3',
    'Section D: slider A - exhibit D': 'Slider A - obiekt 4',
    'Section D: slider A - exhibit D mask': 'Slider A - maska obiektu 4',
    'Section D: slider A - exhibit E': 'Slider A - obiekt 5',
    'Section D: slider A - exhibit E mask': 'Slider A - maska obiektu 5',
    'Section D: slider A - exhibit F': 'Slider A - obiekt 6',
    'Section D: slider A - exhibit F mask': 'Slider A - maska obiektu 6',
    'Section D: slider A - exhibit G': 'Slider A - obiekt 7',
    'Section D: slider A - exhibit G mask': 'Slider A - maska obiektu 7',
    'Section B: slider text': 'Podpis slidera obiektów A',
    'Section E: quote A': 'Cytat 1',
    'Section E: quote A - author': 'Autor cytatu 1',
    'Section E: quote B': 'Cytat 2',
    'Section E: quote B - author': 'Autor cytatu 2',
    'Section E: slider A - exhibit E': 'Slider A - obiekt 5',
    'Section E: slider A - exhibit E mask': 'Slider A - maska obiektu 5',
    'Section E: slider A - exhibit F': 'Slider A - obiekt 6',
    'Section E: slider A - exhibit F mask': 'Slider A - maska obiektu 6',
    'Section E: slider A - exhibit G': 'Slider A - obiekt 7',
    'Section E: slider A - exhibit G mask': 'Slider A - maska obiektu 7',
    'Section D: recipe': 'Przepis A',
    'Section E: exhibit D': 'Obiekt D',
    'Section E: exhibit D size': 'Rozmiar obiektu D',
    'Section E: exhibit D position': 'Pozycja tekstu obiektu D',
    'Section E: exhibit D mask': 'Maska obiektu D',
    'Section C: slider A title': 'Tytuł slidera obiektów A',
    'Section C: slider A text': 'Podpis slidera obiektów A',
    'Section C: slider B title': 'Tytuł slidera obiektów B',
    'Section C: slider B text': 'Podpis slidera obiektów B',
    'Section D: subtext A - title': 'Międzytekst A - tytuł',
    'Section D: subtext A - text': 'Międzytekst A - tekst',
    'Section C: slider C title': 'Tytuł slidera obiektów C',
    'Section C: slider C text': 'Podpis slidera obiektów C',
    'Section C: subtext A - title': 'Międzytekst A - tytuł',
    'Section C: subtext A - text': 'Międzytekst A - tekst',
    'Section E: slider text': 'Podpis slidera obiektów A',
    'Section C: slider B - exhibit E': 'Slider B - obiekt 5',
    'Section C: slider B - exhibit E mask': 'Slider B - maska obiektu 5',
    'Section C: slider B - exhibit F': 'Slider B - obiekt 6',
    'Section C: slider B - exhibit F mask': 'Slider B - maska obiektu 6',
    'Section C: slider B - exhibit G': 'Slider B - obiekt 7',
    'Section C: slider B - exhibit G mask': 'Slider B - maska obiektu 7',
    Source: 'Źródło',
    'Ingredient list A title': 'Lista składników A - tytuł',
    'Ingredient list A comment': 'Lista składników A - komentarz',
    'Ingredient list B title': 'Lista składników B - tytuł',
    'Ingredient list B comment': 'Lista składników B - komentarz',
    'Ingredient list C title': 'Lista składników C - tytuł',
    'Ingredient list C comment': 'Lista składników C - komentarz',
    List: 'Lista',
    Mask: 'Maska',
    'Section A: is enabled': 'Aktywny',
    'Section B: is enabled': 'Aktywny',
    'Section C: is enabled': 'Aktywny',
    'Section D: is enabled': 'Aktywny',
    'Section E: is enabled': 'Aktywny',
    'Section F: is enabled': 'Aktywny',
    'Section G: is enabled': 'Aktywny',
    'Section H: is enabled': 'Aktywny',
    'Section I: is enabled': 'Aktywny',
    'Section J: is enabled': 'Aktywny',
    'Section K: is enabled': 'Aktywny',
    'Holiday A: is enabled': 'Aktywny',
    'Holiday B: is enabled': 'Aktywny',
    'Holiday C: is enabled': 'Aktywny',
    'Holiday D: is enabled': 'Aktywny',
    'Holiday E: is enabled': 'Aktywny',
    'Holiday F: is enabled': 'Aktywny',
    'Holiday G: is enabled': 'Aktywny',
    'Holiday H: is enabled': 'Aktywny',
    'Holiday I: is enabled': 'Aktywny',
    'Holiday J: is enabled': 'Aktywny',
    'Section D: video title': 'Tytuł filmu',
    'Section D: video text': 'Opis filmu',
    Phone: 'Telefon',
    'Is external': 'Link zewnętrzny',
    /* Returned by API: general */
    'Your current password is incorrect.': 'Podałeś niepoprawne hasło',
    'Bad credentials.': 'Niepoprawne dane',
    Error: 'Błąd',
    TypeError: 'Błąd',
    'An error occurred': 'Błąd',
    'Not Found': 'Rekord nie odnaleziony',
    'Syntax error': 'Błąd składni. Skontaktuj się z administratorem',
    'This value is already used.': 'Ta wartość jest już używana',
    'This value should not be null.': 'Pole wymagane',
    Forbidden: 'Brak dostępu do tego zasobu',
    Unauthorized: 'Dostęp zablokowany',
    'Internal Server Error': 'Wystąpił błąd. Skontaktuj się z administratorem',
    'TypeError: Failed to fetch':
      'Błąd połączenia z serwerem. Skontaktuj się z administratorem',
    'Password is common.': 'Wybrałeś znane hasło',
    'You have already used that password.': 'Hasło zostało już wcześniej użyte',
    'Login blocked for 15 mins.': 'Logowanie zablokowane na 15 minut',
    'Circular parentage detected. Current entity or current entity descendants cannot be set as a parent to current entity.':
      'Niepoprawna zmiana rodzica. Nie można przenieść strony w dół aktualnego drzewa.',
    'Parent page is not subpageable.':
      'Niepoprawna zmiana rodzica. Rodzic nie może posiadać podstron.',
    'Nesting level exceeded.':
      'Niepoprawna zmiana rodzica. Osiągnięto maksymalny poziom zagnieżdżenia stron.',
    'Youtube thumbnail does not exist.': 'Zaślepka nie istnieje',
    'Admin does not exist.': 'Administrator nie istnieje.',
    'Reset password link has been already generated.':
      'Link do zresetowania hasła został już wygenerowany.',
    'Incorrect hash.': 'Niepoprawny link.',
    'Link expired.': 'Wygasły link.',
    'Reset password blocked for 15 mins.':
      'Resetowanie hasła zablokowane na 15 minut',
  },
}
