import { types } from './_path_common_types'

const schema = {
  endpoint: '/api/diaspora_manners',
  resource: {
    definition: 'DiasporaManners-diasporaManners.read',
    sectionA: {
      properties: {
        translations: {
          type: 'translation',
          properties: {
            sectionATitle: {},
            sectionAText: {
              type: 'textarea'
            },
            sectionASubtextATitle: {},
            sectionASubtextAText: {
              type: 'textarea'
            },
            sectionASubtextBTitle: {},
            sectionASubtextBText: {
              type: 'textarea'
            },
            sectionAVideoTitle: {},
            sectionAVideoText: {
              type: 'textarea'
            }
          }
        },
        sectionAExhibitA: types.exhibit,
        sectionAExhibitASize: types.exhibit_size,
        sectionAExhibitAPosition: types.exhibit_position,
        sectionAExhibitAMask: types.exhibit_mask,

        sectionAExhibitB: types.exhibit,
        sectionAExhibitBSize: types.exhibit_size,
        sectionAExhibitBPosition: types.exhibit_position,
        sectionAExhibitBMask: types.exhibit_mask,

        sectionAExhibitC: types.exhibit,
        sectionAExhibitCSize: types.exhibit_size,
        sectionAExhibitCPosition: types.exhibit_position,
        sectionAExhibitCMask: types.exhibit_mask,

        sectionAExhibitD: types.exhibit,
        sectionAExhibitDSize: types.exhibit_size,
        sectionAExhibitDPosition: types.exhibit_position,
        sectionAExhibitDMask: types.exhibit_mask,

        sectionAYoutube: {
          type: 'youtube'
        },
        sectionAStat: {}
      }
    },
  },
}

export default schema
