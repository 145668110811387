import React, { useState } from 'react'
import { Divider, Button } from '@material-ui/core'
import { NotFound } from 'core/pages'
import { Paper } from 'core/components/Paper'
import { Title } from 'core/components/Title'
import { Loader } from 'core/components/Loader'
import { useResourceState } from 'core/_helpers/useResourceState'
import { useResourceFetch } from 'core/_helpers/useResourceFetch'
import { useRecipePhotoCollectionFetch } from './_helpers/useRecipePhotoCollectionFetch'
import { fetchDataHandleAuthError } from 'core/_helpers/fetchDataHandleAuthError'
import { notification } from 'core/_helpers/notification'
import { translate } from 'core/_helpers/translate'
import { EmbeddedCollection } from 'core/components/embedded'
import { makeStyles } from '@material-ui/styles'
import { Form } from 'core/pages'
import schema from '_schema/recipe'
import routes from './routes'

const useStyles = makeStyles({
  subresources: {
    marginLeft: 30,
    marginRight: 30,
    paddingBottom: 120
  },
  title: {
    marginTop: 15,
  },
  divider: {
    marginTop: 10,
  },
  steps_divider: {
    marginTop: 40
  },
  photos: {
    display: 'flex',
    '&> *': {
      marginLeft: 15,
      marginRight: 15
    },
    '&> *:first-child': {
      marginLeft: 0
    },
    '&> *:last-child': {
      marginRight: 0
    }
  },
  photo: {
    width: 400
  },
  choose_btn: {
    marginTop: 10
  }
})

export const Edit = ({ schema: definitions, ...rest }) => {
  const { match } = rest

  const iri = `${schema.endpoint}/${match.params.id}`

  const [state, setState] = useResourceState()

  const { resource, isFetching, fetchError } = state

  useResourceFetch(
    iri,
    resource,
    setState.isFetching,
    setState.resource,
    setState.fetchError,
    false
  )

  const [formKey, setFormKey] = useState(Math.random())

  const handleChoosePhoto = uuid => () => {
    fetchDataHandleAuthError(
      iri,
      'PATCH',
      { body: JSON.stringify({
        clonePhotoUuid: uuid
      }) },
      response => {
        setState.resource({
          ...resource,
          photo: response.photo
        })

        setFormKey(Math.random())

        notification('success', 'T_MODULE_RECIPE_PHOTO_CHOSEN', 'T_FORM_SUCCESS')
      },
      error => {
        if (error.response.title === 'AbortError') {
          return
        }

        notification('error', error.response.detail, error.response.title)
      }
    )
  }

  const [recipePhotos] = useRecipePhotoCollectionFetch(
    schema.subresources.photo.endpoint,
    match.params.id
  )

  const classes = useStyles()

  return isFetching ? (
    <Paper>
      <Loader />
    </Paper>
  ) : fetchError || resource.type === 'polin' ? (
    <NotFound />
  ) : (
    <>
      <Form
        definitionSchema={definitions[schema.user_resource.definition]}
        customResourceSchema={{
          ...schema.user_resource,
          properties: {
            ...schema.user_resource.properties,
            linkedRecipes: {
              ...schema.user_resource.properties.linkedRecipes,
              endpoint: `${schema.user_resource.properties.linkedRecipes.endpoint}&type=user&order[createdAt]=desc&uuid[neq]=${match.params.id}`
            }
          }
        }}
        iri={iri}
        resource={resource}
        method="PUT"
        url={iri}
        collectionPath={routes().index.path}
        storeCollectionId={schema.endpoint}
        fieldsFullWidth={false}
        paddingBottom={0}
        width={700}
        key={formKey}
      />
      <div className={classes.subresources}>
        <Divider className={classes.divider} />
        <Title classes={{ root: classes.title }}>{translate('T_MODULE_RECIPE_SENT_PHOTOS')}</Title>
        <div className={classes.photos}>
          {recipePhotos.map(recipePhoto => {
            return (
              <div key={recipePhoto['@id']}>
                <img
                  className={classes.photo}
                  src={`${process.env.REACT_APP_API_ENTRYPOINT}/${recipePhoto.photo.url}`}
                  alt="recipe"
                />
                <div>
                  {recipePhoto.photo.originalName}
                </div>
                <Button
                  size="small"
                  variant="contained"
                  color="primary"
                  className={classes.choose_btn}
                  onClick={handleChoosePhoto(recipePhoto.photo.uuid)}
                >
                  {translate('T_MODULE_RECIPE_CHOOSE_PHOTO')}
                </Button>
              </div>
            )
          })}
        </div>
        <Divider className={classes.divider} />
        <Title classes={{ root: classes.title }}>{translate('Ingredients')}</Title>
        <EmbeddedCollection
          endpoint={schema.subresources.ingredient.endpoint}
          pid={match.params.id}
          parentIri={iri}
          properties={schema.subresources.ingredient.properties}
          definitionSchema={definitions[schema.subresources.ingredient.definition]}
          panelTitle={translate('Ingredient')}
          alignButtonEvenly={true}
        />
        <Divider className={classes.steps_divider} />
        <Title classes={{ root: classes.title }}>{translate('Steps')}</Title>
        <EmbeddedCollection
          endpoint={schema.subresources.step.endpoint}
          pid={match.params.id}
          parentIri={iri}
          properties={schema.subresources.step.properties}
          definitionSchema={definitions[schema.subresources.step.definition]}
          panelTitle={translate('Step')}
          alignButtonEvenly={true}
        />
      </div>
    </>
  )
}
