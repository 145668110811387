import React from 'react'
import { Divider } from '@material-ui/core'
import { NotFound } from 'core/pages'
import { Paper } from 'core/components/Paper'
import { Title } from 'core/components/Title'
import { Loader } from 'core/components/Loader'
import { useResourceState } from 'core/_helpers/useResourceState'
import { useResourceFetch } from 'core/_helpers/useResourceFetch'
import { translate } from 'core/_helpers/translate'
import { EmbeddedCollection } from 'core/components/embedded'
import { makeStyles } from '@material-ui/styles'
import { Form } from 'core/pages'
import schema from '_schema/recipe'
import routes from './routes'

const useStyles = makeStyles({
  subresources: {
    marginLeft: 30,
    marginRight: 30,
    paddingBottom: 120
  },
  title: {
    marginTop: 15,
  },
  divider: {
    marginTop: 10,
  },
  steps_divider: {
    marginTop: 40
  }
})

export const Edit = ({ schema: definitions, ...rest }) => {
  const { match } = rest

  const iri = `${schema.endpoint}/${match.params.id}`

  const [state, setState] = useResourceState()

  const { resource, isFetching, fetchError } = state

  useResourceFetch(
    iri,
    resource,
    setState.isFetching,
    setState.resource,
    setState.fetchError,
    false
  )

  const classes = useStyles()

  return isFetching ? (
    <Paper>
      <Loader />
    </Paper>
  ) : fetchError || resource.type === 'user' ? (
    <NotFound />
  ) : (
    <>
      <Form
        definitionSchema={definitions[schema.polin_resource.definition]}
        customResourceSchema={{
          ...schema.polin_resource,
          properties: {
            ...schema.polin_resource.properties,
            linkedRecipes: {
              ...schema.polin_resource.properties.linkedRecipes,
              endpoint: `${schema.polin_resource.properties.linkedRecipes.endpoint}&type=polin&order[ord]=desc&uuid[neq]=${match.params.id}`
            }
          }
        }}
        iri={iri}
        resource={resource}
        method="PUT"
        url={iri}
        collectionPath={routes().index.path}
        storeCollectionId={schema.endpoint}
        fieldsFullWidth={false}
        paddingBottom={0}
        width={700}
      />
      <div className={classes.subresources}>
        <Divider className={classes.divider} />
        <Title classes={{ root: classes.title }}>{translate('Ingredients')}</Title>
        <EmbeddedCollection
          endpoint={schema.subresources.ingredient.endpoint}
          pid={match.params.id}
          parentIri={iri}
          properties={schema.subresources.ingredient.properties}
          definitionSchema={definitions[schema.subresources.ingredient.definition]}
          panelTitle={translate('Ingredient')}
          alignButtonEvenly={true}
        />
        <Divider className={classes.steps_divider} />
        <Title classes={{ root: classes.title }}>{translate('Steps')}</Title>
        <EmbeddedCollection
          endpoint={schema.subresources.step.endpoint}
          pid={match.params.id}
          parentIri={iri}
          properties={schema.subresources.step.properties}
          definitionSchema={definitions[schema.subresources.step.definition]}
          panelTitle={translate('Step')}
          alignButtonEvenly={true}
        />
      </div>
    </>
  )
}
