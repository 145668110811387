import React, { useMemo } from 'react'
import { Paper } from 'core/components/Paper'
import { NotFound } from 'core/pages'
import { Loader } from 'core/components/Loader'
import { SchemableCollection } from 'core/components/SchemableCollection'
import { Title } from 'core/components/Title'
import { CollectionTable } from 'core/components/table'
import { useResourceState } from 'core/_helpers/useResourceState'
import { useResourceFetch } from 'core/_helpers/useResourceFetch'
import { operations } from 'core/components/table/_columns/operations'
import { translate } from 'core/_helpers/translate'
import schema from '_schema/recipe'

export const Collection = ({ oppositeType = 'polin', ...rest }) => {
  const { match } = rest

  const iri = useMemo(() => `${schema.endpoint}/${match.params.id}`, [
    match.params.id,
  ])

  const [state, setState] = useResourceState()

  const { resource, isFetching, fetchError } = state

  useResourceFetch(
    iri,
    resource,
    setState.isFetching,
    setState.resource,
    setState.fetchError,
    false
  )

  const columns = useMemo(
    () => [
      {
        header: translate('T_MODULE_RECIPE_RATINGS_AUTHOR'),
        accessor: 'title',
        width: '50%',
      },
      {
        accessor: 'rating',
        width: '30%',
      },
      {
        ...operations(
          'title',
          false,
          true,
          false,
          true
        ),
        width: '15%',
      },
    ],
    []
  )

  const defaultFilters = {
    [`parent.${process.env.REACT_APP_RESOURCE_ID}`]: match.params.id,
  }

  return isFetching ? (
    <Paper>
      <Loader />
    </Paper>
  ) : fetchError || resource.type === oppositeType ? (
    <NotFound />
  ) : (
    <Paper>
      <Title>{`${translate('T_MODULE_RECIPE_RATINGS')} "${
        resource.translations[process.env.REACT_APP_LOCALE].title
      }"`}</Title>
      <SchemableCollection
        component={CollectionTable}
        path={`paths.${schema.subresources.rating.endpoint}.get`}
        endpoint={schema.subresources.rating.endpoint}
        columns={columns}
        customResourceSchema={schema.subresources.rating.resource}
        storeCollectionId={`/recipe_ratings/${resource.uuid}`}
        defaultFilters={defaultFilters}
        defaultSorters={{ createdAt: 'desc' }}
        autoWidth={false}
      />
    </Paper>
  )
}
