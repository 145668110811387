import { types } from './_path_common_types'

const schema = {
  endpoint: '/api/modernity_situations',
  resource: {
    definition: 'ModernitySituations-modernitySituations.read',
    sectionA: {
      properties: {
        translations: {
          type: 'translation',
          properties: {
            sectionATitle: {},
            sectionAText: {
              type: 'textarea'
            },
            sectionAVideoTitle: {},
            sectionAVideoText: {
              type: 'textarea'
            },
          }
        },
        sectionAExhibitA: types.exhibit,
        sectionAExhibitASize: types.exhibit_size,
        sectionAExhibitAPosition: types.exhibit_position,
        sectionAExhibitAMask: types.exhibit_mask,

        sectionAYoutube: {
          type: 'youtube'
        },
        sectionAStat: {}
      }
    },
    sectionB: {
      properties: {
        translations: {
          type: 'translation',
          properties: {
            sectionBTitle: {},
            sectionBText: {
              type: 'textarea'
            },
          }
        },
        sectionBSliderAExhibitA: types.exhibit,
        sectionBSliderAExhibitAMask: types.exhibit_mask,
        sectionBSliderAExhibitB: types.exhibit,
        sectionBSliderAExhibitBMask: types.exhibit_mask,
        sectionBSliderAExhibitC: types.exhibit,
        sectionBSliderAExhibitCMask: types.exhibit_mask,
        sectionBSliderAExhibitD: types.exhibit,
        sectionBSliderAExhibitDMask: types.exhibit_mask,
        sectionBSliderAExhibitE: types.exhibit,
        sectionBSliderAExhibitEMask: types.exhibit_mask,
        sectionBSliderAExhibitF: types.exhibit,
        sectionBSliderAExhibitFMask: types.exhibit_mask,
        sectionBSliderAExhibitG: types.exhibit,
        sectionBSliderAExhibitGMask: types.exhibit_mask,

        sectionBExhibitA: types.exhibit,
        sectionBExhibitASize: types.exhibit_size,
        sectionBExhibitAPosition: types.exhibit_position,
        sectionBExhibitAMask: types.exhibit_mask,

        sectionBStat: {}
      }
    },
    sectionC: {
      properties: {
        translations: {
          type: 'translation',
          properties: {
            sectionCTitle: {},
            sectionCText: {
              type: 'textarea'
            },
          }
        },
        sectionCSliderAExhibitA: types.exhibit,
        sectionCSliderAExhibitAMask: types.exhibit_mask,
        sectionCSliderAExhibitB: types.exhibit,
        sectionCSliderAExhibitBMask: types.exhibit_mask,
        sectionCSliderAExhibitC: types.exhibit,
        sectionCSliderAExhibitCMask: types.exhibit_mask,
        sectionCSliderAExhibitD: types.exhibit,
        sectionCSliderAExhibitDMask: types.exhibit_mask,
        sectionCSliderAExhibitE: types.exhibit,
        sectionCSliderAExhibitEMask: types.exhibit_mask,
        sectionCSliderAExhibitF: types.exhibit,
        sectionCSliderAExhibitFMask: types.exhibit_mask,
        sectionCSliderAExhibitG: types.exhibit,
        sectionCSliderAExhibitGMask: types.exhibit_mask,

        sectionCExhibitA: types.exhibit,
        sectionCExhibitASize: types.exhibit_size,
        sectionCExhibitAPosition: types.exhibit_position,
        sectionCExhibitAMask: types.exhibit_mask,

        sectionCStat: {}
      }
    },
    sectionD: {
      properties: {
        translations: {
          type: 'translation',
          properties: {
            sectionDTitle: {},
            sectionDText: {
              type: 'textarea'
            },
          }
        },
        sectionDSliderAExhibitA: types.exhibit,
        sectionDSliderAExhibitAMask: types.exhibit_mask,
        sectionDSliderAExhibitB: types.exhibit,
        sectionDSliderAExhibitBMask: types.exhibit_mask,
        sectionDSliderAExhibitC: types.exhibit,
        sectionDSliderAExhibitCMask: types.exhibit_mask,
        sectionDSliderAExhibitD: types.exhibit,
        sectionDSliderAExhibitDMask: types.exhibit_mask,
        sectionDSliderAExhibitE: types.exhibit,
        sectionDSliderAExhibitEMask: types.exhibit_mask,
        sectionDSliderAExhibitF: types.exhibit,
        sectionDSliderAExhibitFMask: types.exhibit_mask,
        sectionDSliderAExhibitG: types.exhibit,
        sectionDSliderAExhibitGMask: types.exhibit_mask,

        sectionDExhibitA: types.exhibit,
        sectionDExhibitASize: types.exhibit_size,
        sectionDExhibitAPosition: types.exhibit_position,
        sectionDExhibitAMask: types.exhibit_mask,

        sectionDStat: {}
      }
    },
  },
}

export default schema
