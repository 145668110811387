import photoSchema from 'core/_schema/photo'

const schema = {
  endpoint: '/api/workshops',
  resource: {
    definition: 'Workshop-workshop.read',
    properties: {
      translations: {
        type: 'translation',
        properties: {
          title: {},
          text: {
            type: 'textarea'
          },
          buttonTitle: {},
          buttonLink: {},
          photoAlt: {},
        },
      },
      photo: {
        type: 'image',
        endpoint: photoSchema.endpoint.single,
        validate: ['maxSize'],
        thumbs: {
          endpoint: '/api/workshop_thumbs'
        }
      },
      stat: {
        description: 'T_GENERAL_PUBLISH',
      },
    },
  },
}

export default schema
