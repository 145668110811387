import { makeStyles } from '@material-ui/styles'

export const usePathSectionStyles = makeStyles({
  section: {
    width: 700,
    marginBottom: 50,
    paddingBottom: 30,
    borderBottom: '1px solid #ddd',
    '&:last-child': {
      marginBottom: 0,
      paddingBottom: 0,
      borderBottom: 'none'
    }
  },
  section_title: {
    marginBottom: 20
  }
})
