import { types } from './_path_common_types'

const schema = {
  endpoint: '/api/modernity_migrations',
  resource: {
    definition: 'ModernityMigrations-modernityMigrations.read',
    sectionA: {
      properties: {
        translations: {
          type: 'translation',
          properties: {
            sectionATitle: {},
            sectionAText: {
              type: 'textarea'
            }
          }
        },
        sectionAExhibitA: types.exhibit,
        sectionAExhibitASize: types.exhibit_size,
        sectionAExhibitAPosition: types.exhibit_position,
        sectionAExhibitAMask: types.exhibit_mask,

        sectionAStat: {}
      }
    },
    sectionB: {
      properties: {
        translations: {
          type: 'translation',
          properties: {
            sectionBTitle: {},
            sectionBText: {
              type: 'textarea'
            },
            sectionBSliderTitle: {
              type: 'textarea'
            },
          },
        },
        sectionBRecipe: types.recipe,

        sectionBExhibitA: types.exhibit,
        sectionBExhibitASize: types.exhibit_size,
        sectionBExhibitAPosition: types.exhibit_position,
        sectionBExhibitAMask: types.exhibit_mask,

        sectionBExhibitB: types.exhibit,
        sectionBExhibitBSize: types.exhibit_size,
        sectionBExhibitBPosition: types.exhibit_position,
        sectionBExhibitBMask: types.exhibit_mask,

        sectionBExhibitC: types.exhibit,
        sectionBExhibitCSize: types.exhibit_size,
        sectionBExhibitCPosition: types.exhibit_position,
        sectionBExhibitCMask: types.exhibit_mask,

        sectionBExhibitD: types.exhibit,
        sectionBExhibitDSize: types.exhibit_size,
        sectionBExhibitDPosition: types.exhibit_position,
        sectionBExhibitDMask: types.exhibit_mask,

        sectionBExhibitE: types.exhibit,
        sectionBExhibitESize: types.exhibit_size,
        sectionBExhibitEPosition: types.exhibit_position,
        sectionBExhibitEMask: types.exhibit_mask,

        sectionBExhibitF: types.exhibit,
        sectionBExhibitFSize: types.exhibit_size,
        sectionBExhibitFPosition: types.exhibit_position,
        sectionBExhibitFMask: types.exhibit_mask,

        sectionBSliderAExhibitA: types.exhibit,
        sectionBSliderAExhibitAMask: types.exhibit_mask,
        sectionBSliderAExhibitB: types.exhibit,
        sectionBSliderAExhibitBMask: types.exhibit_mask,
        sectionBSliderAExhibitC: types.exhibit,
        sectionBSliderAExhibitCMask: types.exhibit_mask,
        sectionBSliderAExhibitD: types.exhibit,
        sectionBSliderAExhibitDMask: types.exhibit_mask,

        sectionBStat: {}
      }
    },
    sectionC: {
      properties: {
        translations: {
          type: 'translation',
          properties: {
            sectionCTitle: {},
            sectionCText: {
              type: 'textarea'
            }
          },
        },
        sectionCRecipe: types.recipe,

        sectionCExhibitA: types.exhibit,
        sectionCExhibitASize: types.exhibit_size,
        sectionCExhibitAPosition: types.exhibit_position,
        sectionCExhibitAMask: types.exhibit_mask,

        sectionCStat: {}
      }
    },
    sectionD: {
      properties: {
        translations: {
          type: 'translation',
          properties: {
            sectionDTitle: {},
            sectionDText: {
              type: 'textarea'
            }
          },
        },
        sectionDRecipe: types.recipe,

        sectionDSliderAExhibitA: types.exhibit,
        sectionDSliderAExhibitAMask: types.exhibit_mask,
        sectionDSliderAExhibitB: types.exhibit,
        sectionDSliderAExhibitBMask: types.exhibit_mask,
        sectionDSliderAExhibitC: types.exhibit,
        sectionDSliderAExhibitCMask: types.exhibit_mask,
        sectionDSliderAExhibitD: types.exhibit,
        sectionDSliderAExhibitDMask: types.exhibit_mask,
        sectionDSliderAExhibitE: types.exhibit,
        sectionDSliderAExhibitEMask: types.exhibit_mask,
        sectionDSliderAExhibitF: types.exhibit,
        sectionDSliderAExhibitFMask: types.exhibit_mask,
        sectionDSliderAExhibitG: types.exhibit,
        sectionDSliderAExhibitGMask: types.exhibit_mask,

        sectionDExhibitA: types.exhibit,
        sectionDExhibitASize: types.exhibit_size,
        sectionDExhibitAPosition: types.exhibit_position,
        sectionDExhibitAMask: types.exhibit_mask,

        sectionDStat: {}
      }
    },
    sectionE: {
      properties: {
        translations: {
          type: 'translation',
          properties: {
            sectionETitle: {},
            sectionEText: {
              type: 'textarea'
            },
            sectionESubtextATitle: {},
            sectionESubtextAText: {
              type: 'textarea'
            },
            sectionESliderTitle: {
              type: 'textarea'
            },
            sectionESliderText: {},
            sectionEQuoteA: {
              type: 'textarea'
            },
            sectionEQuoteAAuthor: {},
            sectionEQuoteB: {
              type: 'textarea'
            },
            sectionEQuoteBAuthor: {},
          }
        },
        sectionESliderAExhibitA: types.exhibit,
        sectionESliderAExhibitAMask: types.exhibit_mask,
        sectionESliderAExhibitB: types.exhibit,
        sectionESliderAExhibitBMask: types.exhibit_mask,
        sectionESliderAExhibitC: types.exhibit,
        sectionESliderAExhibitCMask: types.exhibit_mask,
        sectionESliderAExhibitD: types.exhibit,
        sectionESliderAExhibitDMask: types.exhibit_mask,
        sectionESliderAExhibitE: types.exhibit,
        sectionESliderAExhibitEMask: types.exhibit_mask,
        sectionESliderAExhibitF: types.exhibit,
        sectionESliderAExhibitFMask: types.exhibit_mask,
        sectionESliderAExhibitG: types.exhibit,
        sectionESliderAExhibitGMask: types.exhibit_mask,

        sectionEExhibitA: types.exhibit,
        sectionEExhibitASize: types.exhibit_size,
        sectionEExhibitAPosition: types.exhibit_position,
        sectionEExhibitAMask: types.exhibit_mask,

        sectionEExhibitB: types.exhibit,
        sectionEExhibitBSize: types.exhibit_size,
        sectionEExhibitBPosition: types.exhibit_position,
        sectionEExhibitBMask: types.exhibit_mask,

        sectionEExhibitC: types.exhibit,
        sectionEExhibitCSize: types.exhibit_size,
        sectionEExhibitCPosition: types.exhibit_position,
        sectionEExhibitCMask: types.exhibit_mask,

        sectionEExhibitD: types.exhibit,
        sectionEExhibitDSize: types.exhibit_size,
        sectionEExhibitDPosition: types.exhibit_position,
        sectionEExhibitDMask: types.exhibit_mask,

        sectionEStat: {}
      }
    },
  },
}

export default schema
