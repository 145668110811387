import { types } from './_path_common_types'

const schema = {
  endpoint: '/api/modernity_wars',
  resource: {
    definition: 'ModernityWars-modernityWars.read',
    sectionA: {
      properties: {
        translations: {
          type: 'translation',
          properties: {
            sectionATitle: {},
            sectionAText: {
              type: 'textarea'
            },
            sectionASubtextATitle: {},
            sectionASubtextAText: {
              type: 'textarea'
            },
          }
        },
        sectionAExhibitA: types.exhibit,
        sectionAExhibitASize: types.exhibit_size,
        sectionAExhibitAPosition: types.exhibit_position,
        sectionAExhibitAMask: types.exhibit_mask,

        sectionAExhibitB: types.exhibit,
        sectionAExhibitBSize: types.exhibit_size,
        sectionAExhibitBPosition: types.exhibit_position,
        sectionAExhibitBMask: types.exhibit_mask,

        sectionAStat: {}
      }
    },
    sectionB: {
      properties: {
        translations: {
          type: 'translation',
          properties: {
            sectionBTitle: {},
            sectionBText: {
              type: 'textarea'
            },
          }
        },
        sectionBExhibitA: types.exhibit,
        sectionBExhibitASize: types.exhibit_size,
        sectionBExhibitAPosition: types.exhibit_position,
        sectionBExhibitAMask: types.exhibit_mask,

        sectionBExhibitB: types.exhibit,
        sectionBExhibitBSize: types.exhibit_size,
        sectionBExhibitBPosition: types.exhibit_position,
        sectionBExhibitBMask: types.exhibit_mask,

        sectionBExhibitC: types.exhibit,
        sectionBExhibitCSize: types.exhibit_size,
        sectionBExhibitCPosition: types.exhibit_position,
        sectionBExhibitCMask: types.exhibit_mask,

        sectionBExhibitD: types.exhibit,
        sectionBExhibitDSize: types.exhibit_size,
        sectionBExhibitDPosition: types.exhibit_position,
        sectionBExhibitDMask: types.exhibit_mask,

        sectionBExhibitE: types.exhibit,
        sectionBExhibitESize: types.exhibit_size,
        sectionBExhibitEPosition: types.exhibit_position,
        sectionBExhibitEMask: types.exhibit_mask,

        sectionBStat: {}
      }
    },
  },
}

export default schema
