const schema = {
  endpoint: '/api/footer_links',
  resource: {
    definition: 'FooterLink-footerLink.read',
    properties: {
      translations: {
        type: 'translation',
        properties: {
          title: {},
          link: {},
        },
      },
      col: {
        type: 'choice',
        choices: {
          left: 'T_MODULE_FOOTER_LINK_COL_LEFT',
          right: 'T_MODULE_FOOTER_LINK_COL_RIGHT',
          bottom: 'T_MODULE_FOOTER_LINK_COL_BOTTOM',
        },
        validate: ['required'],
      },
      stat: {
        description: 'T_GENERAL_PUBLISH',
      },
    },
  },
}

export default schema
