import { ResourceAccessType } from 'core/pages/AdminGroups/form/ResourceAccessType'

const schema = {
  endpoint: '/api/admin_groups',
  resource: {
    definition: 'AdminGroup-adminGroup.write',
    properties: {
      title: {
        validate: ['required'],
      },
      isSuperAdmin: {},
      resourceAccess: {
        description: 'T_FORM_FIELD_RESOURCE_ACCESS',
        type: ResourceAccessType,
      },
    },
  },
}

export default schema
