import { types } from './_path_common_types'

const schema = {
  endpoint: '/api/modernity_books',
  resource: {
    definition: 'ModernityBooks-modernityBooks.read',
    sectionA: {
      properties: {
        translations: {
          type: 'translation',
          properties: {
            sectionATitle: {},
            sectionAText: {
              type: 'textarea'
            },
            sectionAVideoTitle: {},
            sectionAVideoText: {
              type: 'textarea'
            },
            sectionASubtextATitle: {},
            sectionASubtextAText: {
              type: 'textarea'
            },
          }
        },
        sectionARecipe: types.recipe,

        sectionAExhibitA: types.exhibit,
        sectionAExhibitASize: types.exhibit_size,
        sectionAExhibitAPosition: types.exhibit_position,
        sectionAExhibitAMask: types.exhibit_mask,

        sectionAExhibitB: types.exhibit,
        sectionAExhibitBSize: types.exhibit_size,
        sectionAExhibitBPosition: types.exhibit_position,
        sectionAExhibitBMask: types.exhibit_mask,

        sectionAExhibitC: types.exhibit,
        sectionAExhibitCSize: types.exhibit_size,
        sectionAExhibitCPosition: types.exhibit_position,
        sectionAExhibitCMask: types.exhibit_mask,

        sectionAYoutube: {
          type: 'youtube'
        },
        sectionAStat: {}
      }
    },
    sectionB: {
      properties: {
        translations: {
          type: 'translation',
          properties: {
            sectionBTitle: {},
            sectionBText: {
              type: 'textarea'
            },
            sectionBSubtextATitle: {},
            sectionBSubtextAText: {
              type: 'textarea'
            },
            sectionBPhotoText: {
              type: 'textarea'
            },
            sectionBPhotoAuthor: {},
          }
        },
        sectionBExhibitA: types.exhibit,
        sectionBExhibitASize: types.exhibit_size,
        sectionBExhibitAPosition: types.exhibit_position,
        sectionBExhibitAMask: types.exhibit_mask,

        sectionBExhibitB: types.exhibit,
        sectionBExhibitBSize: types.exhibit_size,
        sectionBExhibitBPosition: types.exhibit_position,
        sectionBExhibitBMask: types.exhibit_mask,

        sectionBExhibitC: types.exhibit,
        sectionBExhibitCSize: types.exhibit_size,
        sectionBExhibitCPosition: types.exhibit_position,
        sectionBExhibitCMask: types.exhibit_mask,

        sectionBExhibitD: types.exhibit,
        sectionBExhibitDSize: types.exhibit_size,
        sectionBExhibitDPosition: types.exhibit_position,
        sectionBExhibitDMask: types.exhibit_mask,

        sectionBStat: {}
      }
    },
    sectionC: {
      properties: {
        translations: {
          type: 'translation',
          properties: {
            sectionCTitle: {},
            sectionCText: {
              type: 'textarea'
            },
            sectionCVideoTitle: {},
            sectionCVideoText: {
              type: 'textarea'
            },
          }
        },
        sectionCRecipe: types.recipe,

        sectionCExhibitA: types.exhibit,
        sectionCExhibitASize: types.exhibit_size,
        sectionCExhibitAPosition: types.exhibit_position,
        sectionCExhibitAMask: types.exhibit_mask,

        sectionCExhibitB: types.exhibit,
        sectionCExhibitBSize: types.exhibit_size,
        sectionCExhibitBPosition: types.exhibit_position,
        sectionCExhibitBMask: types.exhibit_mask,

        sectionCExhibitC: types.exhibit,
        sectionCExhibitCSize: types.exhibit_size,
        sectionCExhibitCPosition: types.exhibit_position,
        sectionCExhibitCMask: types.exhibit_mask,

        sectionCExhibitD: types.exhibit,
        sectionCExhibitDSize: types.exhibit_size,
        sectionCExhibitDPosition: types.exhibit_position,
        sectionCExhibitDMask: types.exhibit_mask,

        sectionCYoutube: {
          type: 'youtube'
        },
        sectionCStat: {}
      }
    },
    sectionD: {
      properties: {
        translations: {
          type: 'translation',
          properties: {
            sectionDTitle: {},
            sectionDText: {
              type: 'textarea'
            },
            sectionDSubtextAText: {
              type: 'textarea',
              description: 'Cytat'
            },
            sectionDSubtextATitle: {
              description: 'Autor cytatu'
            },
          }
        },
        sectionDExhibitA: types.exhibit,
        sectionDExhibitASize: types.exhibit_size,
        sectionDExhibitAPosition: types.exhibit_position,
        sectionDExhibitAMask: types.exhibit_mask,

        sectionDExhibitB: types.exhibit,
        sectionDExhibitBSize: types.exhibit_size,
        sectionDExhibitBPosition: types.exhibit_position,
        sectionDExhibitBMask: types.exhibit_mask,

        sectionDExhibitC: types.exhibit,
        sectionDExhibitCSize: types.exhibit_size,
        sectionDExhibitCPosition: types.exhibit_position,
        sectionDExhibitCMask: types.exhibit_mask,

        sectionDExhibitD: types.exhibit,
        sectionDExhibitDSize: types.exhibit_size,
        sectionDExhibitDPosition: types.exhibit_position,
        sectionDExhibitDMask: types.exhibit_mask,

        sectionDExhibitE: types.exhibit,
        sectionDExhibitESize: types.exhibit_size,
        sectionDExhibitEPosition: types.exhibit_position,
        sectionDExhibitEMask: types.exhibit_mask,

        sectionDExhibitF: types.exhibit,
        sectionDExhibitFSize: types.exhibit_size,
        sectionDExhibitFPosition: types.exhibit_position,
        sectionDExhibitFMask: types.exhibit_mask,

        sectionDStat: {}
      }
    },
  },
}

export default schema
