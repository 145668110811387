import React, { useMemo } from 'react'
import { Paper } from 'core/components/Paper'
import { CollectionHeader } from 'core/components/CollectionHeader'
import { SchemableCollection } from 'core/components/SchemableCollection'
import { CollectionTable } from 'core/components/table'
import { operations } from 'core/components/table/_columns/operations'
import { translate } from 'core/_helpers/translate'
import schema from 'core/_schema/translatorEntry'
import routes from './routes'

export const Collection = () => {
  const columns = useMemo(
    () => [
      {
        header: translate('T_GENERAL_CODE'),
        accessor: 'code',
        filterable: true,
        sortable: true,
        width: '25%',
      },
      {
        header: `${translate('T_GENERAL_TRANSLATION')} PL`,
        accessor: `translations.${process.env.REACT_APP_LOCALE}.title`,
        filterName: 'translations.title',
        filterable: true,
        sortable: false,
        width: '17%',
      },
      {
        header: `${translate('T_GENERAL_TRANSLATION')} EN`,
        accessor: `translations.en.title`,
        width: '17%',
      },
      {
        header: `${translate('T_GENERAL_TRANSLATION')} HE`,
        accessor: `translations.he.title`,
        width: '16%',
      },
      {
        ...operations(
          `translations.${process.env.REACT_APP_LOCALE}.title`,
          true,
          true,
          false,
          true
        ),
        width: '13%',
      },
    ],
    []
  )

  return (
    <Paper>
      <CollectionHeader
        buttonTitle={translate('T_MODULE_TRANSLATOR_ENTRIES_NEW_BUTTON')}
        buttonPath={routes().new.path}
      >
        {translate('T_MODULE_TRANSLATOR_ENTRIES')}
      </CollectionHeader>
      <SchemableCollection
        component={CollectionTable}
        path={`paths.${schema.endpoint}.get`}
        endpoint={schema.endpoint}
        columns={columns}
        customResourceSchema={schema.resource}
        defaultSorters={{ ord: 'desc' }}
        orderable={true}
        isRowLinkable={true}
        editPath={routes().edit.path}
        autoWidth={false}
      />
    </Paper>
  )
}
