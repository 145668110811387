import { types } from './_path_common_types'

const schema = {
  endpoint: '/api/memory_stories',
  resource: {
    definition: 'MemoryStories-memoryStories.read',
    sectionA: {
      properties: {
        translations: {
          type: 'translation',
          properties: {
            sectionATitle: {},
            sectionAText: {
              type: 'textarea'
            },
            sectionAPhotoText: {
              type: 'textarea'
            },
            sectionAPhotoTitle: {},
            sectionAVideoTitle: {},
            sectionAVideoText: {
              type: 'textarea'
            },
            sectionASubtextATitle: {},
            sectionASubtextAText: {
              type: 'textarea'
            },
            sectionAQuoteA: {
              type: 'textarea'
            },
            sectionAQuoteAAuthor: {},
            sectionAQuoteB: {
              type: 'textarea'
            },
            sectionAQuoteBAuthor: {},
            sectionAQuoteC: {
              type: 'textarea'
            },
            sectionAQuoteCAuthor: {},
            sectionAQuoteD: {
              type: 'textarea'
            },
            sectionAQuoteDAuthor: {},
          }
        },

        sectionARecipe: types.recipe,

        sectionAExhibitA: types.exhibit,
        sectionAExhibitASize: types.exhibit_size,
        sectionAExhibitAPosition: types.exhibit_position,
        sectionAExhibitAMask: types.exhibit_mask,

        sectionAExhibitB: types.exhibit,
        sectionAExhibitBSize: types.exhibit_size,
        sectionAExhibitBPosition: types.exhibit_position,
        sectionAExhibitBMask: types.exhibit_mask,

        sectionAExhibitC: types.exhibit,
        sectionAExhibitCSize: types.exhibit_size,
        sectionAExhibitCPosition: types.exhibit_position,
        sectionAExhibitCMask: types.exhibit_mask,

        sectionAYoutube: {
          type: 'youtube'
        },
        sectionAStat: {}
      }
    },
    sectionB: {
      properties: {
        translations: {
          type: 'translation',
          properties: {
            sectionBPhotoBText: {
              type: 'textarea'
            },
            sectionBPhotoBTitle: {},
            sectionBPhotoText: {
              type: 'textarea'
            },
            sectionBPhotoSubtext: {},
            sectionBPhotoTitle: {},
            sectionBVideoATitle: {},
            sectionBVideoAText: {
              type: 'textarea'
            },
            sectionBVideoBTitle: {},
            sectionBVideoBText: {
              type: 'textarea'
            },
            sectionBVideoCTitle: {},
            sectionBVideoCText: {
              type: 'textarea'
            },
            sectionBSubtextATitle: {},
            sectionBSubtextAText: {
              type: 'textarea'
            },
            sectionBSubtextBTitle: {},
            sectionBSubtextBText: {
              type: 'textarea'
            },
            sectionBSubtextCTitle: {},
            sectionBSubtextCText: {
              type: 'textarea'
            },
            sectionBSubtextDTitle: {},
            sectionBSubtextDText: {
              type: 'textarea'
            },
            sectionBSliderTitle: {
              type: 'textarea'
            },
          },
        },

        sectionBRecipeA: types.recipe,
        sectionBRecipeB: types.recipe,

        sectionBExhibitA: types.exhibit,
        sectionBExhibitASize: types.exhibit_size,
        sectionBExhibitAPosition: types.exhibit_position,
        sectionBExhibitAMask: types.exhibit_mask,

        sectionBSliderExhibitA: types.exhibit,
        sectionBSliderExhibitAMask: types.exhibit_mask,

        sectionBSliderExhibitB: types.exhibit,
        sectionBSliderExhibitBMask: types.exhibit_mask,

        sectionBSliderExhibitC: types.exhibit,
        sectionBSliderExhibitCMask: types.exhibit_mask,

        sectionBSliderExhibitD: types.exhibit,
        sectionBSliderExhibitDMask: types.exhibit_mask,

        sectionBYoutubeA: {
          type: 'youtube'
        },
        sectionBYoutubeB: {
          type: 'youtube'
        },
        sectionBYoutubeC: {
          type: 'youtube'
        },
        sectionBStat: {}
      }
    },
  },
}

export default schema
