import React, { useState, useMemo } from 'react'
import { BlankForm } from 'core/pages'
import { Paper } from 'core/components/Paper'
import { Loader } from 'core/components/Loader'
import { Title } from 'core/components/Title'
import { usePropertyResourcesFetch } from 'core/_helpers/usePropertyResourcesFetch'
import { usePathFetch } from '_helpers/usePathFetch'
import { handlePathSectionSuccess } from '_helpers/handlePathSectionSuccess'
import { usePathSectionStyles } from 'pages/usePathSectionStyles'
import { NotFound } from 'core/pages'
import { translate } from 'core/_helpers/translate'
import schema from '_schema/traditionHolidays'
import recipeHolidayTypeSchema from '_schema/recipeHolidayType'
import recipeSchema from '_schema/recipe'
import exhibitSchema from '_schema/exhibit'

export const Edit = ({ schema: definitions }) => {
  const [state, setState] = useState({
    isFetching: true,
    fetchError: false,
    resource: null,
  })

  usePathFetch(setState, schema.endpoint)

  const additionalResourcesMapping = useMemo(
    () => ({
      recipeHolidayType: `${recipeHolidayTypeSchema.endpoint}?order[ord]=desc`,
      recipe: `${recipeSchema.endpoint}?order[ord]=desc`,
      exhibit: `${exhibitSchema.endpoint}?order[ord]=desc`,
    }),
    []
  )

  const [additionalResources] = usePropertyResourcesFetch(
    additionalResourcesMapping
  )

  const resourceSchema = useMemo(() => ({
    ...schema.resource,
    sectionA: {
      ...schema.sectionA,
      properties: Object.assign(
        {},
        ...Object.keys(schema.resource.sectionA.properties).map(
          prop => ({ [prop] : {
            ...schema.resource.sectionA.properties[prop],
            ...([
              'sectionAExhibitA',
              'sectionAExhibitB',
              'sectionAExhibitC'
            ].includes(prop)
              ? { resources: additionalResources.exhibit }
              : {})
          }})
        )
      )
    },
    holidayA: {
      ...schema.holidayA,
      properties: Object.assign(
        {},
        ...Object.keys(schema.resource.holidayA.properties).map(
          prop => ({ [prop] : {
            ...schema.resource.holidayA.properties[prop],
            ...([
              'holidayASliderAExhibitA',
              'holidayASliderAExhibitB',
              'holidayASliderAExhibitC',
              'holidayASliderAExhibitD',
              'holidayASliderAExhibitE',
              'holidayASliderAExhibitF',
              'holidayASliderAExhibitG',
              'holidayASliderAExhibitH',
            ].includes(prop)
              ? { resources: additionalResources.exhibit }
              : prop === 'holidayARecipe'
              ? { resources: additionalResources.recipe }
              : prop === 'holidayARecipeHolidayType'
              ? { resources: additionalResources.recipeHolidayType }
              : {})
          }})
        )
      )
    },
    holidayB: {
      ...schema.holidayB,
      properties: Object.assign(
        {},
        ...Object.keys(schema.resource.holidayB.properties).map(
          prop => ({ [prop] : {
            ...schema.resource.holidayB.properties[prop],
            ...([
              'holidayBSliderAExhibitA',
              'holidayBSliderAExhibitB',
              'holidayBSliderAExhibitC',
              'holidayBSliderAExhibitD',
              'holidayBExhibitA',
              'holidayBExhibitB',
              'holidayBExhibitC',
            ].includes(prop)
              ? { resources: additionalResources.exhibit }
              : prop === 'holidayBRecipe'
              ? { resources: additionalResources.recipe }
              : prop === 'holidayBRecipeHolidayType'
              ? { resources: additionalResources.recipeHolidayType }
              : {})
          }})
        )
      )
    },
    holidayC: {
      ...schema.holidayC,
      properties: Object.assign(
        {},
        ...Object.keys(schema.resource.holidayC.properties).map(
          prop => ({ [prop] : {
            ...schema.resource.holidayC.properties[prop],
            ...([
              'holidayCExhibit',
              'holidayCExhibitB'
            ].includes(prop)
              ? { resources: additionalResources.exhibit }
              : {})
          }})
        )
      )
    },
    holidayD: {
      ...schema.holidayD,
      properties: Object.assign(
        {},
        ...Object.keys(schema.resource.holidayD.properties).map(
          prop => ({ [prop] : {
            ...schema.resource.holidayD.properties[prop],
            ...(prop === 'holidayDRecipe'
              ? { resources: additionalResources.recipe }
              : prop === 'holidayDRecipeHolidayType'
              ? { resources: additionalResources.recipeHolidayType }
              : {})
          }})
        )
      )
    },
    holidayE: {
      ...schema.holidayE,
      properties: Object.assign(
        {},
        ...Object.keys(schema.resource.holidayE.properties).map(
          prop => ({ [prop] : {
            ...schema.resource.holidayE.properties[prop],
            ...(prop === 'holidayEExhibit'
              ? { resources: additionalResources.exhibit }
              : prop === 'holidayERecipe'
              ? { resources: additionalResources.recipe }
              : prop === 'holidayERecipeHolidayType'
              ? { resources: additionalResources.recipeHolidayType }
              : {})
          }})
        )
      )
    },
    holidayF: {
      ...schema.holidayF,
      properties: Object.assign(
        {},
        ...Object.keys(schema.resource.holidayF.properties).map(
          prop => ({ [prop] : {
            ...schema.resource.holidayF.properties[prop],
            ...(prop === 'holidayFExhibit'
              ? { resources: additionalResources.exhibit }
              : prop === 'holidayFRecipe'
              ? { resources: additionalResources.recipe }
              : prop === 'holidayFRecipeHolidayType'
              ? { resources: additionalResources.recipeHolidayType }
              : {})
          }})
        )
      )
    },
    holidayG: {
      ...schema.holidayG,
      properties: Object.assign(
        {},
        ...Object.keys(schema.resource.holidayG.properties).map(
          prop => ({ [prop] : {
            ...schema.resource.holidayG.properties[prop],
            ...(prop === 'holidayGExhibit'
              ? { resources: additionalResources.exhibit }
              : {})
          }})
        )
      )
    },
    holidayH: {
      ...schema.holidayH,
      properties: Object.assign(
        {},
        ...Object.keys(schema.resource.holidayH.properties).map(
          prop => ({ [prop] : {
            ...schema.resource.holidayH.properties[prop],
            ...(prop === 'holidayHRecipe'
              ? { resources: additionalResources.recipe }
              : prop === 'holidayHRecipeHolidayType'
              ? { resources: additionalResources.recipeHolidayType }
              : {})
          }})
        )
      )
    },
    holidayI: {
      ...schema.holidayI,
      properties: Object.assign(
        {},
        ...Object.keys(schema.resource.holidayI.properties).map(
          prop => ({ [prop] : {
            ...schema.resource.holidayI.properties[prop],
            ...(prop === 'holidayIRecipe'
              ? { resources: additionalResources.recipe }
              : prop === 'holidayIRecipeHolidayType'
              ? { resources: additionalResources.recipeHolidayType }
              : {})
          }})
        )
      )
    },
  }), [additionalResources])

  const { isFetching, fetchError, resource } = state

  const classes = usePathSectionStyles()

  return isFetching ? (
    <Paper>
      <Loader />
    </Paper>
  ) : fetchError ? (
    <NotFound />
  ) : (
    <Paper>
      <div className={classes.section}>
        <Title className={classes.section_title}>
          {translate('T_PATHS_TRADITION_HOLIDAYS_SECTION_A')}
        </Title>
        <BlankForm
          resource={resource}
          definitionSchema={definitions[schema.resource.definition]}
          customResourceSchema={resourceSchema.sectionA}
          method={resource['@id'] ? 'PUT' : 'POST'}
          url={resource['@id'] || schema.endpoint}
          handleSuccess={handlePathSectionSuccess(setState, schema.resource.sectionA.properties)}
          showSubmitAndStayButton={false}
          showCancelButton={false}
          onlyPassedProperties={true}
          fieldsFullWidth={false}
          buttonsFixed={false}
          width={700}
          key={`tradition-holidays-section-a-${resource['@id'] || 'empty'}`}
        />
      </div>
      <div className={classes.section}>
        <Title className={classes.section_title}>
          {translate('T_PATHS_TRADITION_HOLIDAYS_HOLIDAY_A')}
        </Title>
        <BlankForm
          resource={resource}
          definitionSchema={definitions[schema.resource.definition]}
          customResourceSchema={resourceSchema.holidayA}
          method={resource['@id'] ? 'PUT' : 'POST'}
          url={resource['@id'] || schema.endpoint}
          handleSuccess={handlePathSectionSuccess(setState, schema.resource.holidayA.properties)}
          showSubmitAndStayButton={false}
          showCancelButton={false}
          onlyPassedProperties={true}
          fieldsFullWidth={false}
          buttonsFixed={false}
          width={700}
          key={`tradition-holidays-holiday-a-${resource['@id'] || 'empty'}`}
        />
      </div>
      <div className={classes.section}>
        <Title className={classes.section_title}>
          {translate('T_PATHS_TRADITION_HOLIDAYS_HOLIDAY_B')}
        </Title>
        <BlankForm
          resource={resource}
          definitionSchema={definitions[schema.resource.definition]}
          customResourceSchema={resourceSchema.holidayB}
          method={resource['@id'] ? 'PUT' : 'POST'}
          url={resource['@id'] || schema.endpoint}
          handleSuccess={handlePathSectionSuccess(setState, schema.resource.holidayB.properties)}
          showSubmitAndStayButton={false}
          showCancelButton={false}
          onlyPassedProperties={true}
          fieldsFullWidth={false}
          buttonsFixed={false}
          width={700}
          key={`tradition-holidays-holiday-b-${resource['@id'] || 'empty'}`}
        />
      </div>
      <div className={classes.section}>
        <Title className={classes.section_title}>
          {translate('T_PATHS_TRADITION_HOLIDAYS_HOLIDAY_C')}
        </Title>
        <BlankForm
          resource={resource}
          definitionSchema={definitions[schema.resource.definition]}
          customResourceSchema={resourceSchema.holidayC}
          method={resource['@id'] ? 'PUT' : 'POST'}
          url={resource['@id'] || schema.endpoint}
          handleSuccess={handlePathSectionSuccess(setState, schema.resource.holidayC.properties)}
          showSubmitAndStayButton={false}
          showCancelButton={false}
          onlyPassedProperties={true}
          fieldsFullWidth={false}
          buttonsFixed={false}
          width={700}
          key={`tradition-holidays-holiday-c-${resource['@id'] || 'empty'}`}
        />
      </div>
      <div className={classes.section}>
        <Title className={classes.section_title}>
          {translate('T_PATHS_TRADITION_HOLIDAYS_HOLIDAY_D')}
        </Title>
        <BlankForm
          resource={resource}
          definitionSchema={definitions[schema.resource.definition]}
          customResourceSchema={resourceSchema.holidayD}
          method={resource['@id'] ? 'PUT' : 'POST'}
          url={resource['@id'] || schema.endpoint}
          handleSuccess={handlePathSectionSuccess(setState, schema.resource.holidayD.properties)}
          showSubmitAndStayButton={false}
          showCancelButton={false}
          onlyPassedProperties={true}
          fieldsFullWidth={false}
          buttonsFixed={false}
          width={700}
          key={`tradition-holidays-holiday-d-${resource['@id'] || 'empty'}`}
        />
      </div>
      <div className={classes.section}>
        <Title className={classes.section_title}>
          {translate('T_PATHS_TRADITION_HOLIDAYS_HOLIDAY_E')}
        </Title>
        <BlankForm
          resource={resource}
          definitionSchema={definitions[schema.resource.definition]}
          customResourceSchema={resourceSchema.holidayE}
          method={resource['@id'] ? 'PUT' : 'POST'}
          url={resource['@id'] || schema.endpoint}
          handleSuccess={handlePathSectionSuccess(setState, schema.resource.holidayE.properties)}
          showSubmitAndStayButton={false}
          showCancelButton={false}
          onlyPassedProperties={true}
          fieldsFullWidth={false}
          buttonsFixed={false}
          width={700}
          key={`tradition-holidays-holiday-e-${resource['@id'] || 'empty'}`}
        />
      </div>
      <div className={classes.section}>
        <Title className={classes.section_title}>
          {translate('T_PATHS_TRADITION_HOLIDAYS_HOLIDAY_F')}
        </Title>
        <BlankForm
          resource={resource}
          definitionSchema={definitions[schema.resource.definition]}
          customResourceSchema={resourceSchema.holidayF}
          method={resource['@id'] ? 'PUT' : 'POST'}
          url={resource['@id'] || schema.endpoint}
          handleSuccess={handlePathSectionSuccess(setState, schema.resource.holidayF.properties)}
          showSubmitAndStayButton={false}
          showCancelButton={false}
          onlyPassedProperties={true}
          fieldsFullWidth={false}
          buttonsFixed={false}
          width={700}
          key={`tradition-holidays-holiday-f-${resource['@id'] || 'empty'}`}
        />
      </div>
      <div className={classes.section}>
        <Title className={classes.section_title}>
          {translate('T_PATHS_TRADITION_HOLIDAYS_HOLIDAY_G')}
        </Title>
        <BlankForm
          resource={resource}
          definitionSchema={definitions[schema.resource.definition]}
          customResourceSchema={resourceSchema.holidayG}
          method={resource['@id'] ? 'PUT' : 'POST'}
          url={resource['@id'] || schema.endpoint}
          handleSuccess={handlePathSectionSuccess(setState, schema.resource.holidayG.properties)}
          showSubmitAndStayButton={false}
          showCancelButton={false}
          onlyPassedProperties={true}
          fieldsFullWidth={false}
          buttonsFixed={false}
          width={700}
          key={`tradition-holidays-holiday-g-${resource['@id'] || 'empty'}`}
        />
      </div>
      <div className={classes.section}>
        <Title className={classes.section_title}>
          {translate('T_PATHS_TRADITION_HOLIDAYS_HOLIDAY_H')}
        </Title>
        <BlankForm
          resource={resource}
          definitionSchema={definitions[schema.resource.definition]}
          customResourceSchema={resourceSchema.holidayH}
          method={resource['@id'] ? 'PUT' : 'POST'}
          url={resource['@id'] || schema.endpoint}
          handleSuccess={handlePathSectionSuccess(setState, schema.resource.holidayH.properties)}
          showSubmitAndStayButton={false}
          showCancelButton={false}
          onlyPassedProperties={true}
          fieldsFullWidth={false}
          buttonsFixed={false}
          width={700}
          key={`tradition-holidays-holiday-h-${resource['@id'] || 'empty'}`}
        />
      </div>
      <div className={classes.section}>
        <Title className={classes.section_title}>
          {translate('T_PATHS_TRADITION_HOLIDAYS_HOLIDAY_I')}
        </Title>
        <BlankForm
          resource={resource}
          definitionSchema={definitions[schema.resource.definition]}
          customResourceSchema={resourceSchema.holidayI}
          method={resource['@id'] ? 'PUT' : 'POST'}
          url={resource['@id'] || schema.endpoint}
          handleSuccess={handlePathSectionSuccess(setState, schema.resource.holidayI.properties)}
          showSubmitAndStayButton={false}
          showCancelButton={false}
          onlyPassedProperties={true}
          fieldsFullWidth={false}
          buttonsFixed={false}
          width={700}
          key={`tradition-holidays-holiday-i-${resource['@id'] || 'empty'}`}
        />
      </div>
      <div className={classes.section}>
        <Title className={classes.section_title}>
          {translate('T_PATHS_TRADITION_HOLIDAYS_HOLIDAY_J')}
        </Title>
        <BlankForm
          resource={resource}
          definitionSchema={definitions[schema.resource.definition]}
          customResourceSchema={resourceSchema.holidayJ}
          method={resource['@id'] ? 'PUT' : 'POST'}
          url={resource['@id'] || schema.endpoint}
          handleSuccess={handlePathSectionSuccess(setState, schema.resource.holidayJ.properties)}
          showSubmitAndStayButton={false}
          showCancelButton={false}
          onlyPassedProperties={true}
          fieldsFullWidth={false}
          buttonsFixed={false}
          width={700}
          key={`tradition-holidays-holiday-j-${resource['@id'] || 'empty'}`}
        />
      </div>
    </Paper>
  )
}
