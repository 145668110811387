const schema = {
  endpoint: '/api/exhibit_storage_locations',
  resource: {
    definition: 'ExhibitStorageLocation-exhibitStorageLocation.read',
    properties: {
      translations: {
        type: 'translation',
        properties: {
          title: {},
        },
      },
      stat: {
        description: 'T_GENERAL_PUBLISH',
      },
    },
  },
}

export default schema
