import { types } from './_path_common_types'

const schema = {
  endpoint: '/api/modernity_kitchens',
  resource: {
    definition: 'ModernityKitchen-modernityKitchen.read',
    sectionA: {
      properties: {
        translations: {
          type: 'translation',
          properties: {
            sectionATitle: {},
            sectionAText: {
              type: 'textarea'
            },
          }
        },
        sectionAExhibitA: types.exhibit,
        sectionAExhibitASize: types.exhibit_size,
        sectionAExhibitAPosition: types.exhibit_position,
        sectionAExhibitAMask: types.exhibit_mask,

        sectionAExhibitB: types.exhibit,
        sectionAExhibitBSize: types.exhibit_size,
        sectionAExhibitBPosition: types.exhibit_position,
        sectionAExhibitBMask: types.exhibit_mask,

        sectionAStat: {}
      }
    },
    sectionB: {
      properties: {
        translations: {
          type: 'translation',
          properties: {
            sectionBTitle: {},
            sectionBText: {
              type: 'textarea'
            },
            sectionBVideoTitle: {},
            sectionBVideoText: {
              type: 'textarea'
            }
          }
        },
        sectionBRecipe: types.recipe,

        sectionBExhibitA: types.exhibit,
        sectionBExhibitASize: types.exhibit_size,
        sectionBExhibitAPosition: types.exhibit_position,
        sectionBExhibitAMask: types.exhibit_mask,

        sectionBExhibitB: types.exhibit,
        sectionBExhibitBSize: types.exhibit_size,
        sectionBExhibitBPosition: types.exhibit_position,
        sectionBExhibitBMask: types.exhibit_mask,

        sectionBExhibitC: types.exhibit,
        sectionBExhibitCSize: types.exhibit_size,
        sectionBExhibitCPosition: types.exhibit_position,
        sectionBExhibitCMask: types.exhibit_mask,

        sectionBExhibitD: types.exhibit,
        sectionBExhibitDSize: types.exhibit_size,
        sectionBExhibitDPosition: types.exhibit_position,
        sectionBExhibitDMask: types.exhibit_mask,

        sectionBExhibitE: types.exhibit,
        sectionBExhibitESize: types.exhibit_size,
        sectionBExhibitEPosition: types.exhibit_position,
        sectionBExhibitEMask: types.exhibit_mask,

        sectionBExhibitF: types.exhibit,
        sectionBExhibitFSize: types.exhibit_size,
        sectionBExhibitFPosition: types.exhibit_position,
        sectionBExhibitFMask: types.exhibit_mask,

        sectionBYoutube: {
          type: 'youtube'
        },
        sectionBStat: {}
      }
    },
    sectionC: {
      properties: {
        translations: {
          type: 'translation',
          properties: {
            sectionCTitle:  {},
            sectionCText: {
              type: 'textarea'
            },
            sectionCSliderATitle: {
              type: 'textarea'
            },
            sectionCSliderAText: {},
            sectionCSliderBTitle: {
              type: 'textarea'
            },
            sectionCSliderBText: {},
          }
        },
        sectionCExhibitA: types.exhibit,
        sectionCExhibitASize: types.exhibit_size,
        sectionCExhibitAPosition: types.exhibit_position,
        sectionCExhibitAMask: types.exhibit_mask,

        sectionCSliderAExhibitA: types.exhibit,
        sectionCSliderAExhibitAMask: types.exhibit_mask,
        sectionCSliderAExhibitB: types.exhibit,
        sectionCSliderAExhibitBMask: types.exhibit_mask,
        sectionCSliderAExhibitC: types.exhibit,
        sectionCSliderAExhibitCMask: types.exhibit_mask,
        sectionCSliderAExhibitD: types.exhibit,
        sectionCSliderAExhibitDMask: types.exhibit_mask,
        sectionCSliderAExhibitE: types.exhibit,
        sectionCSliderAExhibitEMask: types.exhibit_mask,
        sectionCSliderAExhibitF: types.exhibit,
        sectionCSliderAExhibitFMask: types.exhibit_mask,
        sectionCSliderAExhibitG: types.exhibit,
        sectionCSliderAExhibitGMask: types.exhibit_mask,

        sectionCSliderBExhibitA: types.exhibit,
        sectionCSliderBExhibitAMask: types.exhibit_mask,
        sectionCSliderBExhibitB: types.exhibit,
        sectionCSliderBExhibitBMask: types.exhibit_mask,
        sectionCSliderBExhibitC: types.exhibit,
        sectionCSliderBExhibitCMask: types.exhibit_mask,
        sectionCSliderBExhibitD: types.exhibit,
        sectionCSliderBExhibitDMask: types.exhibit_mask,
        sectionCSliderBExhibitE: types.exhibit,
        sectionCSliderBExhibitEMask: types.exhibit_mask,
        sectionCSliderBExhibitF: types.exhibit,
        sectionCSliderBExhibitFMask: types.exhibit_mask,
        sectionCSliderBExhibitG: types.exhibit,
        sectionCSliderBExhibitGMask: types.exhibit_mask,

        sectionCStat: {}
      }
    },
    sectionD: {
      properties: {
        translations: {
          type: 'translation',
          properties: {
            sectionDTitle: {},
            sectionDText: {
              type: 'textarea'
            },
          }
        },
        sectionDRecipe: types.recipe,

        sectionDExhibitA: types.exhibit,
        sectionDExhibitASize: types.exhibit_size,
        sectionDExhibitAPosition: types.exhibit_position,
        sectionDExhibitAMask: types.exhibit_mask,

        sectionDStat: {}
      }
    },
  },
}

export default schema
