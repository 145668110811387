import React, { useMemo } from 'react'
import { Paper } from 'core/components/Paper'
import { SchemableCollection } from 'core/components/SchemableCollection'
import { CollectionTable } from 'core/components/table'
import { operations } from './table/_columns/operations'
import { PhotoCell } from 'core/components/table/cells/PhotoCell'
import { RatingCell } from 'pages/PolinRecipes/table/cells/RatingCell'
import { translate } from 'core/_helpers/translate'
import schema from '_schema/recipe'
import routes from './routes'

export const Collection = () => {
  const columns = useMemo(
    () => [
      {
        header: translate('T_GENERAL_TITLE'),
        accessor: `translations.${process.env.REACT_APP_LOCALE}.title`,
        filterName: 'translations.title',
        filterable: true,
        sortable: false,
        width: '35%',
      },
      {
        accessor: 'authorName',
        width: '20%',
      },
      {
        accessor: 'currentRating',
        Cell: RatingCell,
        width: '10%',
      },
      {
        accessor: 'photo',
        Cell: PhotoCell,
        width: '15%',
      },
      {
        ...operations(),
        width: '15%',
      },
    ],
    []
  )

  return (
    <Paper>
      <SchemableCollection
        component={CollectionTable}
        path={`paths.${schema.endpoint}.get`}
        endpoint={schema.endpoint}
        columns={columns}
        customResourceSchema={schema.user_resource}
        defaultFilters={{ type: 'user' }}
        defaultSorters={{ createdAt: 'desc' }}
        storeCollectionId="user_recipes"
        isRowLinkable={true}
        editPath={routes().edit.path}
        autoWidth={false}
      />
    </Paper>
  )
}
