export const text = () => ({
  name: 'text',
  label: 'T_GENERAL_BLOCK_TYPE_TEXT',
  properties: {
    translations: {
      type: 'translation',
      properties: {
        title: {},
        text: {
          type: 'tinymce',
        },
      },
    },
  },
})
