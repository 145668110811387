import { translate } from './translate'

const defaultSelectTitle = resource =>
  resource.translations?.[process.env.REACT_APP_LOCALE]?.title ||
  resource.title ||
  resource.name ||
  resource.fullName

export const modifyBreadcrumbs = (
  resource,
  parent,
  paths,
  id = null,
  length = 20,
  selectTitle = null
) => {
  const lastPath = paths.pop()

  if (!paths.length) {
    return [lastPath]
  }

  if (paths[paths.length - 1].self && parent) {
    paths.push({ ...paths[paths.length - 1] })
  }

  if (resource) {
    const title =
      (selectTitle ? selectTitle(resource) : defaultSelectTitle(resource)) ||
      translate('T_GENERAL_MISSING_TITLE')

    paths[paths.length - 1].title = `${translate(
      paths[paths.length - 1].title
    )} (${title.length > length ? `${title.slice(0, length)}..` : title})`

    if (id && paths[paths.length - 1].path.includes(':id')) {
      paths[paths.length - 1].path = paths[paths.length - 1].path.replace(':id', id)
    }
  }

  if (!parent) {
    return [...paths, lastPath]
  }

  const { path, self } = paths[paths.length - 1]

  paths[paths.length - 1].path = (self || path).includes(':id')
    ? (self || path).replace(':id', parent[process.env.REACT_APP_RESOURCE_ID])
    : self || path
  // : `${self || path}?pid=${parent[process.env.REACT_APP_RESOURCE_ID]}`

  return [...modifyBreadcrumbs(parent, parent.parent, [...paths]), lastPath]
}
