const schema = {
  endpoint: '/api/memory_tables',
  resource: {
    definition: 'MemoryTable-memoryTable.read',
    sectionA: {
      properties: {
        translations: {
          type: 'translation',
          properties: {
            sectionATitle: {},
            sectionAText: {
              type: 'textarea'
            },
            sectionAPhotoText: {
              type: 'textarea'
            },
            sectionAPhotoSubtext: {
              type: 'textarea'
            },
            sectionAPhotoAuthor: {},
            sectionAInstallationText: {
              type: 'textarea'
            },
            sectionAInstallationLink: {}
          },
        },
        sectionAStat: {}
      }
    },
  },
}

export default schema
