import React from 'react'
import { Form } from 'core/pages'
import schema from '_schema/exhibit'
import routes from './routes'

export const Edit = ({ schema: definitions, ...rest }) => {
  const { match } = rest

  const iri = `${schema.endpoint}/${match.params.id}`

  return (
    <Form
      definitionSchema={definitions[schema.resource.definition]}
      customResourceSchema={{
        ...schema.resource,
        properties: {
          ...schema.resource.properties,
          linkedExhibits: {
            ...schema.resource.properties.linkedExhibits,
            endpoint: `${schema.resource.properties.linkedExhibits.endpoint}&uuid[neq]=${match.params.id}`
          }
        }
      }}
      iri={iri}
      method="PUT"
      url={iri}
      collectionPath={routes().index.path}
      storeCollectionId={schema.endpoint}
      fieldsFullWidth={false}
      width={700}
    />
  )
}
