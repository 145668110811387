import { useState, useEffect } from 'react'
import { fetchDataHandleAuthError } from 'core/_helpers/fetchDataHandleAuthError'
import { notification } from 'core/_helpers/notification'
import buildUrl from 'build-url'

export const useRecipePhotoCollectionFetch = (endpoint, pid) => {
  const [state, setState] = useState([])

  useEffect(() => {
    const url = buildUrl(endpoint, {
      queryParams: { [`parent.${process.env.REACT_APP_RESOURCE_ID}`]: pid },
    })

    const controller = new AbortController()
    const { signal } = controller

    fetchDataHandleAuthError(
      url,
      'GET',
      { signal },
      response => {
        setState(response['hydra:member'])
      },
      error => {
        if (error.response.title === 'AbortError') {
          return
        }

        notification('error', error.response.detail, error.response.title)
      }
    )

    return () => controller.abort()
  }, [endpoint, pid])

  return [state, setState]
}
