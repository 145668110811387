import React, { useState } from 'react'
import { BlankForm } from 'core/pages'
import { Paper } from 'core/components/Paper'
import { Loader } from 'core/components/Loader'
import { Title } from 'core/components/Title'
import { usePathFetch } from '_helpers/usePathFetch'
import { handlePathSectionSuccess } from '_helpers/handlePathSectionSuccess'
import { usePathSectionStyles } from 'pages/usePathSectionStyles'
import { NotFound } from 'core/pages'
import { translate } from 'core/_helpers/translate'
import schema from '_schema/memoryTable'

export const Edit = ({ schema: definitions }) => {
  const [state, setState] = useState({
    isFetching: true,
    fetchError: false,
    resource: null,
  })

  usePathFetch(setState, schema.endpoint)

  const { isFetching, fetchError, resource } = state

  const classes = usePathSectionStyles()

  return isFetching ? (
    <Paper>
      <Loader />
    </Paper>
  ) : fetchError ? (
    <NotFound />
  ) : (
    <Paper>
      <div className={classes.section}>
        <Title className={classes.section_title}>
          {translate('T_PATHS_MEMORY_TABLE_SECTION_A')}
        </Title>
        <BlankForm
          resource={resource}
          definitionSchema={definitions[schema.resource.definition]}
          customResourceSchema={schema.resource.sectionA}
          method={resource['@id'] ? 'PUT' : 'POST'}
          url={resource['@id'] || schema.endpoint}
          handleSuccess={handlePathSectionSuccess(setState, schema.resource.sectionA.properties)}
          showSubmitAndStayButton={false}
          showCancelButton={false}
          onlyPassedProperties={true}
          fieldsFullWidth={false}
          buttonsFixed={false}
          width={700}
          key={`memory-table-section-a-${resource['@id'] || 'empty'}`}
        />
      </div>
    </Paper>
  )
}
