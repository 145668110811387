import { boolean } from 'core/_helpers/boolean'
import home from 'core/pages/Home/routes'
import admins from 'core/pages/Admins/routes'
import adminGroups from 'core/pages/AdminGroups/routes'
import loginEntries from 'core/pages/LoginEntries/routes'
import registryEntries from 'core/pages/RegistryEntries/routes'
import settings from 'core/pages/Settings/routes'
import profile from 'core/pages/Profile/routes'
import messenger from 'core/pages/Messenger/routes'
import pages from 'core/pages/Pages/routes'
import translatorEntries from 'core/pages/TranslatorEntries/routes'
import packageFiles from 'core/pages/PackageFiles/routes'
import packageGalleries from 'core/pages/PackageGalleries/routes'
import packageGalleryItems from 'core/pages/PackageGalleryItems/routes'
import homeConfig from 'pages/HomeConfig/routes'
import traditionRules from 'pages/TraditionRules/routes'
import traditionThenAndNow from 'pages/TraditionThenAndNow/routes'
import traditionBlessings from 'pages/TraditionBlessings/routes'
import traditionHolidays from 'pages/TraditionHolidays/routes'
import traditionHolidaysThenAndNow from 'pages/TraditionHolidaysThenAndNow/routes'
import traditionTable from 'pages/TraditionTable/routes'
import diasporaManners from 'pages/DiasporaManners/routes'
import diasporaAshkenazi from 'pages/DiasporaAshkenazi/routes'
import diasporaAshkenaziTastes from 'pages/DiasporaAshkenaziTastes/routes'
import diasporaSephardic from 'pages/DiasporaSephardic/routes'
import diasporaSephardicTastes from 'pages/DiasporaSephardicTastes/routes'
import diasporaTable from 'pages/DiasporaTable/routes'
import modernityBooks from 'pages/ModernityBooks/routes'
import modernitySituations from 'pages/ModernitySituations/routes'
import modernityWars from 'pages/ModernityWars/routes'
import modernityMigrations from 'pages/ModernityMigrations/routes'
import modernityKitchen from 'pages/ModernityKitchen/routes'
import modernityPoland from 'pages/ModernityPoland/routes'
import modernityTable from 'pages/ModernityTable/routes'
import memoryStories from 'pages/MemoryStories/routes'
import memorySouvenirs from 'pages/MemorySouvenirs/routes'
import memoryTable from 'pages/MemoryTable/routes'
import polinRecipes from 'pages/PolinRecipes/routes'
import userRecipes from 'pages/UserRecipes/routes'
import recipeRatings from 'pages/RecipeRatings/routes'
import recipeMealTypes from 'pages/RecipeMealTypes/routes'
import recipeDietTypes from 'pages/RecipeDietTypes/routes'
import recipeHolidayTypes from 'pages/RecipeHolidayTypes/routes'
import recipePreparationTimes from 'pages/RecipePreparationTimes/routes'
import exhibits from 'pages/Exhibits/routes'
import exhibitCategories from 'pages/ExhibitCategories/routes'
import exhibitSections from 'pages/ExhibitSections/routes'
import exhibitHolidayTypes from 'pages/ExhibitHolidayTypes/routes'
import exhibitStorageLocations from 'pages/ExhibitStorageLocations/routes'
import workshops from 'pages/Workshops/routes'
import menuLinks from 'pages/MenuLinks/routes'
import footerLinks from 'pages/FooterLinks/routes'
import config from 'pages/Config/routes'

const routes = () =>
  [
    ...getArray(home()),
    ...getArray(homeConfig()),
    ...getArray(traditionRules()),
    ...getArray(traditionThenAndNow()),
    ...getArray(traditionBlessings()),
    ...getArray(traditionHolidays()),
    ...getArray(traditionHolidaysThenAndNow()),
    ...getArray(traditionTable()),
    ...getArray(diasporaManners()),
    ...getArray(diasporaAshkenazi()),
    ...getArray(diasporaAshkenaziTastes()),
    ...getArray(diasporaSephardic()),
    ...getArray(diasporaSephardicTastes()),
    ...getArray(diasporaTable()),
    ...getArray(modernityBooks()),
    ...getArray(modernitySituations()),
    ...getArray(modernityWars()),
    ...getArray(modernityMigrations()),
    ...getArray(modernityKitchen()),
    ...getArray(modernityPoland()),
    ...getArray(modernityTable()),
    ...getArray(memoryStories()),
    ...getArray(memorySouvenirs()),
    ...getArray(memoryTable()),
    ...getArray(pages()),
    ...getArray(polinRecipes()),
    ...getArray(userRecipes()),
    ...getArray(recipeRatings()),
    ...getArray(recipeMealTypes()),
    ...getArray(recipeDietTypes()),
    ...getArray(recipeHolidayTypes()),
    ...getArray(recipePreparationTimes()),
    ...getArray(exhibits()),
    ...getArray(exhibitCategories()),
    ...getArray(exhibitSections()),
    ...getArray(exhibitHolidayTypes()),
    ...getArray(exhibitStorageLocations()),
    ...getArray(workshops()),
    ...getArray(packageFiles()),
    ...getArray(packageGalleries()),
    ...getArray(packageGalleryItems()),
    ...getArray(menuLinks()),
    ...getArray(footerLinks()),
    ...getArray(admins()),
    ...getArray(adminGroups()),
    ...getArray(loginEntries()),
    ...getArray(registryEntries()),
    ...getArray(translatorEntries()),
    ...getArray(config()),
    ...getArray(settings()),
    ...getArray(profile()),
  ].concat(
    boolean(process.env.REACT_APP_MESSENGER_SHOW) ? getArray(messenger()) : []
  )

const getArray = routes => Object.values(routes)

export default routes
