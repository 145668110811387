import { types } from './_path_common_types'

const schema = {
  endpoint: '/api/diaspora_ashkenazi_tastes',
  resource: {
    definition: 'DiasporaAshkenaziTastes-diasporaAshkenaziTastes.read',
    sectionA: {
      properties: {
        translations: {
          type: 'translation',
          properties: {
            sectionATitle: {},
            sectionAMealATitle: {},
            sectionAMealAText: {
              type: 'textarea'
            },
            sectionAMealASubtitle: {},
          }
        },
        sectionARecipeA: types.recipe,

        sectionAStat: {}
      }
    },
    sectionB: {
      properties: {
        translations: {
          type: 'translation',
          properties: {
            sectionBTitle: {},
            sectionBText: {
              type: 'textarea'
            },
          }
        },
        sectionBExhibitA: types.exhibit,
        sectionBExhibitASize: types.exhibit_size,
        sectionBExhibitAPosition: types.exhibit_position,
        sectionBExhibitAMask: types.exhibit_mask,

        sectionBStat: {}
      }
    },
    sectionC: {
      properties: {
        translations: {
          type: 'translation',
          properties: {
            sectionCMealATitle: {},
            sectionCMealAText: {
              type: 'textarea'
            },
            sectionCMealASubtitle: {},
            sectionCSliderTitle: {
              type: 'textarea'
            },
          }
        },
        sectionCRecipeA: types.recipe,

        sectionCSliderAExhibitA: types.exhibit,
        sectionCSliderAExhibitAMask: types.exhibit_mask,
        sectionCSliderAExhibitB: types.exhibit,
        sectionCSliderAExhibitBMask: types.exhibit_mask,
        sectionCSliderAExhibitC: types.exhibit,
        sectionCSliderAExhibitCMask: types.exhibit_mask,
        sectionCSliderAExhibitD: types.exhibit,
        sectionCSliderAExhibitDMask: types.exhibit_mask,

        sectionCStat: {}
      }
    },
    sectionD: {
      properties: {
        translations: {
          type: 'translation',
          properties: {
            sectionDVideoTitle: {},
            sectionDVideoText: {
              type: 'textarea'
            },
            sectionDMealATitle: {},
            sectionDMealAText: {
              type: 'textarea'
            },
            sectionDMealASubtitle: {}
          }
        },
        sectionDRecipeA: types.recipe,
        sectionDYoutube: {
          type: 'youtube'
        },

        sectionDStat: {}
      }
    },
    sectionE: {
      properties: {
        translations: {
          type: 'translation',
          properties: {
            sectionEMealATitle: {},
            sectionEMealAText: {
              type: 'textarea'
            },
            sectionEMealASubtitle: {}
          }
        },
        sectionERecipeA: types.recipe,

        sectionEStat: {}
      }
    },
    sectionF: {
      properties: {
        translations: {
          type: 'translation',
          properties: {
            sectionFMealATitle: {},
            sectionFMealAText: {
              type: 'textarea'
            },
            sectionFMealASubtitle: {},
          }
        },

        sectionFStat: {}
      }
    },
    sectionG: {
      properties: {
        translations: {
          type: 'translation',
          properties: {
            sectionGMealATitle: {},
            sectionGMealAText: {
              type: 'textarea'
            },
            sectionGMealASubtitle: {}
          }
        },
        sectionGRecipeA: types.recipe,

        sectionGStat: {}
      }
    },
    sectionH: {
      properties: {
        translations: {
          type: 'translation',
          properties: {
            sectionHMealATitle: {},
            sectionHMealAText: {
              type: 'textarea'
            },
            sectionHMealASubtitle: {}
          }
        },
        sectionHRecipeA: types.recipe,

        sectionHStat: {}
      }
    },
    sectionI: {
      properties: {
        translations: {
          type: 'translation',
          properties: {
            sectionIMealATitle: {},
            sectionIMealAText: {
              type: 'textarea'
            },
            sectionIMealASubtitle: {}
          }
        },
        sectionIRecipeA: types.recipe,

        sectionIStat: {}
      }
    },
    sectionJ: {
      properties: {
        translations: {
          type: 'translation',
          properties: {
            sectionJMealATitle: {},
            sectionJMealAText: {
              type: 'textarea'
            },
            sectionJMealASubtitle: {}
          }
        },
        sectionJRecipeA: types.recipe,

        sectionJStat: {}
      }
    },
    sectionK: {
      properties: {
        translations: {
          type: 'translation',
          properties: {
            sectionKTitle: {},
            sectionKText: {
              type: 'textarea'
            },
            sectionKSubtextATitle: {},
            sectionKSubtextAText: {
              type: 'textarea'
            }
          }
        },
        sectionKRecipeA: types.recipe,

        sectionKStat: {}
      }
    }
  },
}

export default schema
