import { types } from './_path_common_types'

const schema = {
  endpoint: '/api/modernity_polands',
  resource: {
    definition: 'ModernityPoland-modernityPoland.read',
    sectionA: {
      properties: {
        translations: {
          type: 'translation',
          properties: {
            sectionATitle: {},
            sectionAText: {
              type: 'textarea'
            },
          }
        },
        sectionAExhibitA: types.exhibit,
        sectionAExhibitASize: types.exhibit_size,
        sectionAExhibitAPosition: types.exhibit_position,
        sectionAExhibitAMask: types.exhibit_mask,

        sectionAStat: {}
      }
    },
    sectionB: {
      properties: {
        translations: {
          type: 'translation',
          properties: {
            sectionBTitle: {},
            sectionBText: {
              type: 'textarea'
            },
            sectionBVideoTitle: {},
            sectionBVideoText: {
              type: 'textarea'
            },
            sectionBSliderTitle: {
              type: 'textarea'
            },
          }
        },
        sectionBExhibitA: types.exhibit,
        sectionBExhibitASize: types.exhibit_size,
        sectionBExhibitAPosition: types.exhibit_position,
        sectionBExhibitAMask: types.exhibit_mask,

        sectionBExhibitB: types.exhibit,
        sectionBExhibitBSize: types.exhibit_size,
        sectionBExhibitBPosition: types.exhibit_position,
        sectionBExhibitBMask: types.exhibit_mask,

        sectionBSliderAExhibitA: types.exhibit,
        sectionBSliderAExhibitAMask: types.exhibit_mask,
        sectionBSliderAExhibitB: types.exhibit,
        sectionBSliderAExhibitBMask: types.exhibit_mask,
        sectionBSliderAExhibitC: types.exhibit,
        sectionBSliderAExhibitCMask: types.exhibit_mask,
        sectionBSliderAExhibitD: types.exhibit,
        sectionBSliderAExhibitDMask: types.exhibit_mask,

        sectionBYoutube: {
          type: 'youtube'
        },
        sectionBStat: {}
      }
    },
    sectionC: {
      properties: {
        translations: {
          type: 'translation',
          properties: {
            sectionCTitle: {},
            sectionCText: {
              type: 'textarea'
            },
            sectionCSliderATitle: {
              type: 'textarea'
            },
            sectionCSliderAText: {},
            sectionCSliderBTitle: {
              type: 'textarea'
            },
            sectionCSliderCTitle: {
              type: 'textarea'
            },
            sectionCSliderCText: {},
            sectionCSubtextATitle: {},
            sectionCSubtextAText: {
              type: 'textarea'
            },
          }
        },
        sectionCSliderAExhibitA: types.exhibit,
        sectionCSliderAExhibitAMask: types.exhibit_mask,
        sectionCSliderAExhibitB: types.exhibit,
        sectionCSliderAExhibitBMask: types.exhibit_mask,
        sectionCSliderAExhibitC: types.exhibit,
        sectionCSliderAExhibitCMask: types.exhibit_mask,
        sectionCSliderAExhibitD: types.exhibit,
        sectionCSliderAExhibitDMask: types.exhibit_mask,
        sectionCSliderAExhibitE: types.exhibit,
        sectionCSliderAExhibitEMask: types.exhibit_mask,
        sectionCSliderAExhibitF: types.exhibit,
        sectionCSliderAExhibitFMask: types.exhibit_mask,
        sectionCSliderAExhibitG: types.exhibit,
        sectionCSliderAExhibitGMask: types.exhibit_mask,

        sectionCSliderBExhibitA: types.exhibit,
        sectionCSliderBExhibitAMask: types.exhibit_mask,
        sectionCSliderBExhibitB: types.exhibit,
        sectionCSliderBExhibitBMask: types.exhibit_mask,
        sectionCSliderBExhibitC: types.exhibit,
        sectionCSliderBExhibitCMask: types.exhibit_mask,
        sectionCSliderBExhibitD: types.exhibit,
        sectionCSliderBExhibitDMask: types.exhibit_mask,

        sectionCSliderCExhibitA: types.exhibit,
        sectionCSliderCExhibitAMask: types.exhibit_mask,
        sectionCSliderCExhibitB: types.exhibit,
        sectionCSliderCExhibitBMask: types.exhibit_mask,
        sectionCSliderCExhibitC: types.exhibit,
        sectionCSliderCExhibitCMask: types.exhibit_mask,
        sectionCSliderCExhibitD: types.exhibit,
        sectionCSliderCExhibitDMask: types.exhibit_mask,
        sectionCSliderCExhibitE: types.exhibit,
        sectionCSliderCExhibitEMask: types.exhibit_mask,
        sectionCSliderCExhibitF: types.exhibit,
        sectionCSliderCExhibitFMask: types.exhibit_mask,
        sectionCSliderCExhibitG: types.exhibit,
        sectionCSliderCExhibitGMask: types.exhibit_mask,

        sectionCStat: {}
      }
    },
  },
}

export default schema
