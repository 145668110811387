import { useEffect } from 'react'
import { fetchDataHandleAuthError } from 'core/_helpers/fetchDataHandleAuthError'
import { notification } from 'core/_helpers/notification'

export const usePathFetch = (setState, endpoint) => {
  useEffect(() => {
    const controller = new AbortController()
    const { signal } = controller

    fetchDataHandleAuthError(
      endpoint,
      'GET',
      { signal },
      response => {
        setState({
          isFetching: false,
          resource: response['hydra:member'].length
            ? response['hydra:member'][0]
            : {},
        })
      },
      error => {
        if (error.response.title === 'AbortError') {
          return
        }

        notification('error', error.response.detail, error.response.title)
        setState({
          isFetching: false,
          fetchError: true,
        })
      }
    )

    return () => controller.abort()
  }, [setState, endpoint])
}
