// import exhibitSchema from './exhibit'
// import recipeSchema from './recipe'

const exhibitType = {
  type: 'resource',
  titleAccessor: `translations.${process.env.REACT_APP_LOCALE}.title`,
  // endpoint: `${exhibitSchema.endpoint}?order[ord]=desc&pagination=false`,
}

const exhibitMaskType = {
  type: 'choice',
  choices: {
    rectangular: 'T_GENERAL_PHOTO_MASK_RECTANGULAR',
    round: 'T_GENERAL_PHOTO_MASK_ROUND',
    round_horizontal: 'T_GENERAL_PHOTO_MASK_ROUND_HORIZONTAL',
    diamond: 'T_GENERAL_PHOTO_MASK_DIAMOND',
    diamond_with_indentation: 'T_GENERAL_PHOTO_MASK_DIAMOND_WITH_INDENTATION',
    window: 'T_GENERAL_PHOTO_MASK_WINDOW'
  },
  validate: ['required'],
}

const exhibitPositionType = {
  type: 'choice',
  choices: {
    right: 'T_GENERAL_PATH_EXHIBIT_POSITION_RIGHT',
    inside: 'T_GENERAL_PATH_EXHIBIT_POSITION_INSIDE',
    under: 'T_GENERAL_PATH_EXHIBIT_POSITION_UNDER',
  },
  validate: ['required'],
}

const exhibitSizeType = {
  type: 'choice',
  choices: {
    small: 'T_GENERAL_PATH_EXHIBIT_SIZE_SMALL',
    large: 'T_GENERAL_PATH_EXHIBIT_SIZE_LARGE',
    full: 'T_GENERAL_PATH_EXHIBIT_SIZE_FULL',
    full_decorative: 'T_GENERAL_PATH_EXHIBIT_SIZE_FULL_DECORATIVE',
  },
  validate: ['required'],
}

const recipeType = {
  type: 'resource',
  titleAccessor: `translations.${process.env.REACT_APP_LOCALE}.title`,
  // endpoint: `${recipeSchema.endpoint}?order[ord]=desc&pagination=false`,
}


export const types = {
  exhibit: exhibitType,
  exhibit_mask: exhibitMaskType,
  exhibit_position: exhibitPositionType,
  exhibit_size: exhibitSizeType,
  recipe: recipeType,
}
