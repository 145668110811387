import {
  text,
  textWithVideo,
  textWithAudio,
  packageFile,
  packageGallery,
} from 'core/_schema/_blocks'

const schema = (photoEndpoint, thumbEndpoint, fileEndpoint) => ({
  types: [
    text(),
    textWithVideo(photoEndpoint, thumbEndpoint),
    textWithAudio(fileEndpoint),
    packageFile(),
    packageGallery(),
  ],
})

export default schema
