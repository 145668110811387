import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import {
  Button,
  Tooltip,
  Typography,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
} from '@material-ui/core'
import MuiDialogTitle from '@material-ui/core/DialogTitle'
import { Close } from '@material-ui/icons'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import { Loader } from 'core/components/Loader'
import { fetchDataHandleAuthError } from 'core/_helpers/fetchDataHandleAuthError'
import { commonConstants } from 'core/_constants'
import { notification } from 'core/_helpers/notification'
import { translate } from 'core/_helpers/translate'

const useStyles = makeStyles(theme => ({
  delete: {
    cursor: 'pointer',
    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
  disabled: {
    color: theme.palette.disabled,
  },
  confirmText: {
    marginTop: 12,
  },
  reject_button: {
    background: theme.palette.error.main,
    '&:hover': {
      background: theme.palette.error.main,
    },
    '&:active': {
      background: theme.palette.error.main,
    },
  },
}))

const styles = () => ({
  root: {
    minHeight: 56,
  },
  closeButton: {
    position: 'absolute',
    right: 8,
    top: 4,
  },
})

const DialogTitle = withStyles(styles)(props => {
  const { children, classes, onClose, ...other } = props
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h4">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <Close />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  )
})

export const EmailButton = ({
  resource,
  isReject,
  buttonTitle,
  tooltipText,
  dialogTitle,
  dialogMessage
}) => {
  const [state, setState] = useState({
    isConfirmOpen: false,
    isFetching: false,
  })

  const handleConfirmToggle = () => {
    setState(state => ({
      ...state,
      isConfirmOpen: !state.isConfirmOpen,
    }))
  }

  const dispatch = useDispatch()

  const handleSend = () => {
    setState(state => ({
      ...state,
      isFetching: true,
    }))

    fetchDataHandleAuthError(
      resource['@id'],
      'PATCH',
      {
        body: JSON.stringify({
          [`send${isReject ? 'Reject' : 'Accept'}Email`]: true
        })
      },
      () => {
        setState({
          isConfirmOpen: false,
          isFetching: false,
        })

        notification('success', 'T_GENERAL_EMAIL_SENT', 'T_FORM_SUCCESS')

        dispatch({ type: commonConstants.INIT_RELOAD })
      },
      error => {
        setState({
          isConfirmOpen: false,
          isFetching: false,
        })

        notification('error', error.response.detail, error.response.title)
      }
    )
  }

  const classes = useStyles()

  return (
    <>
      <Tooltip title={translate(tooltipText)}>
        <Button
          variant="contained"
          color="primary"
          size="small"
          onClick={handleConfirmToggle}
          className={isReject && classes.reject_button}
        >
          {translate(buttonTitle)}
        </Button>
      </Tooltip>
      <Dialog open={state.isConfirmOpen} fullWidth={true} maxWidth="sm">
        <DialogTitle onClose={handleConfirmToggle}>
          {translate(dialogTitle)}
        </DialogTitle>
        <DialogContent dividers>
          {state.isFetching && <Loader align="center" marginBottom={15} />}
          <DialogContentText className={classes.confirmText}>
            {translate(dialogMessage)}{' '}
            {`${resource.translations[process.env.REACT_APP_LOCALE]?.title} (${
              resource.authorName
            })`}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleConfirmToggle}
            disabled={state.isFetching}
            autoFocus
            variant="outlined"
          >
            {translate('T_GENERAL_CANCEL')}
          </Button>
          <Button
            onClick={handleSend}
            disabled={state.isFetching}
            variant="contained"
            color="primary"
          >
            {translate('T_GENERAL_CONFIRM')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

EmailButton.propTypes = {
  resource: PropTypes.shape({
    '@id': PropTypes.string.isRequired,
  }).isRequired,
  isReject: PropTypes.bool.isRequired,
  buttonTitle: PropTypes.string.isRequired,
  tooltipText: PropTypes.string.isRequired,
  dialogTitle: PropTypes.string.isRequired,
  dialogMessage: PropTypes.string.isRequired,
}
