import { types } from './_path_common_types'

const schema = {
  endpoint: '/api/tradition_rules',
  resource: {
    definition: 'TraditionRules-traditionRules.read',
    sectionA: {
      properties: {
        translations: {
          type: 'translation',
          properties: {
            sectionATitle: {},
            sectionAText: {
              type: 'textarea'
            },
            sectionASubtextATitle: {},
            sectionASubtextAText: {
              type: 'textarea'
            },
            sectionAQuoteA: {
              type: 'textarea'
            },
            sectionAQuoteAAuthor: {},
            sectionAQuoteB: {
              type: 'textarea'
            },
            sectionAQuoteBAuthor: {},
            sectionAQuoteC: {
              type: 'textarea'
            },
            sectionAQuoteCAuthor: {},
          },
        },
        sectionAExhibitA: types.exhibit,
        sectionAExhibitASize: types.exhibit_size,
        sectionAExhibitAPosition: types.exhibit_position,
        sectionAExhibitAMask: types.exhibit_mask,

        sectionAExhibitB: types.exhibit,
        sectionAExhibitBSize: types.exhibit_size,
        sectionAExhibitBPosition: types.exhibit_position,
        sectionAExhibitBMask: types.exhibit_mask,

        sectionAStat: {}
      }
    },
    sectionB: {
      properties: {
        translations: {
          type: 'translation',
          properties: {
            sectionBTitle: {},
            sectionBSubtitle: {},
            sectionBText: {
              type: 'textarea'
            },
            sectionBOptionATitle: {},
            sectionBOptionAText: {
              type: 'textarea'
            },
            sectionBOptionBTitle: {},
            sectionBOptionBText: {
              type: 'textarea'
            },
            sectionBOptionCTitle: {},
            sectionBOptionCText: {
              type: 'textarea'
            },
            sectionBOptionDTitle: {},
            sectionBOptionDText: {
              type: 'textarea'
            },
            sectionBOptionETitle: {},
            sectionBOptionEText: {
              type: 'textarea'
            },
            sectionBOptionFTitle: {},
            sectionBOptionFText: {
              type: 'textarea'
            },
            sectionBSubtextATitle: {},
            sectionBSubtextAText: {
              type: 'textarea'
            },
            sectionBSubtextBTitle: {},
            sectionBSubtextBText: {
              type: 'textarea'
            },
            sectionBVideoTitle: {},
            sectionBVideoText: {
              type: 'textarea'
            }
          },
        },
        sectionBYoutube: {
          type: 'youtube'
        },
        sectionBStat: {}
      }
    },
    sectionC: {
      properties: {
        translations: {
          type: 'translation',
          properties: {
            sectionCTitle: {},
            sectionCSubtitle: {},
            sectionCText: {
              type: 'textarea'
            },
            sectionCSubtextATitle: {},
            sectionCSubtextAText: {
              type: 'textarea'
            },
            sectionCSubtextBTitle: {},
            sectionCSubtextBText: {
              type: 'textarea'
            },
            sectionCSubtextCTitle: {},
            sectionCSubtextCText: {
              type: 'textarea'
            },
            sectionCVideoTitle: {},
            sectionCVideoText: {
              type: 'textarea'
            }
          },
        },
        sectionCExhibitA: types.exhibit,
        sectionCExhibitASize: types.exhibit_size,
        sectionCExhibitAPosition: types.exhibit_position,
        sectionCExhibitAMask: types.exhibit_mask,

        sectionCExhibitB: types.exhibit,
        sectionCExhibitBSize: types.exhibit_size,
        sectionCExhibitBPosition: types.exhibit_position,
        sectionCExhibitBMask: types.exhibit_mask,

        sectionCExhibitC: types.exhibit,
        sectionCExhibitCSize: types.exhibit_size,
        sectionCExhibitCPosition: types.exhibit_position,
        sectionCExhibitCMask: types.exhibit_mask,

        sectionCExhibitD: types.exhibit,
        sectionCExhibitDSize: types.exhibit_size,
        sectionCExhibitDPosition: types.exhibit_position,
        sectionCExhibitDMask: types.exhibit_mask,

        sectionCExhibitE: types.exhibit,
        sectionCExhibitESize: types.exhibit_size,
        sectionCExhibitEPosition: types.exhibit_position,
        sectionCExhibitEMask: types.exhibit_mask,

        sectionCYoutube: {
          type: 'youtube'
        },
        sectionCStat: {}
      }
    },
  },
}

export default schema
