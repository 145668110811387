const LANGS = process.env.REACT_APP_RESOURCE_LANGS.split(',').map(lang => lang.trim())

export const handlePathSectionSuccess = (setState, properties) => resource => {
  setState(state => ({
    ...state,
    resource: {
      ...state.resource,
      '@id': resource['@id'],
      uuid: resource.uuid,
      ...Object.assign(
        {},
        ...Object.keys(properties).map(prop => prop === 'translations'
          ? {}
          : { [prop]: resource[prop] }
        )
      ),
      translations: Object.assign(
        {},
        ...LANGS.map(lang => ({ [lang]: {
          ...state.resource.translations?.[lang],
          '@id': resource.translations?.[lang]['@id'],
          uuid: resource.translations?.[lang].uuid,
          ...Object.assign(
            {},
            ...Object.keys(properties.translations.properties).map(prop => ({ [prop]: resource.translations[lang][prop] }))
          )
        }}))
      )
    }
  }))
}
