import {
  ViewListOutlined,
  PeopleOutlined,
  DescriptionOutlined,
  SettingsOutlined,
  LinkOutlined,
  MenuBookOutlined,
  MuseumOutlined,
  LooksOneOutlined,
  LooksTwoOutlined,
  Looks3Outlined,
  Looks4Outlined
} from '@material-ui/icons'
import { boolean } from 'core/_helpers/boolean'
import home from 'core/pages/Home/routes'
import admins from 'core/pages/Admins/routes'
import adminGroups from 'core/pages/AdminGroups/routes'
import loginEntries from 'core/pages/LoginEntries/routes'
import registryEntries from 'core/pages/RegistryEntries/routes'
import settings from 'core/pages/Settings/routes'
import profile from 'core/pages/Profile/routes'
import messenger from 'core/pages/Messenger/routes'
import pages from 'core/pages/Pages/routes'
import translatorEntries from 'core/pages/TranslatorEntries/routes'
import packageFiles from 'core/pages/PackageFiles/routes'
import packageGalleries from 'core/pages/PackageGalleries/routes'
import homeConfig from 'pages/HomeConfig/routes'
import traditionRules from 'pages/TraditionRules/routes'
import traditionThenAndNow from 'pages/TraditionThenAndNow/routes'
import traditionBlessings from 'pages/TraditionBlessings/routes'
import traditionHolidays from 'pages/TraditionHolidays/routes'
import traditionHolidaysThenAndNow from 'pages/TraditionHolidaysThenAndNow/routes'
import traditionTable from 'pages/TraditionTable/routes'
import diasporaManners from 'pages/DiasporaManners/routes'
import diasporaAshkenazi from 'pages/DiasporaAshkenazi/routes'
import diasporaAshkenaziTastes from 'pages/DiasporaAshkenaziTastes/routes'
import diasporaSephardic from 'pages/DiasporaSephardic/routes'
import diasporaSephardicTastes from 'pages/DiasporaSephardicTastes/routes'
import diasporaTable from 'pages/DiasporaTable/routes'
import modernityBooks from 'pages/ModernityBooks/routes'
import modernitySituations from 'pages/ModernitySituations/routes'
import modernityWars from 'pages/ModernityWars/routes'
import modernityMigrations from 'pages/ModernityMigrations/routes'
import modernityKitchen from 'pages/ModernityKitchen/routes'
import modernityPoland from 'pages/ModernityPoland/routes'
import modernityTable from 'pages/ModernityTable/routes'
import memoryStories from 'pages/MemoryStories/routes'
import memorySouvenirs from 'pages/MemorySouvenirs/routes'
import memoryTable from 'pages/MemoryTable/routes'
import polinRecipes from 'pages/PolinRecipes/routes'
import userRecipes from 'pages/UserRecipes/routes'
import recipeMealTypes from 'pages/RecipeMealTypes/routes'
import recipeDietTypes from 'pages/RecipeDietTypes/routes'
import recipeHolidayTypes from 'pages/RecipeHolidayTypes/routes'
import recipePreparationTimes from 'pages/RecipePreparationTimes/routes'
import exhibits from 'pages/Exhibits/routes'
import exhibitCategories from 'pages/ExhibitCategories/routes'
import exhibitSections from 'pages/ExhibitSections/routes'
import exhibitHolidayTypes from 'pages/ExhibitHolidayTypes/routes'
import exhibitStorageLocations from 'pages/ExhibitStorageLocations/routes'
import workshops from 'pages/Workshops/routes'
import menuLinks from 'pages/MenuLinks/routes'
import footerLinks from 'pages/FooterLinks/routes'
import config from 'pages/Config/routes'

const menu = () =>
  [
    home().index,
    homeConfig().edit,
    {
      title: 'T_PATHS_TRADITION',
      icon: LooksOneOutlined,
      items: [
        traditionRules().edit,
        traditionThenAndNow().edit,
        traditionBlessings().edit,
        traditionHolidays().edit,
        traditionHolidaysThenAndNow().edit,
        traditionTable().edit,
      ]
    },
    {
      title: 'T_PATHS_DIASPORA',
      icon: LooksTwoOutlined,
      items: [
        diasporaManners().edit,
        diasporaAshkenazi().edit,
        diasporaAshkenaziTastes().edit,
        diasporaSephardic().edit,
        diasporaSephardicTastes().edit,
        diasporaTable().edit,
      ]
    },
    {
      title: 'T_PATHS_MODERNITY',
      icon: Looks3Outlined,
      items: [
        modernityBooks().edit,
        modernitySituations().edit,
        modernityWars().edit,
        modernityMigrations().edit,
        modernityKitchen().edit,
        modernityPoland().edit,
        modernityTable().edit
      ]
    },
    {
      title: 'T_PATHS_MEMORY',
      icon: Looks4Outlined,
      items: [
        memoryStories().edit,
        memorySouvenirs().edit,
        memoryTable().edit,
      ]
    },
    pages().index,
    {
      title: 'T_MENU_RECIPES',
      icon: MenuBookOutlined,
      items: [
        polinRecipes().index,
        userRecipes().index,
        recipeMealTypes().index,
        recipeDietTypes().index,
        recipeHolidayTypes().index,
        recipePreparationTimes().index
      ]
    },
    {
      title: 'T_MENU_EXHIBITS',
      icon: MuseumOutlined,
      items: [
        exhibits().index,
        exhibitCategories().index,
        exhibitSections().index,
        exhibitHolidayTypes().index,
        exhibitStorageLocations().index
      ]
    },
    workshops().index,
    {
      title: 'T_MENU_PACKAGES',
      icon: ViewListOutlined,
      items: [packageFiles().index, packageGalleries().index],
    },
    {
      title: 'T_MENU_LINKS',
      icon: LinkOutlined,
      items: [menuLinks().index, footerLinks().index]
    },
    {
      title: 'Administrators',
      icon: PeopleOutlined,
      items: [admins().index, adminGroups().index],
    },
    {
      title: 'T_MENU_REGISTRIES',
      icon: DescriptionOutlined,
      items: [registryEntries().index, loginEntries().index],
    },
    {
      title: 'T_MODULE_SETTINGS',
      icon: SettingsOutlined,
      items: [config().edit, settings().index, profile().index],
    },
    translatorEntries().index,
  ].concat(
    boolean(process.env.REACT_APP_MESSENGER_SHOW) ? [messenger().index] : []
  )

export default menu
