import React, { useState, useMemo } from 'react'
import { BlankForm } from 'core/pages'
import { Paper } from 'core/components/Paper'
import { Loader } from 'core/components/Loader'
import { Title } from 'core/components/Title'
import { usePropertyResourcesFetch } from 'core/_helpers/usePropertyResourcesFetch'
import { usePathFetch } from '_helpers/usePathFetch'
import { handlePathSectionSuccess } from '_helpers/handlePathSectionSuccess'
import { usePathSectionStyles } from 'pages/usePathSectionStyles'
import { NotFound } from 'core/pages'
import { translate } from 'core/_helpers/translate'
import schema from '_schema/modernityKitchen'
import recipeSchema from '_schema/recipe'
import exhibitSchema from '_schema/exhibit'

export const Edit = ({ schema: definitions }) => {
  const [state, setState] = useState({
    isFetching: true,
    fetchError: false,
    resource: null,
  })

  usePathFetch(setState, schema.endpoint)

  const additionalResourcesMapping = useMemo(
    () => ({
      recipe: `${recipeSchema.endpoint}?order[ord]=desc`,
      exhibit: `${exhibitSchema.endpoint}?order[ord]=desc`,
    }),
    []
  )

  const [additionalResources] = usePropertyResourcesFetch(
    additionalResourcesMapping
  )

  const resourceSchema = useMemo(() => ({
    ...schema.resource,
    sectionA: {
      ...schema.sectionA,
      properties: Object.assign(
        {},
        ...Object.keys(schema.resource.sectionA.properties).map(
          prop => ({ [prop] : {
            ...schema.resource.sectionA.properties[prop],
            ...([
              'sectionAExhibitA',
              'sectionAExhibitB',
            ].includes(prop)
              ? { resources: additionalResources.exhibit }
              : {})
          }})
        )
      )
    },
    sectionB: {
      ...schema.sectionB,
      properties: Object.assign(
        {},
        ...Object.keys(schema.resource.sectionB.properties).map(
          prop => ({ [prop] : {
            ...schema.resource.sectionB.properties[prop],
            ...([
              'sectionBExhibitA',
              'sectionBExhibitB',
              'sectionBExhibitC',
              'sectionBExhibitD',
              'sectionBExhibitE',
              'sectionBExhibitF'
            ].includes(prop)
              ? { resources: additionalResources.exhibit }
              : prop === 'sectionBRecipe'
              ? { resources: additionalResources.recipe }
              : {})
          }})
        )
      )
    },
    sectionC: {
      ...schema.sectionC,
      properties: Object.assign(
        {},
        ...Object.keys(schema.resource.sectionC.properties).map(
          prop => ({ [prop] : {
            ...schema.resource.sectionC.properties[prop],
            ...([
              'sectionCExhibitA',
              'sectionCSliderAExhibitA',
              'sectionCSliderAExhibitB',
              'sectionCSliderAExhibitC',
              'sectionCSliderAExhibitD',
              'sectionCSliderAExhibitE',
              'sectionCSliderAExhibitF',
              'sectionCSliderAExhibitG',
              'sectionCSliderBExhibitA',
              'sectionCSliderBExhibitB',
              'sectionCSliderBExhibitC',
              'sectionCSliderBExhibitD',
              'sectionCSliderBExhibitE',
              'sectionCSliderBExhibitF',
              'sectionCSliderBExhibitG',
            ].includes(prop)
              ? { resources: additionalResources.exhibit }
              : {})
          }})
        )
      )
    },
    sectionD: {
      ...schema.sectionD,
      properties: Object.assign(
        {},
        ...Object.keys(schema.resource.sectionD.properties).map(
          prop => ({ [prop] : {
            ...schema.resource.sectionD.properties[prop],
            ...(prop === 'sectionDExhibitA'
              ? { resources: additionalResources.exhibit }
              : prop === 'sectionDRecipe'
              ? { resources: additionalResources.recipe }
              : {})
          }})
        )
      )
    },
  }), [additionalResources])

  const { isFetching, fetchError, resource } = state

  const classes = usePathSectionStyles()

  return isFetching ? (
    <Paper>
      <Loader />
    </Paper>
  ) : fetchError ? (
    <NotFound />
  ) : (
    <Paper>
      <div className={classes.section}>
        <Title className={classes.section_title}>
          {translate('T_PATHS_MODERNITY_KITCHEN_SECTION_A')}
        </Title>
        <BlankForm
          resource={resource}
          definitionSchema={definitions[schema.resource.definition]}
          customResourceSchema={resourceSchema.sectionA}
          method={resource['@id'] ? 'PUT' : 'POST'}
          url={resource['@id'] || schema.endpoint}
          handleSuccess={handlePathSectionSuccess(setState, schema.resource.sectionA.properties)}
          showSubmitAndStayButton={false}
          showCancelButton={false}
          onlyPassedProperties={true}
          fieldsFullWidth={false}
          buttonsFixed={false}
          width={700}
          key={`modernity-kitchen-section-a-${resource['@id'] || 'empty'}`}
        />
      </div>
      <div className={classes.section}>
        <Title className={classes.section_title}>
          {translate('T_PATHS_MODERNITY_KITCHEN_SECTION_B')}
        </Title>
        <BlankForm
          resource={resource}
          definitionSchema={definitions[schema.resource.definition]}
          customResourceSchema={resourceSchema.sectionB}
          method={resource['@id'] ? 'PUT' : 'POST'}
          url={resource['@id'] || schema.endpoint}
          handleSuccess={handlePathSectionSuccess(setState, schema.resource.sectionB.properties)}
          showSubmitAndStayButton={false}
          showCancelButton={false}
          onlyPassedProperties={true}
          fieldsFullWidth={false}
          buttonsFixed={false}
          width={700}
          key={`modernity-kitchen-section-b-${resource['@id'] || 'empty'}`}
        />
      </div>
      <div className={classes.section}>
        <Title className={classes.section_title}>
          {translate('T_PATHS_MODERNITY_KITCHEN_SECTION_C')}
        </Title>
        <BlankForm
          resource={resource}
          definitionSchema={definitions[schema.resource.definition]}
          customResourceSchema={resourceSchema.sectionC}
          method={resource['@id'] ? 'PUT' : 'POST'}
          url={resource['@id'] || schema.endpoint}
          handleSuccess={handlePathSectionSuccess(setState, schema.resource.sectionC.properties)}
          showSubmitAndStayButton={false}
          showCancelButton={false}
          onlyPassedProperties={true}
          fieldsFullWidth={false}
          buttonsFixed={false}
          width={700}
          key={`modernity-kitchen-section-c-${resource['@id'] || 'empty'}`}
        />
      </div>
      <div className={classes.section}>
        <Title className={classes.section_title}>
          {translate('T_PATHS_MODERNITY_KITCHEN_SECTION_D')}
        </Title>
        <BlankForm
          resource={resource}
          definitionSchema={definitions[schema.resource.definition]}
          customResourceSchema={resourceSchema.sectionD}
          method={resource['@id'] ? 'PUT' : 'POST'}
          url={resource['@id'] || schema.endpoint}
          handleSuccess={handlePathSectionSuccess(setState, schema.resource.sectionD.properties)}
          showSubmitAndStayButton={false}
          showCancelButton={false}
          onlyPassedProperties={true}
          fieldsFullWidth={false}
          buttonsFixed={false}
          width={700}
          key={`modernity-kitchen-section-d-${resource['@id'] || 'empty'}`}
        />
      </div>
    </Paper>
  )
}
