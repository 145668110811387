import React, { useState } from 'react'
import moment from 'moment'
import clsx from 'clsx'
import { Link } from 'react-router-dom'
import { Menu, MenuItem, IconButton } from '@material-ui/core'
import { MoreVertOutlined } from '@material-ui/icons'
import { makeStyles, withStyles  } from '@material-ui/core/styles'
import { EditLink } from 'core/components/resource/EditLink'
import { DeleteCell } from 'core/components/table/cells/DeleteCell'
import { StatusCell } from 'core/components/table/cells/StatusCell'
import { Timestamp } from 'core/components/resource/Timestamp'
import { EmailButton } from 'pages/UserRecipes/table/buttons/EmailButton'
import { translate } from 'core/_helpers/translate'
import recipeRatingRoutes from 'pages/RecipeRatings/routes'

const DATE_FORMAT = 'DD.MM.YYYY'

const useStyles = makeStyles({
  icons: {
    display: 'flex',
    alignItems: 'center',
  },
  menu_item: {
    fontSize: 14,
    '& svg': {
      fontSize: 20,
    },
  },
  list_item: {
    minWidth: 32,
  },
  menu_trigger: {
    borderRadius: 4,
    padding: 7,
  },
  menu_trigger_open: {
    border: '1px solid #d3d4d5',
    borderBottomColor: '#fff',
    backgroundColor: '#fff',
    borderBottomRightRadius: 0,
    borderBottomLeftRadius: 0,
  },
  email_buttons: {
    display: 'flex',
    marginTop: 10,
    '&> *:first-child': {
      marginRight: 10
    }
  },
  email_info: {
    marginTop: 10,
    color: '#777',
    fontSize: '.8em'
  },
  link: {
    width: '100%',
    textDecoration: 'none',
    color: 'inherit',
    '&:hover': {
      color: 'inherit',
    },
  }
})

const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',

    borderTopRightRadius: 0
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
));

export const operations = () => ({
  header: translate('T_GENERAL_OPERATIONS'),
  accessor: `translations.${process.env.REACT_APP_LOCALE}.title`,
  sortable: false,
  filterable: false,
  Cell: ({
    resource,
    accessor,
    tableState,
    tableStateDispatch,
    editPath = null,
  }) => {
    const classes = useStyles()
    const [menuAnchor, setMenuAnchor] = useState(null)

    const handleMenu = e => {
      setMenuAnchor(e.currentTarget)
    }

    const handleClose = () => {
      setMenuAnchor(null)
    }

    return (
      <>
        <div className={classes.icons}>
          <IconButton
            aria-label="record menu"
            aria-controls="record-menu"
            aria-haspopup="true"
            onClick={handleMenu}
            className={clsx( classes.menu_trigger,
              menuAnchor && classes.menu_trigger_open
            )}
          >
            <MoreVertOutlined color="inherit" />
          </IconButton>
          <StyledMenu
            id="record-menu"
            anchorEl={menuAnchor}
            keepMounted
            open={!!menuAnchor}
            onClose={handleClose}
          >
            <MenuItem className={classes.menu_item}>
              <EditLink
                resource={resource}
                accessor={accessor}
                editPath={editPath}
              />
            </MenuItem>
            <MenuItem className={classes.menu_item}>
              <Link to={recipeRatingRoutes().index_user.path.replace(
                ':id',
                resource[process.env.REACT_APP_RESOURCE_ID]
              )} className={classes.link}>
                {translate('T_MODULE_RECIPE_RATINGS')}
              </Link>
            </MenuItem>
            <MenuItem className={classes.menu_item}>
              <DeleteCell
                resource={resource}
                accessor={accessor}
                tableState={tableState}
                tableStateDispatch={tableStateDispatch}
              />
            </MenuItem>
          </StyledMenu>
          <StatusCell
            resource={resource}
            tableStateDispatch={tableStateDispatch}
          />
          <Timestamp resource={resource} />
        </div>
        <div className={classes.email_buttons}>
          <EmailButton
            resource={resource}
            isReject={false}
            buttonTitle="T_MODULE_RECIPE_USER_SEND_ACCEPT_EMAIL_BUTTON"
            tooltipText="T_MODULE_RECIPE_USER_SEND_ACCEPT_EMAIL_TOOLTIP"
            dialogTitle="T_MODULE_RECIPE_USER_SEND_ACCEPT_EMAIL_TITLE"
            dialogMessage="T_MODULE_RECIPE_USER_SEND_ACCEPT_EMAIL_TEXT"
          />
          <EmailButton
            resource={resource}
            isReject={true}
            buttonTitle="T_MODULE_RECIPE_USER_SEND_REJECT_EMAIL_BUTTON"
            tooltipText="T_MODULE_RECIPE_USER_SEND_REJECT_EMAIL_TOOLTIP"
            dialogTitle="T_MODULE_RECIPE_USER_SEND_REJECT_EMAIL_TITLE"
            dialogMessage="T_MODULE_RECIPE_USER_SEND_REJECT_EMAIL_TEXT"
          />
        </div>
        <div className={classes.email_info}>
          <div>
            {translate('T_MODULE_RECIPE_USER_ACCEPTED_AT')}: {
              resource.acceptEmailSentAt && moment(resource.acceptEmailSentAt).format(DATE_FORMAT)
            }
          </div>
          <div>
            {translate('T_MODULE_RECIPE_USER_REJECTED_AT')}: {
              resource.rejectEmailSentAt && moment(resource.rejectEmailSentAt).format(DATE_FORMAT)
            }
          </div>
        </div>
      </>
    )
  },
})
