import { types } from './_path_common_types'

const schema = {
  endpoint: '/api/home_configs',
  resource: {
    definition: 'HomeConfig-homeConfig.read',
    sectionA: {
      properties: {
        translations: {
          type: 'translation',
          properties: {
            sectionASuperscription: {},
            sectionATitle: {},
            sectionAText: {
              type: 'textarea'
            }
          },
        },
      }
    },
    sectionB: {
      properties: {
        translations: {
          type: 'translation',
          properties: {
            sectionBSuperscription: {},
            sectionBTitle: {},
            sectionBText: {
              type: 'textarea'
            }
          },
        },
        sectionBExhibitA: types.exhibit,
        sectionBExhibitAMask: types.exhibit_mask,

        sectionBExhibitB: types.exhibit,
        sectionBExhibitBMask: types.exhibit_mask,
      }
    },
    sectionC: {
      properties: {
        translations: {
          type: 'translation',
          properties: {
            sectionCQuote: {
              type: 'textarea'
            },
            sectionCQuoteAuthor: {},
            sectionCRulesTitle: {},
            sectionCRulesText: {
              type: 'textarea'
            }
          },
        },
      }
    },
    sectionD: {
      properties: {
        translations: {
          type: 'translation',
          properties: {
            sectionDSuperscription: {
              type: 'textarea',
            },
            sectionDTitle: {},
            sectionDText: {
              type: 'textarea'
            },
            sectionDKitchenATitle: {},
            sectionDKitchenAText: {
              type: 'textarea'
            },
            sectionDKitchenBTitle: {},
            sectionDKitchenBText: {
              type: 'textarea'
            }
          },
        },
        sectionDKitchenARecipeA: types.recipe,
        sectionDKitchenARecipeB: types.recipe,
        sectionDKitchenARecipeC: types.recipe,

        sectionDKitchenBRecipeA: types.recipe,
        sectionDKitchenBRecipeB: types.recipe,
        sectionDKitchenBRecipeC: types.recipe,
      }
    },
    sectionE: {
      properties: {
        translations: {
          type: 'translation',
          properties: {
            sectionESuperscription: {
              type: 'textarea'
            },
            sectionETitle: {
              type: 'textarea'
            },
            sectionEText: {
              type: 'textarea'
            }
          },
        },
        sectionEExhibitA: types.exhibit,
        sectionEExhibitAMask: types.exhibit_mask,

        sectionEExhibitB: types.exhibit,
        sectionEExhibitBMask: types.exhibit_mask,

        sectionEExhibitC: types.exhibit,
        sectionEExhibitCMask: types.exhibit_mask,
      }
    },
    sectionF: {
      properties: {
        translations: {
          type: 'translation',
          properties: {
            sectionFTitle: {},
            sectionFText: {
              type: 'textarea'
            },
            sectionFCountryATitle: {},
            sectionFCountryAText: {
              type: 'textarea'
            },
            sectionFCountryBTitle: {},
            sectionFCountryBText: {
              type: 'textarea'
            },
            sectionFCountryCTitle: {},
            sectionFCountryCText: {
              type: 'textarea'
            }
          },
        },
        sectionFCountryAExhibitA: types.exhibit,
        sectionFCountryAExhibitB: types.exhibit,
        sectionFCountryAExhibitC: types.exhibit,

        sectionFCountryBExhibitA: types.exhibit,
        sectionFCountryBExhibitB: types.exhibit,
        sectionFCountryBExhibitC: types.exhibit,

        sectionFCountryCExhibitA: types.exhibit,
        sectionFCountryCExhibitB: types.exhibit,
        sectionFCountryCExhibitC: types.exhibit,
      }
    },
    sectionG: {
      properties: {
        translations: {
          type: 'translation',
          properties: {
            sectionGSuperscription: {},
            sectionGTitle: {},
            sectionGText: {
              type: 'textarea'
            },
            sectionGVideoTitle: {},
            sectionGVideoText: {
              type: 'textarea'
            },
            sectionGQuote: {
              type: 'textarea'
            },
            sectionGQuoteAuthor: {}
          },
        },
        sectionGExhibitA: types.exhibit,
        sectionGExhibitAMask: types.exhibit_mask,

        sectionGExhibitB: types.exhibit,
        sectionGExhibitBMask: types.exhibit_mask,
      }
    },
  },
}

export default schema
