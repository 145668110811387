import React from 'react'
import { Route } from 'react-router-dom'
import { LooksOutlined } from '@material-ui/icons'
import { SchemableComponent } from 'core/components/SchemableComponent'
import { Edit } from './Edit'
import { addRoutesConfig } from 'core/_helpers/addRoutesConfig'

const basePath = '/memory_table'

const routes = () =>
  addRoutesConfig(
    {
      edit: {
        subtitle: 'T_GENERAL_RECORD_EDIT',
        type: Route,
        render: props => (
          <SchemableComponent path="definitions" component={Edit} {...props} />
        ),
        path: basePath,
        exact: true,
        icon: LooksOutlined,
      },
    },
    {
      title: 'Memory table',
      resourceAccess: '/api/paths',
    }
  )

export default routes
