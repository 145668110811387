import React, { useState, useEffect, useMemo } from 'react'
import { makeStyles } from '@material-ui/styles'
import { BlankForm } from 'core/pages'
import { Paper } from 'core/components/Paper'
import { Loader } from 'core/components/Loader'
import { Title } from 'core/components/Title'
import { usePropertyResourcesFetch } from 'core/_helpers/usePropertyResourcesFetch'
import { fetchDataHandleAuthError } from 'core/_helpers/fetchDataHandleAuthError'
import { notification } from 'core/_helpers/notification'
import { NotFound } from 'core/pages'
import { translate } from 'core/_helpers/translate'
import schema from '_schema/homeConfig'
import recipeSchema from '_schema/recipe'
import exhibitSchema from '_schema/exhibit'

const LANGS = process.env.REACT_APP_RESOURCE_LANGS.split(',').map(lang => lang.trim())

const useStyles = makeStyles({
  section: {
    width: 700,
    marginBottom: 50,
    paddingBottom: 30,
    borderBottom: '1px solid #ddd',
    '&:last-child': {
      marginBottom: 0,
      paddingBottom: 0,
      borderBottom: 'none'
    }
  },
  section_title: {
    marginBottom: 20
  }
})

export const Edit = ({ schema: definitions }) => {
  const [state, setState] = useState({
    isFetching: true,
    fetchError: false,
    resource: null,
  })

  useEffect(() => {
    const controller = new AbortController()
    const { signal } = controller

    fetchDataHandleAuthError(
      schema.endpoint,
      'GET',
      { signal },
      response => {
        setState({
          isFetching: false,
          resource: response['hydra:member'].length
            ? response['hydra:member'][0]
            : {},
        })
      },
      error => {
        if (error.response.title === 'AbortError') {
          return
        }

        notification('error', error.response.detail, error.response.title)
        setState({
          isFetching: false,
          fetchError: true,
        })
      }
    )

    return () => controller.abort()
  }, [])

  const handleSuccess = properties => resource => {
    setState(state => ({
      ...state,
      resource: {
        ...state.resource,
        ...Object.assign(
          {},
          ...Object.keys(properties).map(prop => prop === 'translations'
            ? {}
            : { [prop]: resource[prop] }
          )
        ),
        translations: Object.assign(
          {},
          ...LANGS.map(lang => ({ [lang]: {
            ...state.resource.translations?.[lang],
            ...Object.assign(
              {},
              ...Object.keys(properties.translations.properties).map(prop => ({ [prop]: resource.translations[lang][prop] }))
            )
          }}))
        )
      }
    }))
  }

  const additionalResourcesMapping = useMemo(
    () => ({
      recipe: `${recipeSchema.endpoint}?order[ord]=desc`,
      exhibit: `${exhibitSchema.endpoint}?order[ord]=desc`,
    }),
    []
  )

  const [additionalResources] = usePropertyResourcesFetch(
    additionalResourcesMapping
  )

  const resourceSchema = useMemo(() => ({
    ...schema.resource,
    sectionB: {
      ...schema.sectionB,
      properties: Object.assign(
        {},
        ...Object.keys(schema.resource.sectionB.properties).map(
          prop => ({ [prop] : {
            ...schema.resource.sectionB.properties[prop],
            ...(['sectionBExhibitA', 'sectionBExhibitB'].includes(prop)
              ? { resources: additionalResources.exhibit }
              : {})
          }})
        )
      )
    },
    sectionD: {
      ...schema.sectionD,
      properties: Object.assign(
        {},
        ...Object.keys(schema.resource.sectionD.properties).map(
          prop => ({ [prop] : {
            ...schema.resource.sectionD.properties[prop],
            ...([
              'sectionDKitchenARecipeA',
              'sectionDKitchenARecipeB',
              'sectionDKitchenARecipeC',
              'sectionDKitchenBRecipeA',
              'sectionDKitchenBRecipeB',
              'sectionDKitchenBRecipeC'
            ].includes(prop)
              ? { resources: additionalResources.recipe }
              : {})
          }})
        )
      )
    },
    sectionE: {
      ...schema.sectionE,
      properties: Object.assign(
        {},
        ...Object.keys(schema.resource.sectionE.properties).map(
          prop => ({ [prop] : {
            ...schema.resource.sectionE.properties[prop],
            ...([
              'sectionEExhibitA',
              'sectionEExhibitB',
              'sectionEExhibitC'
            ].includes(prop)
              ? { resources: additionalResources.exhibit }
              : {})
          }})
        )
      )
    },
    sectionF: {
      ...schema.sectionF,
      properties: Object.assign(
        {},
        ...Object.keys(schema.resource.sectionF.properties).map(
          prop => ({ [prop] : {
            ...schema.resource.sectionF.properties[prop],
            ...([
              'sectionFCountryAExhibitA',
              'sectionFCountryAExhibitB',
              'sectionFCountryAExhibitC',
              'sectionFCountryBExhibitA',
              'sectionFCountryBExhibitB',
              'sectionFCountryBExhibitC',
              'sectionFCountryCExhibitA',
              'sectionFCountryCExhibitB',
              'sectionFCountryCExhibitC',
            ].includes(prop)
              ? { resources: additionalResources.exhibit }
              : {})
          }})
        )
      )
    },
    sectionG: {
      ...schema.sectionG,
      properties: Object.assign(
        {},
        ...Object.keys(schema.resource.sectionG.properties).map(
          prop => ({ [prop] : {
            ...schema.resource.sectionG.properties[prop],
            ...([
              'sectionGExhibitA',
              'sectionGExhibitB',
            ].includes(prop)
              ? { resources: additionalResources.exhibit }
              : {})
          }})
        )
      )
    }
  }), [additionalResources])

  const { isFetching, fetchError, resource } = state

  const classes = useStyles()

  return isFetching ? (
    <Paper>
      <Loader />
    </Paper>
  ) : fetchError ? (
    <NotFound />
  ) : (
    <Paper>
      <div className={classes.section}>
        <Title className={classes.section_title}>
          {translate('T_MODULE_HOME_CONFIG_SECTION_A')}
        </Title>
        <BlankForm
          resource={resource}
          definitionSchema={definitions[schema.resource.definition]}
          customResourceSchema={resourceSchema.sectionA}
          method={resource['@id'] ? 'PUT' : 'POST'}
          url={resource['@id'] || schema.endpoint}
          handleSuccess={handleSuccess(schema.resource.sectionA.properties)}
          showSubmitAndStayButton={false}
          showCancelButton={false}
          onlyPassedProperties={true}
          fieldsFullWidth={false}
          buttonsFixed={false}
          width={700}
          key="home-config-section-a"
        />
      </div>
      <div className={classes.section}>
        <Title className={classes.section_title}>
          {translate('T_MODULE_HOME_CONFIG_SECTION_B')}
        </Title>
        <BlankForm
          resource={resource}
          definitionSchema={definitions[schema.resource.definition]}
          customResourceSchema={resourceSchema.sectionB}
          method={resource['@id'] ? 'PUT' : 'POST'}
          url={resource['@id'] || schema.endpoint}
          handleSuccess={handleSuccess(schema.resource.sectionB.properties)}
          showSubmitAndStayButton={false}
          showCancelButton={false}
          onlyPassedProperties={true}
          fieldsFullWidth={false}
          buttonsFixed={false}
          width={700}
          key="home-config-section-b"
        />
      </div>
      <div className={classes.section}>
        <Title className={classes.section_title}>
          {translate('T_MODULE_HOME_CONFIG_SECTION_C')}
        </Title>
        <BlankForm
          resource={resource}
          definitionSchema={definitions[schema.resource.definition]}
          customResourceSchema={resourceSchema.sectionC}
          method={resource['@id'] ? 'PUT' : 'POST'}
          url={resource['@id'] || schema.endpoint}
          handleSuccess={handleSuccess(schema.resource.sectionC.properties)}
          showSubmitAndStayButton={false}
          showCancelButton={false}
          onlyPassedProperties={true}
          fieldsFullWidth={false}
          buttonsFixed={false}
          width={700}
          key="home-config-section-c"
        />
      </div>
      <div className={classes.section}>
        <Title className={classes.section_title}>
          {translate('T_MODULE_HOME_CONFIG_SECTION_D')}
        </Title>
        <BlankForm
          resource={resource}
          definitionSchema={definitions[schema.resource.definition]}
          customResourceSchema={resourceSchema.sectionD}
          method={resource['@id'] ? 'PUT' : 'POST'}
          url={resource['@id'] || schema.endpoint}
          handleSuccess={handleSuccess(schema.resource.sectionD.properties)}
          showSubmitAndStayButton={false}
          showCancelButton={false}
          onlyPassedProperties={true}
          fieldsFullWidth={false}
          buttonsFixed={false}
          width={700}
          key="home-config-section-d"
        />
      </div>
      <div className={classes.section}>
        <Title className={classes.section_title}>
          {translate('T_MODULE_HOME_CONFIG_SECTION_E')}
        </Title>
        <BlankForm
          resource={resource}
          definitionSchema={definitions[schema.resource.definition]}
          customResourceSchema={resourceSchema.sectionE}
          method={resource['@id'] ? 'PUT' : 'POST'}
          url={resource['@id'] || schema.endpoint}
          handleSuccess={handleSuccess(schema.resource.sectionE.properties)}
          showSubmitAndStayButton={false}
          showCancelButton={false}
          onlyPassedProperties={true}
          fieldsFullWidth={false}
          buttonsFixed={false}
          width={700}
          key="home-config-section-e"
        />
      </div>
      <div className={classes.section}>
        <Title className={classes.section_title}>
          {translate('T_MODULE_HOME_CONFIG_SECTION_F')}
        </Title>
        <BlankForm
          resource={resource}
          definitionSchema={definitions[schema.resource.definition]}
          customResourceSchema={resourceSchema.sectionF}
          method={resource['@id'] ? 'PUT' : 'POST'}
          url={resource['@id'] || schema.endpoint}
          handleSuccess={handleSuccess(schema.resource.sectionF.properties)}
          showSubmitAndStayButton={false}
          showCancelButton={false}
          onlyPassedProperties={true}
          fieldsFullWidth={false}
          buttonsFixed={false}
          width={700}
          key="home-config-section-f"
        />
      </div>
      <div className={classes.section}>
        <Title className={classes.section_title}>
          {translate('T_MODULE_HOME_CONFIG_SECTION_G')}
        </Title>
        <BlankForm
          resource={resource}
          definitionSchema={definitions[schema.resource.definition]}
          customResourceSchema={resourceSchema.sectionG}
          method={resource['@id'] ? 'PUT' : 'POST'}
          url={resource['@id'] || schema.endpoint}
          handleSuccess={handleSuccess(schema.resource.sectionG.properties)}
          showSubmitAndStayButton={false}
          showCancelButton={false}
          onlyPassedProperties={true}
          fieldsFullWidth={false}
          buttonsFixed={false}
          width={700}
          key="home-config-section-g"
        />
      </div>
    </Paper>
  )
}
