import photoSchema from 'core/_schema/photo'
import recipeMealTypeSchema from '_schema/recipeMealType'
import recipeDietTypeSchema from '_schema/recipeDietType'
import recipeHolidayTypeSchema from '_schema/recipeHolidayType'
import recipePreparationTimeSchema from '_schema/recipePreparationTime'

const schema = {
  endpoint: '/api/recipes',
  polin_resource: {
    definition: 'Recipe-recipe.read',
    properties: {
      translations: {
        type: 'translation',
        properties: {
          title: {},
          subtitle: {},
          description: {
            type: 'textarea'
          },
          introduction: {
            type: 'textarea'
          },
          numberOfServings: {},
          photoAlt: {},
          source: {
            type: 'textarea'
          },
          ingredientListATitle: {},
          ingredientListAComment: {},
          ingredientListBTitle: {},
          ingredientListBComment: {},
          ingredientListCTitle: {},
          ingredientListCComment: {},
          metaTitle: {},
          metaDescription: {
            type: 'textarea',
          },
          metaKeywords: {
            type: 'textarea',
          },
          slug: {
            type: 'slug',
            routes: {
              pl: '/przepisy/:slug',
              en: '/en/recipes/:slug',
              he: '/he/recipes/:slug'
            },
          },
        },
      },
      hasQualityMark: {},
      mealTypes: {
        type: 'multipleResource',
        titleAccessor: `translations.${process.env.REACT_APP_LOCALE}.title`,
        endpoint: `${recipeMealTypeSchema.endpoint}?order[ord]=desc&pagination=false`,
      },
      dietTypes: {
        type: 'multipleResource',
        titleAccessor: `translations.${process.env.REACT_APP_LOCALE}.title`,
        endpoint: `${recipeDietTypeSchema.endpoint}?order[ord]=desc&pagination=false`,
      },
      holidayType: {
        type: 'resource',
        titleAccessor: `translations.${process.env.REACT_APP_LOCALE}.title`,
        endpoint: `${recipeHolidayTypeSchema.endpoint}?order[ord]=desc&pagination=false`,
      },
      preparationTime: {
        type: 'resource',
        titleAccessor: `translations.${process.env.REACT_APP_LOCALE}.title`,
        endpoint: `${recipePreparationTimeSchema.endpoint}?order[ord]=desc&pagination=false`,
      },
      photo: {
        type: 'image',
        endpoint: photoSchema.endpoint.single,
        validate: ['maxSize'],
        thumbs: {
          endpoint: '/api/recipe_thumbs'
        }
      },
      linkedRecipes: {
        type: 'multipleResource',
        endpoint: '/api/recipes?type=polin&order[ord]=desc&pagination=false',
        titleAccessor: `translations.${process.env.REACT_APP_LOCALE}.title`,
      },
      stat: {
        description: 'T_GENERAL_PUBLISH',
      },
    },
  },
  user_resource: {
    definition: 'Recipe-recipe.read',
    properties: {
      translations: {
        type: 'translation',
        properties: {
          title: {},
          subtitle: {},
          description: {
            type: 'textarea'
          },
          introduction: {
            type: 'textarea'
          },
          numberOfServings: {},
          photoAlt: {},
          metaTitle: {},
          metaDescription: {
            type: 'textarea',
          },
          metaKeywords: {
            type: 'textarea',
          },
          slug: {
            type: 'slug',
            routes: {
              pl: '/przepisy/:slug',
              en: '/en/recipes/:slug',
              he: '/he/recipes/:slug'
            },
          },
        },
      },
      hasQualityMark: {},
      mealTypes: {
        type: 'multipleResource',
        titleAccessor: `translations.${process.env.REACT_APP_LOCALE}.title`,
        endpoint: `${recipeMealTypeSchema.endpoint}?order[ord]=desc&pagination=false`,
      },
      dietTypes: {
        type: 'multipleResource',
        titleAccessor: `translations.${process.env.REACT_APP_LOCALE}.title`,
        endpoint: `${recipeDietTypeSchema.endpoint}?order[ord]=desc&pagination=false`,
      },
      holidayType: {
        type: 'resource',
        titleAccessor: `translations.${process.env.REACT_APP_LOCALE}.title`,
        endpoint: `${recipeHolidayTypeSchema.endpoint}?order[ord]=desc&pagination=false`,
      },
      preparationTime: {
        type: 'resource',
        titleAccessor: 'title',
        endpoint: `${recipePreparationTimeSchema.endpoint}?order[ord]=desc&pagination=false`,
      },
      authorName: {},
      authorEmail: {},
      photo: {
        type: 'image',
        endpoint: photoSchema.endpoint.single,
        validate: ['maxSize'],
        thumbs: {
          endpoint: '/api/recipe_thumbs'
        }
      },
      linkedRecipes: {
        type: 'multipleResource',
        endpoint: '/api/recipes?type=user&order[createdAt]=desc&pagination=false',
        titleAccessor: `translations.${process.env.REACT_APP_LOCALE}.title`,
      },
    },
  },
  subresources: {
    ingredient: {
      endpoint: '/api/recipe_ingredients',
      definition: 'RecipeIngredient-recipeIngredient.read',
      properties: {
        translations: {
          type: 'translation',
          properties: {
            title: {
              type: 'tinymce'
            },
          },
        },
        list: {
          type: 'choice',
          choices: {
            a: 'T_MODULE_RECIPE_INGREDIENT_LIST_A',
            b: 'T_MODULE_RECIPE_INGREDIENT_LIST_B',
            c: 'T_MODULE_RECIPE_INGREDIENT_LIST_C',
          },
          validate: ['required'],
        },
        stat: {
          description: 'T_GENERAL_PUBLISH',
        },
      },
    },
    step: {
      endpoint: '/api/recipe_steps',
      definition: 'RecipeStep-recipeStep.read',
      properties: {
        translations: {
          type: 'translation',
          properties: {
            title: {
              type: 'tinymce'
            },
            photoAlt: {},
          },
        },
        photo: {
          type: 'image',
          endpoint: photoSchema.endpoint.single,
          validate: ['maxSize'],
          // thumbs: {
          //   endpoint: '/api/recipe_step_thumbs'
          // }
        },
        stat: {
          description: 'T_GENERAL_PUBLISH',
        },
      },
    },
    photo: {
      endpoint: '/api/recipe_photos'
    },
    rating: {
      endpoint: '/api/recipe_ratings',
      definition: 'RecipeRating-recipeRating.read',
      properties: {
        title: {},
        rating: {}
      }
    }
  }
}

export default schema
