import { types } from './_path_common_types'

const schema = {
  endpoint: '/api/tradition_holidays_then_and_nows',
  resource: {
    definition: 'TraditionHolidaysThenAndNow-traditionHolidaysThenAndNow.read',
    sectionA: {
      properties: {
        translations: {
          type: 'translation',
          properties: {
            sectionATitle: {},
            sectionAText: {
              type: 'textarea'
            },
            sectionASubtextATitle: {},
            sectionASubtextAText: {
              type: 'textarea'
            },
          }
        },
        sectionARecipe: types.recipe,

        sectionAExhibitA: types.exhibit,
        sectionAExhibitASize: types.exhibit_size,
        sectionAExhibitAPosition: types.exhibit_position,
        sectionAExhibitAMask: types.exhibit_mask,

        sectionAExhibitB: types.exhibit,
        sectionAExhibitBSize: types.exhibit_size,
        sectionAExhibitBPosition: types.exhibit_position,
        sectionAExhibitBMask: types.exhibit_mask,

        sectionAExhibitC: types.exhibit,
        sectionAExhibitCSize: types.exhibit_size,
        sectionAExhibitCPosition: types.exhibit_position,
        sectionAExhibitCMask: types.exhibit_mask,

        sectionAExhibitD: types.exhibit,
        sectionAExhibitDSize: types.exhibit_size,
        sectionAExhibitDPosition: types.exhibit_position,
        sectionAExhibitDMask: types.exhibit_mask,

        sectionAExhibitE: types.exhibit,
        sectionAExhibitESize: types.exhibit_size,
        sectionAExhibitEPosition: types.exhibit_position,
        sectionAExhibitEMask: types.exhibit_mask,

        sectionAStat: {}
      }
    },
    sectionB: {
      properties: {
        translations: {
          type: 'translation',
          properties: {
            sectionBSubtextATitle: {},
            sectionBSubtextAText: {
              type: 'textarea'
            },
          },
        },
        sectionBExhibitA: types.exhibit,
        sectionBExhibitASize: types.exhibit_size,
        sectionBExhibitAPosition: types.exhibit_position,
        sectionBExhibitAMask: types.exhibit_mask,

        sectionBExhibitB: types.exhibit,
        sectionBExhibitBMask: types.exhibit_mask,

        sectionBExhibitC: types.exhibit,
        sectionBExhibitCMask: types.exhibit_mask,

        sectionBExhibitD: types.exhibit,
        sectionBExhibitDMask: types.exhibit_mask,

        sectionBExhibitE: types.exhibit,
        sectionBExhibitEMask: types.exhibit_mask,

        sectionBExhibitF: types.exhibit,
        sectionBExhibitFMask: types.exhibit_mask,

        sectionBExhibitG: types.exhibit,
        sectionBExhibitGMask: types.exhibit_mask,

        sectionBExhibitH: types.exhibit,
        sectionBExhibitHMask: types.exhibit_mask,

        sectionBStat: {}
      }
    },
    sectionC: {
      properties: {
        translations: {
          type: 'translation',
          properties: {
            sectionCOptionATitle: {},
            sectionCOptionBTitle: {},
            sectionCOptionCTitle: {},
            sectionCOptionDTitle: {},
            sectionCOptionETitle: {},
            sectionCOptionFTitle: {},
          },
        },
        sectionCOptionAExhibitA: types.exhibit,
        sectionCOptionAExhibitAMask: types.exhibit_mask,

        sectionCOptionAExhibitB: types.exhibit,
        sectionCOptionAExhibitBMask: types.exhibit_mask,

        sectionCOptionBExhibitA: types.exhibit,
        sectionCOptionBExhibitAMask: types.exhibit_mask,

        sectionCOptionBExhibitB: types.exhibit,
        sectionCOptionBExhibitBMask: types.exhibit_mask,

        sectionCOptionCExhibitA: types.exhibit,
        sectionCOptionCExhibitAMask: types.exhibit_mask,

        sectionCOptionCExhibitB: types.exhibit,
        sectionCOptionCExhibitBMask: types.exhibit_mask,

        sectionCOptionDExhibitA: types.exhibit,
        sectionCOptionDExhibitAMask: types.exhibit_mask,

        sectionCOptionDExhibitB: types.exhibit,
        sectionCOptionDExhibitBMask: types.exhibit_mask,

        sectionCOptionEExhibitA: types.exhibit,
        sectionCOptionEExhibitAMask: types.exhibit_mask,

        sectionCOptionEExhibitB: types.exhibit,
        sectionCOptionEExhibitBMask: types.exhibit_mask,

        sectionCOptionFExhibitA: types.exhibit,
        sectionCOptionFExhibitAMask: types.exhibit_mask,

        sectionCOptionFExhibitB: types.exhibit,
        sectionCOptionFExhibitBMask: types.exhibit_mask,

        sectionCStat: {}
      }
    },
  },
}

export default schema
