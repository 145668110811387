import _block from './_block'
import photoSchema from './photo'
import fileSchema from './file'

const schema = {
  endpoint: '/api/pages',
  resource: {
    definition: 'Page-page.read',
    properties: {
      translations: {
        type: 'translation',
        properties: {
          title: {},
          subtitle: {},
          text: {
            type: 'tinymce',
          },
          photoAlt: {},
          metaTitle: {},
          metaDescription: {
            type: 'textarea',
          },
          metaKeywords: {
            type: 'textarea',
          },
          slug: {
            type: 'slug',
            routes: {
              pl: '/:slug',
              en: '/en/:slug',
              he: '/he/:slug'
            },
          },
        },
      },
      type: {
        type: 'media[type]',
      },
      youtube: {
        type: 'media[youtube]',
        description: 'T_GENERAL_YOUTUBE_CODE',
      },
      photo: {
        type: 'media[image]',
        description: 'T_GENERAL_MEDIA_PHOTO',
        endpoint: photoSchema.endpoint.single,
        endpointYoutube: photoSchema.endpoint.youtube,
        thumbs: {
          endpoint: '/api/page_thumbs',
        },
        validate: ['maxSize'],
        ytProperty: 'youtube',
      },
    },
  },
  subresources: {
    block: {
      endpoint: '/api/page_blocks',
      definition: 'PageBlock-pageBlock.read',
      types: _block(
        photoSchema.endpoint.single,
        '/api/page_block_thumbs',
        fileSchema.endpoint.single
      ).types,
    },
  },
}

export default schema
