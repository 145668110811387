const schema = {
  endpoint: '/api/menu_links',
  resource: {
    definition: 'MenuLink-menuLink.read',
    properties: {
      translations: {
        type: 'translation',
        properties: {
          title: {},
          link: {},
        },
      },
      col: {
        type: 'choice',
        choices: {
          top: 'T_MODULE_MENU_LINK_COL_TOP',
          left: 'T_MODULE_MENU_LINK_COL_LEFT',
          right: 'T_MODULE_MENU_LINK_COL_RIGHT',
        },
        validate: ['required'],
      },
      isExternal: {},
      stat: {
        description: 'T_GENERAL_PUBLISH',
      },
    },
  },
}

export default schema
