export const textWithAudio = fileEndpoint => ({
  name: 'textWithAudio',
  label: 'T_GENERAL_BLOCK_TYPE_TEXT_WITH_AUDIO',
  properties: {
    translations: {
      type: 'translation',
      properties: {
        title: {},
        text: {
          type: 'tinymce',
        },
      },
    },
    file: {
      type: 'file',
      description: 'T_GENERAL_AUDIO',
      endpoint: fileEndpoint,
      accept: 'audio/*',
      validate: ['maxSize'],
    },
  },
})
