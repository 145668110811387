import { types } from './_path_common_types'

const schema = {
  endpoint: '/api/tradition_then_and_nows',
  resource: {
    definition: 'TraditionThenAndNow-traditionThenAndNow.read',
    sectionA: {
      properties: {
        translations: {
          type: 'translation',
          properties: {
            sectionATitle: {},
            sectionAText: {
              type: 'textarea'
            },
          }
        },
        sectionAExhibitA: types.exhibit,
        sectionAExhibitAMask: types.exhibit_mask,

        sectionAExhibitB: types.exhibit,
        sectionAExhibitBMask: types.exhibit_mask,

        sectionAExhibitC: types.exhibit,
        sectionAExhibitCMask: types.exhibit_mask,

        sectionAExhibitD: types.exhibit,
        sectionAExhibitDMask: types.exhibit_mask,

        sectionAStat: {}
      }
    },
    sectionB: {
      properties: {
        translations: {
          type: 'translation',
          properties: {
            sectionBTitle: {},
            sectionBText: {
              type: 'textarea'
            },
            sectionBSliderAText: {},
            sectionBSliderASubtitle: {
              type: 'textarea'
            },
            sectionBSliderBText: {},
            sectionBSliderBSubtitle: {
              type: 'textarea'
            },
          },
        },
        sectionBRecipe: types.recipe,

        sectionBExhibitA: types.exhibit,
        sectionBExhibitASize: types.exhibit_size,
        sectionBExhibitAPosition: types.exhibit_position,
        sectionBExhibitAMask: types.exhibit_mask,

        sectionBSliderAExhibitA: types.exhibit,
        sectionBSliderAExhibitAMask: types.exhibit_mask,

        sectionBSliderAExhibitB: types.exhibit,
        sectionBSliderAExhibitBMask: types.exhibit_mask,

        sectionBSliderAExhibitC: types.exhibit,
        sectionBSliderAExhibitCMask: types.exhibit_mask,

        sectionBSliderAExhibitD: types.exhibit,
        sectionBSliderAExhibitDMask: types.exhibit_mask,

        sectionBSliderAExhibitE: types.exhibit,
        sectionBSliderAExhibitEMask: types.exhibit_mask,

        sectionBSliderAExhibitF: types.exhibit,
        sectionBSliderAExhibitFMask: types.exhibit_mask,

        sectionBSliderAExhibitG: types.exhibit,
        sectionBSliderAExhibitGMask: types.exhibit_mask,

        sectionBSliderBExhibitA: types.exhibit,
        sectionBSliderBExhibitAMask: types.exhibit_mask,

        sectionBSliderBExhibitB: types.exhibit,
        sectionBSliderBExhibitBMask: types.exhibit_mask,

        sectionBSliderBExhibitC: types.exhibit,
        sectionBSliderBExhibitCMask: types.exhibit_mask,

        sectionBSliderBExhibitD: types.exhibit,
        sectionBSliderBExhibitDMask: types.exhibit_mask,

        sectionBSliderBExhibitE: types.exhibit,
        sectionBSliderBExhibitEMask: types.exhibit_mask,

        sectionBSliderBExhibitF: types.exhibit,
        sectionBSliderBExhibitFMask: types.exhibit_mask,

        sectionBSliderBExhibitG: types.exhibit,
        sectionBSliderBExhibitGMask: types.exhibit_mask,

        sectionBStat: {}
      }
    },
    sectionC: {
      properties: {
        translations: {
          type: 'translation',
          properties: {
            sectionCTitle: {},
            sectionCText: {
              type: 'textarea'
            },
            sectionCVideoTitle: {},
            sectionCVideoText: {
              type: 'textarea'
            },
          },
        },
        sectionCExhibitA: types.exhibit,
        sectionCExhibitAMask: types.exhibit_mask,

        sectionCExhibitB: types.exhibit,
        sectionCExhibitBMask: types.exhibit_mask,

        sectionCExhibitC: types.exhibit,
        sectionCExhibitCMask: types.exhibit_mask,

        sectionCExhibitD: types.exhibit,
        sectionCExhibitDMask: types.exhibit_mask,

        sectionCYoutube: {
          type: 'youtube'
        },
        sectionCStat: {}
      }
    },
  },
}

export default schema
