import React, { useCallback, useEffect } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { TextField } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { validate } from 'core/_helpers/validate'
import { translate } from 'core/_helpers/translate'

const useStyles = makeStyles(theme => ({
  compare: {
    marginTop: 5,
  },
  compareNeq: {
    color: theme.palette.error.main,
  },
}))

export const YearFromType = ({
  name,
  label,
  hint = null,
  initialValue,
  value,
  compareValue = null,
  compare = false,
  error = false,
  renderError = false,
  disabled = false,
  validators,
  setValue,
  setAdditional,
  setError,
  fullWidth = false,
}) => {
  const handleChange = e => {
    const value = parseInt(e.target.value)

    setValue(name, value)
    validateField(value)

    setAdditional({ [name]: value })
  }

  const validateField = useCallback(
    value => {
      if (!validators) {
        setError(name, false)

        return
      }

      const valid = validate(validators, value)

      setError(name, !valid.result && valid.message)
    },
    [validators, setError, name]
  )

  useEffect(() => {
    validateField(initialValue)

    setAdditional({ [name]: initialValue })
  }, [validateField, initialValue, setAdditional, name])

  const classes = useStyles()

  return (
    <>
      <TextField
        name={name}
        type="number"
        label={
          translate(label.text || label) +
          (validators && validators.includes('required') ? ' *' : '')
        }
        value={value || ''}
        disabled={disabled}
        onChange={handleChange}
        error={renderError && !!error}
        helperText={translate(renderError && error ? error : hint)}
        fullWidth={fullWidth}
        variant="standard"
        inputProps={{ step: 1 }}
      />
      {compare && (
        <div
          className={clsx(
            classes.compare,
            value !== compareValue && classes.compareNeq
          )}
        >
          {compareValue}
        </div>
      )}
    </>
  )
}

YearFromType.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      text: PropTypes.string.isRequired,
    }),
  ]).isRequired,
  hint: PropTypes.string,
  initialValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  compareValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  compare: PropTypes.bool,
  error: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  renderError: PropTypes.bool.isRequired,
  disabled: PropTypes.bool.isRequired,
  validators: PropTypes.arrayOf(PropTypes.string),
  setValue: PropTypes.func.isRequired,
  setAdditional: PropTypes.func.isRequired,
  setError: PropTypes.func.isRequired,
  fullWidth: PropTypes.bool,
}
